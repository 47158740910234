import { atom } from "jotai";
import { calendarPatientIdAtom } from "./calendarWeeklyEventsAtom";
import { patientsCompiledPHQAtom } from "./patientPHQAtoms";

export const getCalendarWeeklySurveys = atom((get) => {
  const allSurveys = get(patientsCompiledPHQAtom);
  const patientId = get(calendarPatientIdAtom);

  const patientSurveys = allSurveys.filter((survey) => survey.patientID === patientId);

  const newResponseArray = patientSurveys.map((element: any) => {
    const newDate = new Date(element.date);

    return {
      answers: element.answers,
      date: `${newDate.getDate()}-${newDate.getMonth()}-${newDate.getFullYear()}`,
      averageValue: element.score,
    };
  });

  return newResponseArray;
});

import { useSelector } from "react-redux";

export function useUncompletedGoals() {
  const startDate = useSelector((state: any) => state.user.startDate);
  const uncompletedGoals = useSelector((state: any) => state.goals.goals).map((el: any) => el.goalsArray.filter((goalElem: any) => !goalElem.completed));
  let uncompletedGoalsCount = 0;

  // add 1 to uncomplatedGoalsCount if goalElem.created_at 
  // 1. year is lower than current year or
  // 2. year is equal to current year and month is lower or equal than current month 

  if (uncompletedGoals && uncompletedGoals.length > 0) {
    uncompletedGoals.forEach((goal: any) => {
      goal.forEach((goalElem: any) => {
        const goalDate = new Date(goalElem.created_at);
        const currentDate = new Date(startDate);
        if (goalDate.getFullYear() < currentDate.getFullYear() || (goalDate.getFullYear() === currentDate.getFullYear() && goalDate.getMonth() <= currentDate.getMonth())) {
          uncompletedGoalsCount++;
        }
      });
    });
  }

  return uncompletedGoalsCount;

}
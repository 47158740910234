export default function getGenderToDisplay(gender) {
    let genderToDisplay = '';

    switch (gender) {
        case 'm':
            genderToDisplay = 'Uomo';
            break;
        case 'f':
            genderToDisplay = 'Donna';
            break;
        case 'o':
            genderToDisplay = 'Altro';
            break;
        default:
            genderToDisplay = 'Tutti';
            break;
    }

    return genderToDisplay;
}

import axios from "axios";
import { atom } from "jotai";
import getCallUrl from "../utils/callUrlUtil";
import { userTokenAtom } from "./userInfoAtom";

export const calendarStartDateAtom = atom<Date>(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
export const calendarEndDateAtom = atom<Date>(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
export const calendarPatientIdAtom = atom<string>('');
export const calendarDrugIdAtom = atom<string>('');

export const getCalendarWeeklyAdherenceAtom = atom(
  async (get) => {
    const startDate = get(calendarStartDateAtom);
    const endDate = get(calendarEndDateAtom);
    const callUrl = getCallUrl();
    const userToken = get(userTokenAtom);
    const patientId = get(calendarPatientIdAtom);
    const drugId = get(calendarDrugIdAtom);
    if (patientId !== 'null') {

      console.log('patientId', patientId);

      console.log('userToken', userToken);



      const response = await axios.get(`${callUrl}/api/patient-drugs/therapeutic-adherences-stats/${startDate.getFullYear()}-${startDate.getMonth()}-${startDate.getDate()}/${endDate.getFullYear()}-${endDate.getMonth()}-${endDate.getDate() + 1}/true?patient=${patientId
        }&patientDrug=${drugId}`, {
        headers: { 'X-AUTH-TOKEN': userToken },
      })
        .then(response => {

          return response.data;
        })
        .catch(error => {
          console.log(error);
        });

      return response ? response.data : null;
    } else {
      return null;
    }
  }
);
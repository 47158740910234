import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { userFakeData } from '../containers/exampleData/exampleData';


const initialState = {
  startDate: new Date().toString(),
  user: null,
  userPatients: null,
  filteredPatients: null,
  ageFilter: null,
  genderFilter: null,
  pathologyFilter: null,
  patientsPathologies: [],
  patientId: null,
  selectedPatient: null,
  patientsPending: [],
  loading: true,
  authToken: null,
  logError: null,
  genders: {
    male: 0,
    female: 0,
    other: 0,
  }
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLogin: (state, action) => {
      if (action.payload.data) {

        let userPatients = action.payload.data.data.user.patient_medics;
        const patientsPending = state.patientsPending;
        const newPathologies = state.patientsPathologies;
        let male = 0;
        let female = 0;
        let other = 0;

        // if userPatients is not null filter .status != 'canceled' and .status != 'suspended'
        if (userPatients) {
          userPatients = userPatients.filter(element => element.status !== 'canceled' && element.status !== 'suspended' && element.status !== 'pending');
        }

        // add patients to pending if status === 'pending'
        if (action.payload.data.data.user.patient_medics) {
          action.payload.data.data.user.patient_medics.forEach(element => {
            if (element.status === 'pending' && patientsPending.findIndex(e => e.id === element.id) < 0) {
              patientsPending.push(element);
            }
          });
        }

        userPatients.forEach(element => {

          // check every pathology of the given patient, if pathology name is not present in the current pathology array, adds it to it 
          // else adds 1 to the current number
          element.patient.pathologies.forEach(pathology => {
            const pathologyIndex = newPathologies.findIndex(e => e[0] === pathology.name);

            if (pathologyIndex === -1) {
              newPathologies.push([pathology.name, 1]);
            } else {
              newPathologies[pathologyIndex][1] = newPathologies[pathologyIndex][1] + 1;
            }
          });

          if (element.patient.gender === 'f') {
            female++;
          } else if (element.patient.gender === 'm') {
            male++;

          } else {
            other++;
          }

        });
        if (!action.payload.skipToken)
          state.authToken = action.payload.data.data.token;
        state.user = action.payload.data.data.user;
        state.userPatients = userPatients;
        state.filteredPatients = userPatients;
        state.patientsPending = patientsPending;
        state.patientsPathologies = newPathologies;
        state.logError = null;
        state.loading = false;
        state.genders.male = male;
        state.genders.female = female;
        state.genders.other = other;

      } else {
        if (action.payload.logError) {
          state.logError = action.payload.logError;
        }
      }

    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    userLogout: (state) => {
      state.authToken = null;
    },
    acceptUserPatient: (state, action) => {
      const patients = state.userPatients;
      const patientsPending = state.patientsPending;
      const patientIndex = patients.findIndex(e => e.id === action.payload.id);
      const pendingIndex = patientsPending.findIndex(e => e.id === action.payload.id);


      if (patientIndex !== -1) {

        patients[patientIndex].patient.status = action.payload.accept ? 'accepted' : 'rejected';
      }

      if (pendingIndex !== -1) {
        patientsPending.splice(pendingIndex, 1);
      }

      state.userPatients = patients;
      state.patientsPending = patientsPending;

    },
    loadUserInfo: (state) => {
      state.user = userFakeData;

      state.loading = false;
    },
    selectPatient: (state, action) => {
      state.selectedPatient = state.user.patient_medics.find(e => e.patient.id === action.payload);
      state.patientId = action.payload;
    },
    updateComorbidity: (state, action) => {
      if (state.selectedPatient) {
        state.selectedPatient.comorbidities = action.payload;
      }
    },
    filterPatients: (state) => {
      state.filteredPatients = applyFilters(state.userPatients, state.genderFilter, state.pathologyFilter, state.ageFilter);
    },
    genderFilter: (state, action) => {
      let genderFilter = null;

      if (action.payload.gender) {
        genderFilter = action.payload.gender;
      }

      state.genderFilter = genderFilter;
      state.filteredPatients = applyFilters(state.userPatients, genderFilter, state.pathologyFilter, state.ageFilter);
    },
    pathologyFilter: (state, action) => {
      let pathologyFilter = null;

      if (action.payload) {
        pathologyFilter = action.payload;
      }

      state.pathologyFilter = pathologyFilter;
      state.filteredPatients = applyFilters(state.userPatients, state.genderFilter, pathologyFilter, state.ageFilter);

    },
    ageFilter: (state, action) => {
      let ageFilter = null;

      if (action.payload) {
        ageFilter = action.payload.age;
      }
      state.ageFilter = ageFilter;
      state.filteredPatients = applyFilters(state.userPatients, state.genderFilter, state.pathologyFilter, ageFilter);

    },
    setUserStartDate: (state, action) => {
      state.startDate = action.payload;
    }
  }
});

export function fetchUserLogin() {
  return function (dispatch) {
    return axios.get("https://api.myjson.com/bins/19dtxc")
      .then(({ data }) => {
        dispatch(userLogin(data));
      });
  };
}

export const { acceptUserPatient, loadUserInfo, selectPatient, userLogin, filterPatients, genderFilter, pathologyFilter, setUserStartDate, userLogout, updateComorbidity, setAuthToken, ageFilter } = userSlice.actions;


export default userSlice.reducer;


function applyFilters(patients, gender, pathology, age) {

  // get all user patients
  let patientsFiltered = patients;


  // if gender is passed, and is not === to t(all) push patient to filteredArrayWhere Gender === payload.gender;
  if (gender && gender !== 't') {
    if (gender !== 'm' && gender !== 'f') {
      patientsFiltered = patientsFiltered.filter(e => e.patient.gender !== 'm' && e.patient.gender !== 'f');
    } else {
      patientsFiltered = patientsFiltered.filter(element => element.patient.gender === gender);
    }
  }
  // if pathology is passed, push patient to filteredArray where pathologies contains payload.pathology in obj.name;
  if (pathology && pathology !== 'Tutte') {
    patientsFiltered = patientsFiltered.filter(element => { return (element.patient.pathologies.findIndex(e => e.name === pathology) >= 0); });
  }

  if (age) {
    console.log(age);
    const splittedAge = age.split('-');
    patientsFiltered = patientsFiltered.filter(element => {
      const patientAge = getAge(element.patient.date_of_birth);
      return (patientAge >= splittedAge[0] && patientAge <= splittedAge[1]);
    });
  }

  return patientsFiltered;
}


function getAge(dateString) {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
import { useAtomValue } from "jotai";
import { patientsCompiledPHQAtom } from "../jotai/patientPHQAtoms";

export function useFilteredPHQ(patients: string | string[], date: string) {
  const allPHQ = useAtomValue(patientsCompiledPHQAtom);

  //return hte phqs filtered by patient id and in the same month as date
  return allPHQ.filter((phq) => {
    const phqDate = new Date(phq.date);
    const filterDate = new Date(date);
    let patientCheck = false;

    //if patients is a string, then we only want to filter by that patient
    if (!Array.isArray(patients)) {
      patientCheck = phq.patientID === patients;
    } else {
      //if patients is an array, then we want to filter by all patients in the array
      patientCheck = patients.includes(phq.patientID);
    }
    return (
      phqDate.getMonth() === filterDate.getMonth() &&
      phqDate.getFullYear() === filterDate.getFullYear() &&
      patientCheck
    );
  }
  );
}
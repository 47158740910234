import React, { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer';

type Props = {
    date: Date,
    entersInView: (arg: Date) => void,
    intoView: Date,
    scrolled: () => void,
    scrollToMatch: boolean,
    monthsArray: string[],
}

export default function IntersectionDayElement({ date, entersInView, intoView, scrolled, scrollToMatch, monthsArray }: Props) {
    const elementDate = `${date.getDate()}-${date.getMonth()}`;
    const daysString = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const wrapper = useRef(null)
    const [isToday, setIsToday] = useState<boolean>(false);
    const [isTomorrow, setIsTomorrow] = useState<boolean>(false);
    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: 0.5,
    });

    useEffect(() => {


        if (scrollToMatch && `${intoView.getDate()}-${intoView.getMonth()}` === elementDate && wrapper.current) {

            // @ts-ignore
            wrapper.current.parentNode.parentNode.scrollTop = wrapper.current.offsetTop;
            
            // this is here 'cause I have 5 minutes to do a job that would require 4 hours 
            setTimeout(() => {
                scrolled();
            }, 300)
        }

    }, [scrollToMatch])

    useEffect(() => {
        if (inView) {
            entersInView(date)
        }

    }, [inView, scrollToMatch])

    useEffect(() => {
        if (new Date(new Date().setHours(0, 0, 0, 0)).toString() === date.toString() && wrapper.current) {
            // @ts-ignore

            wrapper.current.parentNode.scrollTop = wrapper.current.offsetTop;

            setIsToday(true);
        }

    }, [])


    return (
        <div id={`date-target-${date.getDate()}-${date.getMonth()}`} ref={wrapper}>
            <div className={`date-element${isToday ? ' today' : ''}`} ref={ref}>
                <div className="day">
                    <div className="week-day">
                        <p>{date.getDate().toString()}</p>
                    </div>
                    <div className="week-day-name">
                        <p>{daysString[date.getDay()]}</p>
                    </div>
                </div>
                <div className="speical-day"> {isToday ? 'Oggi' : monthsArray[date.getMonth()]}</div>
            </div>
        </div>
    );
}
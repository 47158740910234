import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as patientsStore from '../../store/patientsStore';
import { getSurveyTotal } from '../../store/surveysStore';
import {
    useHistory
} from "react-router-dom";

import NewsCard from '../../components/newsCard';
import SimpleModal from '../../components/simpleModal';
import Loader from '../../components/Loader';
import AddAppointments from '../../components/AddAppointments';
import MultiChartElement from '../../components/charts/MultiChartElement';

import NewsImg from '../../assets/images/layout/news-header.png';

import CalendarTimesB from '../../assets/images/fa-icons/calendar-times-b.svg';
import graph from '../../assets/images/fa-icons/graph.svg';
import CalendarPlusW from '../../assets/images/fa-icons/calendar-plus-w.svg';

import PatientsBarBoxComponent from '../../components/PatientsBarBoxComponent';
import PatientsConnectionModal from '../../components/modals/PatientsConnectionModal';
import VerticalScrollableCalendar from '../../components/verticalScrollableCalendar';
import InfoBox from '../../components/infoBox';
import VerticalCalendar from '../../components/verticalCalendar';
import ActivityBoxes from '../../components/activities/ActivityBoxes';
import { useAtomValue } from 'jotai';
import { acceptedEventsAtom } from '../../jotai/calendarEvents/taggedEventsAtom';


export default function Dashboard(props) {
    const startDate = useSelector((state) => state.user.startDate);
    const [showAppointmentModal, setShowAppointmentModal] = useState(false);
    const [showConnectionModal, setShowConnectionModal] = useState(false);
    const dispatch = useDispatch();
    const lastestNews = useSelector((state) => state.news.lastestNews);
    const allAdherences = useSelector((state) => state.patients.allAdherences);
    const dayUsageChartData = useSelector((state) => state.usage.dayUsageChartData);
    const dailyMoods = useSelector((state) => state.patients.dailyMoods);
    const moods = useSelector((state) => state.patients.moods);
    const filteredGoalsChartData = useSelector((state) => state.goals.filteredGoalsChartData);
    const allSurveysChartData = useSelector((state) => state.surveys.allSurveysChartData);
    const events = useSelector((state) => state.events.events);
    const acceptedEvents = useAtomValue(acceptedEventsAtom);
    const history = useHistory();
    const [nextEvent, setNextEvent] = useState(null);


    useEffect(() => {
        console.log('acceptedEvents', acceptedEvents);
        if (events) {
            const usableEvents = [...events, ...acceptedEvents];
            const nextAppointment = usableEvents.find(ev => new Date(ev.date) > new Date());
            console.log('nextAppointment', nextAppointment);

            if (nextAppointment) {
                let appointmentUser = null;
                let pathologies = '';
                const date = new Date(nextAppointment.date);

                if (nextAppointment.tagged_users) {
                    nextAppointment.tagged_users.forEach(user => {

                        if (appointmentUser === null && user.discr === 'patient') {
                            appointmentUser = user;
                        }

                    });
                }
                if (appointmentUser === null && nextAppointment.user) {
                    appointmentUser = nextAppointment.user;
                }

                if (appointmentUser === null && nextAppointment.eventId) {
                    appointmentUser = {
                        firstname: nextAppointment.name,
                        lastname: nextAppointment.lastName,
                    };
                    pathologies = nextAppointment.pathologies.map(pathology => pathology.name).join(', ');
                }

                if (!nextAppointment.eventId) {
                    appointmentUser.pathologies?.forEach((pathology, index) => {
                        if (index > 0) {
                            pathologies += ', ';
                        }

                        pathologies += pathology.name;
                    });

                };

                if (appointmentUser !== null) {
                    setNextEvent({
                        name: `${appointmentUser.firstname} ${appointmentUser.lastname}`,
                        diagnosis: pathologies,
                        eventTitle: nextAppointment.title,
                        eventTiming: `${date.getHours()}:${date.getMinutes()}`
                    });
                }
            }
        }
    }, [events, acceptedEvents]);

    useEffect(() => {
        console.log("nextEvent", nextEvent);
    }, [nextEvent]);

    useEffect(() => {
        document.title = props.title || "Giada";

        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 200);

        moodsCalculator();
        dispatch(getSurveyTotal({ startDate: startDate, endDate: startDate }));

    }, [props.title, moods, startDate]);


    const moodsCalculator = () => {
        dispatch(patientsStore.calculateDailyMoods());
        dispatch(patientsStore.calculateTotalMood());
    };


    return (
        <React.Fragment>
            <SimpleModal visible={showAppointmentModal} toggleModal={() => setShowAppointmentModal(!showAppointmentModal)} modalTitle="Aggiungi appuntamento">
                <AddAppointments />

            </SimpleModal>

            <PatientsConnectionModal
                visible={showConnectionModal}
                toggleModal={() => setShowConnectionModal(!showConnectionModal)}
            />

            {props.loading.user ?
                <SimpleModal visible={true} forced={true}>
                    <Loader title="Caricamento informazioni..." />
                </SimpleModal>
                : null}
            <div className="page-header">
                <div className="breadcrumbs">
                    Dashboard
                </div>
                <div className="page-actions right">
                </div>
            </div>
            <div className="row">
                <div className={props.menuCollapsed ? 'col-lg-4' : 'col-xl-4'}>
                    <div className="row">
                        <div className="col-12">

                            {
                                nextEvent ?
                                    <InfoBox
                                        name={nextEvent.name}
                                        diagnosis={nextEvent.diagnosis}
                                        eventTitle={nextEvent.eventTitle}
                                        eventTiming={nextEvent.eventTiming}
                                    />
                                    : <div className="rounded-box flexed">
                                        <img className="big-icon" src={CalendarTimesB} alt="" />
                                        <div>
                                            <p className="colored-title">Prossimo appuntamento</p>
                                            <p className="my-2">Nessun appuntamento programmato</p>
                                            <button className="button secondary flexed small" onClick={() => setShowAppointmentModal(true)}><img className="icon-img" src={CalendarPlusW} /> Aggiungi appuntamento</button>
                                        </div>
                                    </div>
                            }

                        </div>
                        <div className="col-12 mb-3">
                            <div className='d-none d-lg-block'>
                                <VerticalScrollableCalendar />
                            </div>
                            <div className='d-lg-none'>
                                <VerticalCalendar />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={props.menuCollapsed ? 'col-lg-8' : 'col-xl-8'}>
                    <ActivityBoxes />
                    <div className="row">
                        <div className="col-12">
                            <div className="rounded-box chart-box position-relative">
                                <MultiChartElement
                                    Adherences={allAdherences}
                                    dailyMoods={dailyMoods}
                                    allAdherences={allAdherences}
                                    filteredGoalsChartData={filteredGoalsChartData}
                                    allSurveysChartData={allSurveysChartData}
                                    dayUsageChartData={dayUsageChartData}
                                />
                                <button className="button secondary more-detail flexed" onClick={() => history.push("/analisi/")}><img className="icon-img mr-2" src={graph} />Maggiorni informazioni</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-xl-8 mt-3">
                    <PatientsBarBoxComponent
                        buttonClick={() => setShowConnectionModal(true)}
                    />
                </div>
                <div className="col-12 col-xl-4 mt-3">
                    {lastestNews ?

                        <NewsCard
                            img={lastestNews.featured_image ? lastestNews.featured_image.reference.url : NewsImg}
                            title={lastestNews.title}
                            tags={lastestNews.tags}
                            date={lastestNews.date}
                            excerpt={lastestNews.abstract}
                            content={lastestNews.content}
                            type={'max mx-auto'}
                        />
                        : null}

                </div>
            </div>
        </React.Fragment>

    );
};
import React, { useEffect, useState } from 'react';
import getChartData from '../../hooks/getChartData';
import { v4 as uuid } from 'uuid';

import LineChart from './LineChart';
import AbsoluteChart from './AbsoluteChart';
import ExportComponent from '../ExportComponent';
import { useSelector } from 'react-redux';



const MultiChartElement = (props) => {
    const startDate = useSelector((state) => state.user.startDate);
    const [specialChartData, setSpecialChartData] = useState(getChartData(props.Adherences, null, null, ''));
    const [moodChartData, setMoodChartData] = useState(getChartData(props.dailyMoods, startDate, null, 'moods'));
    const [adherenceChartData, setAdherenceChartData] = useState(getChartData(props.allAdherences, startDate, null, 'adherence'));
    const [goalsChartData, setGoalsChartData] = useState(getChartData(props.filteredGoalsChartData, startDate, null, 'goal'));
    const [surveyChartData, setSurveyChartData] = useState(getChartData(props.allSurveysChartData, startDate, null, 'survey'));
    const [usageChartData, setUsageChartData] = useState(getChartData(props.dayUsageChartData, startDate, null, 'usage'));
    const [id, setId] = useState(uuid());
    const [selectedCharts, setSelectedCharts] = useState({
        1: false,
        2: true,
        3: false,
        4: false,
        5: false,

    });

    useEffect(() => setMoodChartData(getChartData(props.dailyMoods, startDate, null, 'moods')), [startDate, props.Adherences, props.dailyMoods]);

    useEffect(() => {
        setAdherenceChartData(getChartData(props.allAdherences, startDate, null, 'adherence'));
        setSpecialChartData(getChartData(props.allAdherences, startDate, null, ''));
    }, [startDate, props.allAdherences]);

    useEffect(() => setGoalsChartData(getChartData(props.filteredGoalsChartData, startDate, null, 'goal')), [startDate, props.filteredGoalsChartData]);

    useEffect(() => setSurveyChartData(getChartData(props.allSurveysChartData, startDate, null, 'survey')), [startDate, props.allSurveysChartData]);

    useEffect(() => setUsageChartData(getChartData(props.dayUsageChartData, startDate, null, 'usage')), [startDate, props.dayUsageChartData]);

    useEffect(() => { setId(uuid()); }, [props.Adherences, props.dailyMoods, props.allAdherences, props.filteredGoalsChartData, props.allSurveysChartData, props.dayUsageChartData]);


    const handleChartsSelection = (id) => {

        const charts = Object.assign({}, selectedCharts);

        charts[id] = !charts[id];

        setSelectedCharts(charts);
    };

    // moodChartData on change log it

    // useEffect(() => {
    //     console.log("props.filteredGoalsChartData", goalsChartData);
    // }
    //     , [goalsChartData]);

    return (
        <React.Fragment>

            <div className="chart-legend-wrapper">
                <div onClick={() => handleChartsSelection(1)} className={`legend-element ${selectedCharts[1] ? 'active' : ''}`}>
                    <div className="legend-square"></div>
                    <p>Uso App</p>
                </div>
                <div onClick={() => handleChartsSelection(2)} className={`legend-element ${selectedCharts[2] ? 'active' : ''}`}>
                    <div className="legend-square"></div>
                    <p>Aderenza</p>
                </div>
                <div onClick={() => handleChartsSelection(3)} className={`legend-element ${selectedCharts[3] ? 'active' : ''}`}>
                    <div className="legend-square"></div>
                    <p>Umore</p>
                </div>
                <div onClick={() => handleChartsSelection(4)} className={`legend-element ${selectedCharts[4] ? 'active' : ''}`}>
                    <div className="legend-square"></div>
                    <p>Traguardi</p>
                </div>
                <div onClick={() => handleChartsSelection(5)} className={`legend-element ${selectedCharts[5] ? 'active' : ''}`}>
                    <div className="legend-square"></div>
                    <p>PHQ9</p>
                </div>
            </div>
            {props.exportable ? <ExportComponent
                active={selectedCharts}
                data={[
                    usageChartData,
                    adherenceChartData,
                    moodChartData,
                    goalsChartData,
                    surveyChartData,
                ]} /> : null}
            <LineChart data={[{
                "id": id,
                "color": "transparent",
                "data": adherenceChartData
            }]}
                setID={0}
                passedID={selectedCharts}
                minValue={0}
            />
            {/* Chart setter  needed for multi select tooltip*/}
            <AbsoluteChart data={[{
                "id": "Uso App",
                "color": "red",
                "data": specialChartData
            }]} setID={1} passedID={selectedCharts}
                special={true}
                chartsData={[{ id: 1, data: usageChartData, name: 'Uso App' }, { id: 2, data: adherenceChartData, name: 'Aderenza' }, { id: 3, data: moodChartData, name: 'Umore' }, { id: 4, data: goalsChartData, name: 'Traguardi' }, { id: 5, data: surveyChartData, name: 'Questionari' }]} />
            {/* Chart setter */}
            <AbsoluteChart data={[{
                "id": "Uso App",
                "color": "rgb(0, 0, 0)",
                "data": usageChartData
            }]} setID={1} passedID={selectedCharts}
            />
            <AbsoluteChart data={[{
                "id": "Aderenza",
                "color": "rgb(206, 75, 75)",
                "data": adherenceChartData
            }]} setID={2} passedID={selectedCharts}
            />
            <AbsoluteChart data={[{
                "id": 'Umore',
                "color": "hsl(158, 56%, 43%)",
                "data": moodChartData
            }]} setID={3} passedID={selectedCharts}
            />
            <AbsoluteChart data={[{
                "id": "Traguardi",
                "color": "hsl(203, 7%, 42%)",
                "data": goalsChartData
            }]} setID={4} passedID={selectedCharts}
            />
            <AbsoluteChart data={[{
                "id": "PHQ9",
                "color": "hsl(233, 46%, 55%)",
                "data": surveyChartData
            }]} setID={5} passedID={selectedCharts}
            />
        </React.Fragment>
    );
};

export default MultiChartElement;
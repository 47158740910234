import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    context: {
        show: false,
        xPos: 0,
        yPos: 0,
    },
    contextName:'',
}

export const contextSlice = createSlice({
    name: 'context',
    initialState,
    reducers: {
        setContext: (state, action) => {
            if (action.payload.xPos !== null) {
                state.context = {
                    show: true,
                    xPos: action.payload.xPos,
                    yPos: action.payload.yPos,
                }

            } else {
                state.context = {
                    show: false,
                    xPos: 0,
                    yPos: 0,
                }
            }

            if(action.payload.contextName){
                state.contextName = action.payload.contextName;
            }else{
                state.contextName = '';
            }
        },


    }
})

export const { setContext } = contextSlice.actions;

export default contextSlice.reducer;
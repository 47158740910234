export function getMedianSteps(activites: any[]):number{

    if(activites.length > 0){
        let steps = activites.map(activity => activity.total_steps);
        //reduce total steps to a single number
        let totalSteps = steps.reduce((acc, curr) => acc + curr);
        let medianSteps = totalSteps / steps.length;
        return medianSteps;
    }else{
        return 0;

    }
}
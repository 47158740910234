export const getPersonalGoals = (goals: any[], patientID: string) => {
  const patientGoals = goals.filter((e: any) => e.id === patientID);

  const newArray: any[] = [];

  patientGoals.forEach((element: any) => {
    element.goalsArray.forEach((goal: any) => {
      if (newArray.findIndex((e: any) => e.id === goal.id) === -1)
        newArray.push(goal);
    });
  });


  // return only unique goals for the patient
  return newArray;

};
export const getFilteredGoals = (goals: any[], patientID: string[]) => {
  const patientGoals = goals.filter((e: any) => patientID.includes(e.id));

  // console.log('patientGoals', patientGoals);

  if (patientGoals.length === 0) {
    return [];
  }

  const newArray: any[] = [];

  patientGoals.forEach((element: any) => {
    element.goalsArray.forEach((goal: any) => {
      if (newArray.findIndex((e: any) => e.id === goal.id) === -1)

        newArray.push(goal);
    });
  });

  // return only unique goals (no duplicates)
  return newArray;

};
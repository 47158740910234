import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ActivityCompact from '../../components/activities/activityCompact';
import AdherenceRateComponent from '../AdherenceRateComponent';
import Adeherence from '../../assets/images/icons/list.png';
import Mood from '../../assets/images/icons/dashboard.png';
import Step from '../../assets/images/icons/shoes.png';
import Survey from '../../assets/images/icons/paper.png';
import Stats from '../../assets/images/icons/stats.png';
import Med1 from '../../assets/images/icons/medication-1.png';
import Danger from '../../assets/images/icons/danger.png';
import { useSelector } from 'react-redux';
import { getFirstDayDate } from '../../utils/daysUtils';
import SpecifiedAdherenceRateComponent from '../SpecifiedAdherenceRateComponent';

type Props = {
    userToken: string;
    roundedSelected: string,
    clinicalEventsCount: number,
    patient: any,
    activities: any[],
    useClinicalEvents: any[],
    singleSurveyChartData: any[],
    selectedSurveys: any[],
    getPatientUsage: () => any[],
    setShowGoalModal: (arg: boolean) => void,
    infoHandler: (arg: number) => void,
    setSingleDrugAdherenceData: (arg1: any, arg2: any) => void,
    getPatientMood: () => string,
    setSelectedSurveys: (arg: any) => void,
    selectedActivity: number,
    setSelectedActivity: (arg: number) => void,
    setRealDrugID: (arg: string) => void,
};



export default function SectionInfo({ userToken, roundedSelected, clinicalEventsCount, patient, activities, useClinicalEvents, singleSurveyChartData, selectedActivity, setSelectedActivity, setShowGoalModal, infoHandler, setSingleDrugAdherenceData, getPatientMood, setRealDrugID }: Props) {
    const allGoals = useSelector((state: any) => state.goals.goals);
    const completedGoals = useSelector((state: any) => state.goals.completedGoals);
    const patientSession = useSelector((state: any) => state.usage.userSessions);
    const startDate = useSelector((state: any) => state.user.startDate);
    const userDailyUsage = useSelector((state: any) => state.usage.userDailyUsage);


    const [medianUsage, setMedianUsage] = useState<number>(0);
    useEffect(() => {
        if (patient && userDailyUsage.length > 0) {

            const filteredUsage = userDailyUsage.filter((obj: any) => patient.id === obj.id);
            const startDay = getFirstDayDate(new Date(startDate).getMonth() + 1, new Date(startDate).getFullYear());
            const dateFilteredSurveys = filteredUsage.filter((use: any) => use.date.split('-')[1] === (startDay.getMonth() + 1).toString());
            // map usage from filteredUsage and get sum
            const sum = dateFilteredSurveys.map((obj: any) => obj.usage).reduce((a: any, b: any) => a + b, 0);
            if (dateFilteredSurveys.length > 0) {

                setMedianUsage(Math.round(sum / dateFilteredSurveys.length));
            } else {
                setMedianUsage(0);
            }
        } else {
            setMedianUsage(0);
        }

    }, [userDailyUsage, patient, startDate]);
    useEffect(() => {
        setSelectedActivity(0);
    }, [roundedSelected]);

    const getPersonalGoals = () => {
        const startDay = getFirstDayDate(new Date(startDate).getMonth() + 1, new Date(startDate).getFullYear());
        const patientGoals = allGoals.filter((e: any) => e.id === patient.id);
        const goalsArray: any[] = [];

        console.log('patientGoals', patientGoals);

        patientGoals.forEach((element: any) => {
            element.goalsArray.forEach((goal: any) => {
                if (goalsArray.findIndex((e: any) => e.id === goal.id) === -1) {
                    if (goal.completed_at) {
                        const completedAt = new Date(goal.completed_at);
                        if (completedAt.getMonth() + 1 >= startDay.getMonth() + 1 && completedAt.getFullYear() >= startDay.getFullYear()) {
                            const createdAt = new Date(goal.created_at);
                            if (createdAt.getMonth() + 1 <= startDay.getMonth() + 1 && createdAt.getFullYear() <= startDay.getFullYear()) {
                                goalsArray.push(goal);
                            }
                        }
                    } else {
                        const createdAt = new Date(goal.created_at);
                        if (createdAt.getMonth() + 1 <= startDay.getMonth() + 1 && createdAt.getFullYear() <= startDay.getFullYear()) {
                            goalsArray.push(goal);
                        }
                    }

                }
            });
        });

        console.log('goalsArray', goalsArray);

        return goalsArray;
    };



    const getPatientSteps = () => {
        const activitiesIndex = activities.findIndex(e => e.id === patient.id);
        let totalSteps = 0;

        if (activitiesIndex !== -1) {

            activities[activitiesIndex].activities.forEach((e: any) => {
                totalSteps += e.total_steps;
            });

        }

        return activitiesIndex === -1 ? 0 : totalSteps;

    };

    const getOnlySystemGoals = () => {
        return patient.in_app_goals_completed;
    };

    const getSystemGoals = () => {
        const patientGoals = completedGoals.filter((e: any) => e.id === patient.id).map((e: any) => e.goalsArray);
        const goalsArray: any[] = [];
        // get startDate date month-year
        const startDay = getFirstDayDate(new Date(startDate).getMonth() + 1, new Date(startDate).getFullYear());

        // add each patient goal with a completed_at date month-year >= startDay and created_at month-year <= startDay to the goalsArray 
        // completed_at can be null
        patientGoals.forEach((element: any) => {
            element.forEach((goal: any) => {
                if (goalsArray.findIndex((e: any) => e.id === goal.id) === -1) {
                    if (goal.completed_at) {
                        const completedAt = new Date(goal.completed_at);
                        if (completedAt.getMonth() + 1 >= startDay.getMonth() + 1 && completedAt.getFullYear() >= startDay.getFullYear()) {
                            const createdAt = new Date(goal.created_at);
                            if (createdAt.getMonth() + 1 <= startDay.getMonth() + 1 && createdAt.getFullYear() <= startDay.getFullYear()) {
                                goalsArray.push(goal);
                            }
                        }
                    }
                }
            });
        });

        if (goalsArray.length > 0) {
            return goalsArray.length;
        } else {
            return 0;
        }
    };


    function getPatientSession(sessions: any, id: number) {
        const filteredSessions = sessions.filter((el: any) => id === el.id);
        const patientUsage = filteredSessions.map((e: any) => e.session);

        if (patientUsage.length > 0) {
            const session = Math.round(patientUsage.reduce((a: any, b: any) => a + b, 0) / patientUsage.length);
            if (session > 0) {
                return (<h5>{session} volte al giorno</h5>);

            } else {
                return (<h5>meno di 1 volta al giorno</h5>);
            }

        } else {
            return (<h5>ND</h5>);
        }
    }

    switch (roundedSelected) {

        case 'attivita':
            const steps = getPatientSteps();
            return (
                <React.Fragment>
                    <div onClick={() => setSelectedActivity(0)}>
                        <ActivityCompact
                            type={Step}
                            title="Passi"
                            data={steps}
                            selectable={true}
                            selected={selectedActivity === 0}
                        />
                    </div>
                    <div onClick={() => setSelectedActivity(1)}>
                        <ActivityCompact
                            type={Stats}
                            title="KM"
                            data={Math.round(steps * 0.0008)}
                            selectable={true}
                            selected={selectedActivity === 1}
                        />
                    </div>

                    <div className="disclaimer">
                        <p>
                            Il tipo di dati raccolti sono sulla base delle autorizzazioni del paziente e possono variare da paziente a paziente.
                        </p>
                    </div>
                </React.Fragment>
            );
        case 'terapia':
            return (
                <React.Fragment>
                    <div onClick={() => {
                        setSingleDrugAdherenceData('', 0);
                        setRealDrugID('');
                    }} style={{ width: '19rem!important' }}>
                        <ActivityCompact
                            type={Adeherence}
                            title="Media Aderenza"
                            data={<SpecifiedAdherenceRateComponent patientID={patient.id} drugID={''} userToken={userToken} />}
                            special={'w-100'}
                            selectable={true}
                            selected={selectedActivity === 0}
                        />
                    </div>
                    {
                        patient.patient_drugs.map((element: any, index: number) => {
                            // console.log('element drug', element);
                            if (!element.suspended) {
                                return (
                                    !element.end_date || new Date(element.end_date) >= new Date() ?
                                        <div className={`medication-box type-1 ${selectedActivity === index + 1 ? 'selected' : ''}`} onClick={() => {
                                            setSingleDrugAdherenceData(element.id, index + 1);
                                            setRealDrugID(element.drug.id);
                                        }} style={{ width: '19rem' }}>
                                            <div className="medication-img">
                                                <img src={Med1} alt=""></img>
                                            </div>
                                            <div className="medication-info">
                                                <p className="medication-name">{element.drug.name}</p>
                                                {/*<p className="medication-posology">6 gocce al dì</p>*/}
                                            </div>
                                            <div className="medication-adeherence"><SpecifiedAdherenceRateComponent patientID={patient.id} drugID={element.id} userToken={userToken} /></div>
                                        </div> : null
                                );
                            } else {
                                return <></>;
                            }
                        })}
                </React.Fragment>
            );
        case 'traguardi':
            return (
                <React.Fragment>
                    <h5 className="mb-1">Traguardi di sistema: {getOnlySystemGoals()}</h5>
                    <hr></hr>
                    <h5 className="mt-3 mb-2">Traguardi personali</h5>
                    <div className="wrapper-box gray text-center">
                        <h2 className="mb-0">{getSystemGoals()} raggiunti</h2>
                    </div>
                    <hr></hr>
                    {
                        getPersonalGoals().map(e => (
                            <div className="wrapper-box gray goal-box">
                                <p className="goal-text">{e.title}</p>
                                <div className={`reached ${e.completed ? 'active' : ''}`}>
                                    <FontAwesomeIcon icon="check" />
                                </div>
                            </div>
                        ))
                    }
                    <button className="button secondary small mx-auto" onClick={() => setShowGoalModal(true)}><FontAwesomeIcon icon="calendar-plus" /> Aggiungi</button>
                </React.Fragment>
            );
        case 'utilizzo':
            return (
                <React.Fragment>
                    <h5 className="mt-3">Utilizzo medio</h5>
                    <div className="wrapper-box gray">
                        {getPatientSession(patientSession, patient.id)}
                    </div>
                    <div className="wrapper-box gray">
                        <h5>{medianUsage} minuti a sessione</h5>
                    </div>
                </React.Fragment>
            );
        case 'info':
            return (
                <React.Fragment>
                    <ActivityCompact
                        type={Danger}
                        title="Eventi Clinici"
                        data={clinicalEventsCount}
                    />
                    {useClinicalEvents ?
                        useClinicalEvents.map((item) => {
                            return (
                                <div className={`wrapper-box green filter-box ${item.id === 0 ? 'mt-5' : ''}`}>
                                    <div onClick={() => infoHandler(item.id)} className={`filter-check ${item ? item.checked ? 'checked' : '' : ''}`}>
                                        <FontAwesomeIcon icon="check" />
                                    </div>
                                    <p>{item.count}</p>
                                    <p>{item.name}</p>
                                </div>
                            );
                        }) : null
                    }

                    {/* <button className="button secondary mt-2 mx-auto"><FontAwesomeIcon icon="plus-circle" /> Aggiungi</button>*/}
                </React.Fragment>
            );
        default:
            return (
                <React.Fragment>
                    <div onClick={() => setSelectedActivity(0)}>
                        <ActivityCompact
                            type={Mood}
                            title="Umore"
                            data={getPatientMood()}
                            selectable={true}
                            selected={selectedActivity === 0}
                        />
                    </div>
                    <div onClick={() => setSelectedActivity(1)}>
                        <ActivityCompact
                            type={Survey}
                            title="Media PHQ9"
                            data={singleSurveyChartData.length}
                            selectable={true}
                            selected={selectedActivity === 1}
                        />
                    </div>
                </React.Fragment>
            );
    }
}

import { atom } from "jotai";

export type adherenceEntry = {
  date: string;
  rate: number | null;
};

export type PatientsAdherence = {
  patientID: string;
  rate: number;
  data: adherenceEntry[];
};


export const patientsAdherenceAtom = atom<PatientsAdherence[]>([]);


export const addPatientAdherence = atom(
  null,
  (get, set, patientAdherence: PatientsAdherence) => {
    // if patient already exists, update it, otherwise add it
    const patientsAdherence = get(patientsAdherenceAtom);
    const patientIndex = patientsAdherence.findIndex(patient => patient.patientID === patientAdherence.patientID);
    if (patientIndex !== -1) {
      patientsAdherence[patientIndex] = patientAdherence;
    } else {
      patientsAdherence.push(patientAdherence);
    }
    set(patientsAdherenceAtom, patientsAdherence);
  }
);

export const resetAherences = atom(
  null,
  (get, set) => {
    set(patientsAdherenceAtom, []);
  }
);
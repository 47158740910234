import { useAtomValue } from "jotai";
import { useSelector } from "react-redux";
import { adherenceEntry, PatientsAdherence, patientsAdherenceAtom } from "../jotai/patientsAdherenceAtom";

export type PatientType = {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  date_of_birth: string;
};

export function useFilteredAdherence() {
  const allAdherence = useAtomValue(patientsAdherenceAtom);
  const filteredPatients = useSelector((state: any) => state.user.filteredPatients);
  const adherencesCopy = JSON.parse(JSON.stringify(allAdherence));;

  // make an array of all patient instances that are in the filteredPatients array
  const patients: PatientType[] = filteredPatients.map((patient: any) => patient.patient);

  // make an array with the adherences which patientID is in the patients array
  const filteredAdherence = adherencesCopy.filter((adherence: any) => patients.some((patient: any) => patient.id === adherence.patientID));

  return filteredAdherence;

}

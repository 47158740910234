import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SimpleModal from './simpleModal';
import ContextMenu from './contextMenu/contextMenu';
import { selectPatient, userLogout } from '../store/userStore';

import userIcon from '../assets/images/icons/user-circle.png';
import mediKeyLogo from '../assets/images/layout/medikey-logo.png';
import { setContext } from '../store/contextStore';
import NotificationsWidget from './NotificationsWidget';
import Loader from './Loader';
import getCallUrl from '../utils/callUrlUtil';
import axios from 'axios';
import { sendMessage } from '../store/messagesStore';

const HeaderSearch = (props) => {
    const [userMenuVisible, setUserMenuVisible] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [profileModal, setProfileModal] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);
    const [patientRedirect, setPatientRedirect] = useState(false);
    const [patientFilter, setPatientFilter] = useState('');
    const [showContext, setShowContext] = useState({
        show: false,
        xPos: 0,
        yPos: 0,
    });
    const [filteredPatients, setFilteredPatients] = useState([]);
    const userToken = useSelector((state) => state.user.authToken);
    const callUrl = getCallUrl();
    const userInfo = useSelector((state) => state.user.user);
    const userPatients = useSelector((state) => state.user.userPatients);
    const dispatch = useDispatch();
    const [infoLoaded, setInfoLoaded] = useState(false);
    const [newPasswords, setNewPasswords] = useState('');
    const [repeatNewPasswords, setRepeatNewPasswords] = useState('');
    const [useError, setError] = useState(null);
    const [settings, setSettings] = useState({
        appointmets: true,
        mood: true,
        reminder: true,
        science: true,
        giada: true,
    });


    useEffect(() => {
        setToggle(props.collapsed);
    }, []);

    useEffect(() => {
        if (passwordModal) {
            setProfileModal(false);
        }
    }, [passwordModal]);

    useEffect(() => {
        setInfoLoaded(false);
        if (profileModal) {
            if (localStorage.getItem('customSettings')) {
                setSettings(JSON.parse(localStorage.getItem('customSettings')));
            }
            axios.get(`${callUrl}/api/users/profile`, {
                headers: { 'X-AUTH-TOKEN': userToken },
            })
                .then(response => {
                    const serverSettings = response.data.data.user.settings;
                    const newSetting = {
                        ...serverSettings,
                        /* appointmets: serverSettings.appointmets ?? true,
                        mood: serverSettings.mood ?? true,
                        reminder: serverSettings.reminder ?? true,
                        science: serverSettings.science ?? true,
                        giada: serverSettings.giada ?? true, */
                    };

                    setInfoLoaded(true);
                })
                .catch(error => dispatch(sendMessage({
                    message: 'Impossibile recuperare dati utente: ' + error,
                    positive: false,
                })));

        }
    }, [profileModal]);

    useEffect(() => { if (patientFilter !== '') patientFilterHandler(); }, [patientFilter]);

    function updateMedicPassword(pass, repeatedPass) {
        // console.log('pass', pass);
        // console.log('replaced password', repeatedPass);
        if (pass !== repeatedPass) {
            setError('Le due password devono coincidere');
        } else {
            setError('');
            setPasswordModal(false);
        }
    }

    function updateMedicSettings(settings) {
        localStorage.setItem('customSettings', JSON.stringify(settings));
        axios.post(`${callUrl}/api/users/update`, {
            settings: {
                "notificationsEnabled": true,
                "minutesBeforeNotifications": 5
            }
        }, {
            headers: { 'X-AUTH-TOKEN': userToken },
        })
            .then(response => {
                if (response.status === "error") {
                    dispatch(sendMessage({
                        message: 'Impossibile aggiornare preferenze',
                        positive: false,
                    }));
                } else {
                    dispatch(sendMessage({
                        message: 'Preferenze aggiornate con successo',
                        positive: true,
                    }));
                }
            })
            .catch(error => {
                dispatch(sendMessage({
                    message: 'Impossibile aggiornare preferenze: ' + error,
                    positive: false,
                }));
            });
    }


    const selectPatientHandler = (id) => {
        dispatch(selectPatient(id));
        setPatientRedirect(true);

    };

    const patientFilterHandler = () => {
        setFilteredPatients(userPatients.filter(item => {
            const name = item.patient.firstname + ' ' + item.patient.lastname;
            if (name.toLowerCase().includes(patientFilter)) {

                return item;
            }
        }));
    };


    if (patientRedirect) {
        props.patientRedir();
        setPatientFilter('');
        props.patientRedir();
        setPatientRedirect(false);


    }

    function handleMenuClick(id) {
        if (id === 0) {
            setProfileModal(true);
        } else {
            dispatch(userLogout());
            //clear session storage and localcal storage
            sessionStorage.clear();
            localStorage.clear();
        }
        setUserMenuVisible(false);
    }

    return (
        <React.Fragment>

            {patientFilter.length > 0 ?
                <ul className="patient-list patient-absolute rounded-box" >
                    {filteredPatients.length > 0 ?
                        <div className="patients-wrapper">
                            {
                                filteredPatients.map(item =>

                                    <div key={item.patient.id} className="rounded-box patient-box search" onClick={() => selectPatientHandler(item.patient.id)}>
                                        <li className="patient-list-item">
                                            <div className="avatar"></div>
                                            <p className="patient-list-name">{item.patient.firstname} {item.patient.lastname}</p>
                                        </li>
                                    </div>
                                )
                            }
                        </div>
                        : <h3>Nessun paziente trovato</h3>}
                    <button className="button mt-3 mx-auto" onClick={() => setPatientFilter('')}>Chiudi</button>
                </ul>
                : null}

            <div className="wrapper-header-search">
                <div className="col-left">
                    <div className={`reduce-menu ${toggle ? 'collapsed' : ''}`} onClick={() => { setToggle(!toggle); props.collapseMenu(); }}></div>
                    <div className="form-group">
                        {/* form neded to remove autocomplete from search field */}
                        <form autoComplete="off">
                            <input autoComplete="none" name='patient-name' type="text" className="form-control search" placeholder="Cerca paziente" value={patientFilter} onChange={(e) => setPatientFilter(e.target.value.toLowerCase())} />
                        </form>
                    </div>
                </div>
                <div className="col-right">
                    <NotificationsWidget />
                    <div className={`dropdown`}>
                        <div className="user-menu" onClick={() => setUserMenuVisible(!userMenuVisible)}>
                            <span> {userInfo.firstname + ' ' + userInfo.lastname}</span>
                            <img src={userIcon} alt="user icon" />
                        </div>
                        <div className={`dropdown-bg ${userMenuVisible ? 'show' : 'hide'}`} onClick={() => setUserMenuVisible(false)}></div>
                        <ul className={`dropdown-menu tag-modal-content ${userMenuVisible ? 'show' : 'hide'}`} >
                            {/* <li className="menu-option" onClick={() => handleMenuClick(0)}>profilo</li> */}
                            <li className="menu-option" onClick={() => handleMenuClick(1)}>logout</li>
                        </ul>
                    </div>
                </div>
            </div>
            <SimpleModal visible={passwordModal} toggleModal={() => setPasswordModal(!passwordModal)} modalTitle="Aggiorna password">
                <div className="form-group">
                    <p className="inline-error text-center">{useError ? useError : null}</p>
                    <label>Nuova password</label>
                    <input className={`form-control mb-5`} type="password" placeholder="Nuova password" value={newPasswords} onChange={(e) => setNewPasswords(e.target.value)} />
                    <label>Ripeti password</label>
                    <input className={`form-control mb-5`} type="password" placeholder="Ripeti password" value={repeatNewPasswords} onChange={(e) => setRepeatNewPasswords(e.target.value)} />
                    <button className="button secondary small mx-auto" onClick={() => updateMedicPassword(newPasswords, repeatNewPasswords)} >Cambia password</button>
                </div>


            </SimpleModal>
            <SimpleModal visible={profileModal} toggleModal={() => setProfileModal(!profileModal)} modalTitle="Profilo">
                {infoLoaded ?
                    <React.Fragment>
                        <div className="row">
                            <div className="col-12">
                                <h3>{userInfo.firstname + ' ' + userInfo.lastname}</h3>
                                <p className="mb-1">{userInfo.email}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h3 className="colored-title regular green mt-3 mb-1">Notiﬁche desktop</h3>
                                <div className="d-flex">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" checked={settings.appointmets} onChange={() => setSettings({ ...settings, appointmets: !settings.appointmets })} />
                                        <label className="custom-control-label">
                                            Notifiche <br />appuntamenti
                                        </label>
                                    </div>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" checked={settings.mood} onChange={() => setSettings({ ...settings, mood: !settings.mood })} />
                                        <label className="custom-control-label">
                                            Notifiche <br /> umore basso
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h3 className="colored-title regular green mt-3 mb-1">Notiﬁche e-mail</h3>
                                <div className="d-flex">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" checked={settings.reminder} onChange={() => setSettings({ ...settings, reminder: !settings.reminder })} />
                                        <label className="custom-control-label">
                                            Reminder appuntamenti
                                        </label>
                                    </div>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" checked={settings.science} onChange={() => setSettings({ ...settings, science: !settings.science })} />
                                        <label className="custom-control-label">
                                            Notizie e articoli scientifici
                                        </label>
                                    </div>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" checked={settings.giada} onChange={() => setSettings({ ...settings, giada: !settings.giada })} />
                                        <label className="custom-control-label">
                                            Novità su Giada
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12 d-flex align-items-center justify-content-around">
                                <img className="footer-logo" src={mediKeyLogo} alt="" />
                                <button className="button secondary small" onClick={() => updateMedicSettings(settings)}>Modifica Dati</button>
                                {/* <button className="button secondary small" onClick={() => setPasswordModal(true)} >Modifica Password</button> */}
                            </div>
                        </div>
                    </React.Fragment>
                    : <Loader />}
            </SimpleModal>
        </React.Fragment>
    );
};

export default HeaderSearch;

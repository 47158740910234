import React from 'react'
import { useSelector } from 'react-redux';
import PlusCircleW from '../assets/images/fa-icons/plus-circle-w.svg';
import PatientsGenderBars from './charts/PatientGenderBars';


export default function PatientsBarBoxComponent({ buttonClick }) {
  const userPatients = useSelector((state) => state.user.userPatients);
  const filteredPatients = useSelector((state) => state.user.filteredPatients);
  const patientsPending = useSelector((state) => state.user.patientsPending);

  return (
    <div className="rounded-box">
      <div className="rounded-box-header column">
        <p className="rounded-box-title">
          Panoramica Pazienti
        </p>
        <p className="rounded-box-subtitle">
        {
            userPatients ?
              userPatients.length !== filteredPatients.length ?
                `Selezionati: `
                :
                `Totali: `
              :
              `Totali: `
          }
          <span className='number'>
          {
            userPatients ?
              userPatients.length !== filteredPatients.length ?
                `${filteredPatients.length}`
                :
                `${filteredPatients.length}`
              :
              `${filteredPatients.length}`
          }
          </span>
        </p>
      </div>
      <PatientsGenderBars
        filteredPatients={filteredPatients}
      />
      {/* <button className="button secondary flexed small mr-auto mt-2" onClick={patientsPending.length > 0 ? buttonClick : null}>
        <img className="icon-img" src={PlusCircleW} />
        {patientsPending ? patientsPending.length : 0}  Richieste di Collegamento
      </button> */}
    </div>
  )
}

import React, { useEffect, useState } from 'react'

const PatientInfo = props => {
    const calculatedAge = calculateAge("1989-05-26T00:00:00+02:00");
    const [comorbities, setComorbities] = useState([]);
    const diagnosis = props.object ? props.object.pathologies ? props.object.pathologies.map((item, index) => {
        if(index === 0){
            return item.name;
        }else{
            return ', ' + item.name;
        }
    }) : 'Nessuna' : 'Nessuna';

    useEffect(() => {
        if(props.comorbidities && props.comorbidities.length > 0){
            setComorbities(props.comorbidities)
        }else{
            if(props.object && props.object.comorbidities){
                setComorbities(props.object.comorbidities)
            }
        }
    }, [props])
    


    function calculateAge(age){
        const date = new Date(age);
        const monthDiff = new Date(Date.now() - date.getTime());

        return Math.abs(monthDiff.getUTCFullYear() - 1970);
    }
    function getGenderClass(gender){
        switch(gender){
            case 'm':
                return 'gender-m';
            case 'f':
                return 'gender-f';
            default:
                return 'gender-x';
        }
    }

    return(
        <div className="user-wrapper">
            <div className={`avatar	${props.object ? getGenderClass(props.object.gender) : ''}`}></div>
            {props.object ? 
            <div className="user-info">
                <p className="name">{props.object.firstname ? props.object.firstname + ' ' + props.object.lastname : ''}</p>
                <p>Età: {calculatedAge} anni</p>
                <p>Diagnosi: {diagnosis}</p>
                <p>Comorbilità: {comorbities?.length > 0 ?comorbities.map((comor, index) => <span>{index > 0 ? ', ' : ''}{comor}</span>) : 'Nessuna' }</p>
            </div>
            : null}
        </div>
    )
}

export default PatientInfo;
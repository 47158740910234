import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";

import axios from 'axios';

import { selectPatient, acceptUserPatient } from '../../store/userStore';
import { calculateTotalMood } from '../../store/patientsStore';
import { sendMessage } from '../../store/messagesStore';

import PatientInfo from '../../components/patient/patientInfo';
import PatientRow from '../../components/patient/patientRow';
import SimpleModal from '../../components/simpleModal';
import SmallCalendar from 'react-calendar';

import CalendarCheck from '../../assets/images/fa-icons/calendar-check.svg';
import CalendarTimes from '../../assets/images/fa-icons/calendar-times.svg';
import PageFilters from '../../components/PageFilters';
import ActivityBoxes from '../../components/activities/ActivityBoxes';
import Pagination from '../../components/Pagination';
import getCallUrl from '../../utils/callUrlUtil';

const breadObject = {
    'umore-basso': 'Umore Basso',
    'app-non-usata': 'App non usata',
    'traguardi-incompleti': 'Tragaurdi incompleti',
    'attività-diminuzione': 'Attivtà in diminuzione',
    'aderenza-bassa': 'Aderenza bassa',
    'questionari-compilati': 'Questionari compilati',
};



const Patients = (props) => {
    const [patientRedirect, setPatientRedirect] = useState(false);
    const [showConnectionModal, setShowConnectionModal] = useState(false);
    const [showCalendar, setShowcalendar] = useState(false);
    const filteredPatients = useSelector((state) => state.user.filteredPatients);
    const patientsPending = useSelector((state) => state.user.patientsPending);
    const userToken = useSelector((state) => state.user.authToken);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    let callUrl = getCallUrl();

    useEffect(() => {
        dispatch(calculateTotalMood());

        window.scrollTo(0, 0);

        document.title = props.title || "Giada";
    }, [props.title]);

    useEffect(() => {
        const splitted = location.pathname.split('/').filter(el => el !== '' && el !== 'pazienti');
        const newBread = [];

        splitted.forEach(route => {
            newBread.push(breadObject[route]);
        });

        setBreadcrumbs(newBread);

    }, [location]);

    function patientClickHandler(id) {
        dispatch(selectPatient(id));
        setPatientRedirect(true);

    }

    const rejectUser = (id) => {
        axios.post(`${callUrl}/api/medics/pending-patients/${id}/remove`, null, {
        })
            .then(response => {
                if (response.data.status !== 'error') {
                    dispatch(acceptUserPatient({ id: id, accept: false }));
                    dispatch(sendMessage({
                        message: 'Utente rifiutato',
                        positive: true,
                    }));
                } else {
                    dispatch(sendMessage({
                        message: 'impossibile rifiutare l\'utente',
                        positive: false,
                    }));
                }
            })
            .catch(error => dispatch(sendMessage({
                message: 'Impossibile rifiutare l\'utente: ' + error,
                positive: false,
            }))
            );
    };

    const acceptUser = (id) => {
        axios.post(`${callUrl}/api/medics/pending-patients/${id}/accept`, null, {
            headers: { 'X-AUTH-TOKEN': userToken },
        })
            .then(response => {
                if (response.data.status !== 'error') {
                    dispatch(acceptUserPatient({ id: id, accept: true }));
                    dispatch(sendMessage({
                        message: 'Utente accettato',
                        positive: true,
                    }));
                } else {
                    dispatch(sendMessage({
                        message: 'impossibile accettare l\'utente',
                        positive: false,
                    }));
                }
            })
            .catch(error => dispatch(sendMessage({
                message: 'Impossibile accettare l\'utente: ' + error,
                positive: false,
            }))
            );
    };

    const pathologyFilterHandler = (value) => {
        props.pathologyFilter(value);
    };

    const selectDateRange = (event) => {
        const date = event;
        props.updateRange(date);
        setShowcalendar(false);
    };


    if (patientRedirect) {

        return (
            <Redirect to='/paziente' />
        );

    }

    return (
        <React.Fragment>
            <SimpleModal visible={showConnectionModal} toggleModal={() => setShowConnectionModal(!showConnectionModal)} modalTitle="Aggiungi Paziente">
                {patientsPending ? patientsPending.map(element => (
                    <div className="accept-wrapper">
                        <PatientInfo object={element.patient} />
                        <div className="buttons">
                            <button onClick={() => acceptUser(element.id)} className="button big accept">
                                <img className="icon-img" src={CalendarCheck} />
                                Conferma
                            </button>
                            <button onClick={() => rejectUser(element.id)} className="button big deny">
                                <img className="icon-img" src={CalendarTimes} />
                                Rifiuta
                            </button>
                        </div>
                    </div>
                )) : null}

            </SimpleModal>
            <div className="page-header">
                <div className="breadcrumbs-wrapper">
                    <div
                        onClick={() => history.push("/pazienti/")}
                        className="breadcrumbs"
                    >
                        Pazienti
                    </div>
                    <div className="breadcrumbs current">
                        {breadcrumbs.map(element =>
                        (
                            <React.Fragment>
                                <span className="breadcrumb-separator">{'>'}</span><span>{element}</span>
                            </React.Fragment>
                        ))}

                    </div>
                </div>
                <div className="page-actions right">
                    <p>Filtra per:</p>
                    <div className="select-group ">
                        <span className="pre-select">Periodo: </span>
                        <span className="select-arrow top"></span>

                        <input className="form-control month-selector" readOnly placeholder="Seleziona data" type="text" value={props.selectedRangeString ? props.selectedRangeString : ''} onClick={() => setShowcalendar(true)}></input>
                        <SimpleModal visible={showCalendar} toggleModal={() => setShowcalendar(!showCalendar)} modalTitle="Seleziona Data">
                            <SmallCalendar
                                className={`range-picker`}
                                view="year"
                                locale="it-IT"
                                onClickMonth={(event, value) => selectDateRange(event)}
                            />
                        </SimpleModal>
                    </div>
                    <PageFilters
                        currentPathology={props.currentPathology}
                        pathologyFilterHandler={(arg) => pathologyFilterHandler(arg)}
                    />
                </div>
            </div>
            <Switch>
                <Route path="/pazienti/" exact>
                    <div className="row">
                        <div className="col-12">
                            <ActivityBoxes />
                        </div>
                    </div>
                </Route>
            </Switch>
            <div className="row d-none mt-2 mb-3">
                <div className="col-12">
                    <div className="page-header">
                        <div className="page-actions right">
                            {/** To Do add filters */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <Switch>
                    <Route path="/pazienti" exact >
                        {filteredPatients ?
                            <>

                                <Pagination
                                    data={filteredPatients.filter(patient => patient.status === 'accepted').map(item =>
                                    (<div key={item.patient.id} className="col-12 col-lg-6 col-xl-12">
                                        <PatientRow
                                            patientClick={() => patientClickHandler(item.patient.id)}
                                            patient={item.patient}
                                            appointment={null}
                                        />
                                    </div>)
                                    )
                                    }

                                    pageLimit={5}
                                    dataLimit={5}
                                />
                            </>

                            : <h3>Nessun paziente presente</h3>
                        }
                    </Route>
                </Switch>

            </div>
        </React.Fragment>
    );
};

export default Patients;
import React, { useEffect, useState } from 'react';
import CalendarToolbar from '../CalendarToolbar';
import DisplayEvent from '../dayEvents/DisplayEvent';
// @ts-ignore
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/it';
import CalendarTransform from '../../../hooks/useCalendarTransformer';
import { useSelector } from 'react-redux';
import { adherenceSpecificationAtom } from '../../../jotai/adherenceSpecificationAtom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import axios from 'axios';
import getCallUrl from '../../../utils/callUrlUtil';
import { calendarPatientIdAtom, getCalendarWeeklyAdherenceAtom } from '../../../jotai/calendarWeeklyEventsAtom';
import { getCalendarWeeklyMoods } from '../../../jotai/calendarWeeklyMoodsAtom';
import { getCalendarWeeklyActivities } from '../../../jotai/calendarWeeklyActivitiesAtom';
import { patientsCompiledPHQAtom } from '../../../jotai/patientPHQAtoms';
import { getCalendarWeeklySurveys } from '../../../jotai/calendarWeeklySurveys';
import { getWeeklyGoalsAtom } from '../../../jotai/calendarWeeklyGoalsAtom';

type Props = {
  realEvents: any,
  eventsData: object,
  roundedSelected: string,
  patientID: string,
  eventClick: (arg: any) => void,
  selectedActivity: number,
  dataChanged: (arg: Date) => void,
  selectedDrug: string,
};
// { moods: currentPatientMoods, activities: activites, surveys: singleSurveyChartData, goals: getPersonalGoals(completedGoals, patient.id), adherence: calendarAdherence, appUse: userDailyUsage, clinicalEvents: clinicalEvents }

export default function ActivitiesCalendar({ realEvents, eventsData, roundedSelected, patientID, eventClick, selectedActivity, selectedDrug }: Props) {
  const callUrl = getCallUrl();
  const userToken = useSelector((state: any) => state.user.authToken);

  const [specificationFilter, setSpecificationFilter] = useAtom(adherenceSpecificationAtom);
  //@ts-ignore
  const startDate = useSelector((state) => state.user.startDate);
  const [useRealEvents, setUseRealEvents] = useState<boolean>(true);
  const [weeklyEvents, setWeelyEvents] = useState(CalendarTransform(eventsData, new Date(startDate), roundedSelected, patientID, (e: any) => eventClick(e), selectedActivity));
  const localizer = momentLocalizer(moment);
  // by default, the calendarDate will start from the first day of the month
  const [caledarDate, setCalendarDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const adherence = useAtomValue(getCalendarWeeklyAdherenceAtom);
  const moods = useAtomValue(getCalendarWeeklyMoods);
  const activities = useAtomValue(getCalendarWeeklyActivities);
  const surveys = useAtomValue(getCalendarWeeklySurveys);
  const goals = useAtomValue(getWeeklyGoalsAtom);
  const setPatientId = useSetAtom(calendarPatientIdAtom);


  useEffect(() => {
    setPatientId(patientID);
  }, [patientID]);

  useEffect(() => {
    console.log('goals', goals);
  }, [goals]);



  useEffect(() => {
    const newEventData = { adherence: adherence, activities: [activities], moods: moods, surveys: surveys, goals: goals };
    console.log('activities newEventData', newEventData);
    console.log('activities oldEventData', eventsData);
    // setWeelyEvents(CalendarTransform({ ...eventsData, adherence: adherence, }, caledarDate, roundedSelected, patientID, (e: any) => eventClick(e), selectedActivity));
    // mirko non ha finito di lavorare (strano) quindi questa parte rimarrà commentata (per ora???)
    setWeelyEvents(CalendarTransform(eventsData, new Date(startDate), roundedSelected, patientID, (e: any) => eventClick(e), selectedActivity));
  }, [eventsData, roundedSelected, patientID, selectedActivity, adherence, caledarDate, surveys, activities, moods, goals]);

  // when startDate changes, the calendarDate will be the first day of the month of the startDate
  useEffect(() => {
    setCalendarDate(new Date(new Date(startDate).getFullYear(), new Date(startDate).getMonth(), 1));
  }, [startDate]);

  useEffect(() => {
    // console.log('activities calendar', eventsData);
    loadPatientsAdherence();
  }, [caledarDate, specificationFilter]);

  const loadPatientsAdherence = () => {

    const date = caledarDate;
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    if (specificationFilter === 'week') {
      lastDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7);
    }

    if (specificationFilter === 'day') {
      lastDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
    }

    return axios.get(`${callUrl}/api/patient-drugs/therapeutic-adherences-stats/${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}/${lastDay.getFullYear()}-${lastDay.getMonth() + 1}-${lastDay.getDate() + 1}/true?patient=${patientID
      }`, {
      headers: { 'X-AUTH-TOKEN': userToken },
    })
      .then(response => {

        // console.log('response', response.data);
      })
      .catch(error => {
        console.log(error);
      }
      );

  };


  return (
    <Calendar
      localizer={localizer}
      components={{
        event: DisplayEvent,
        toolbar: CalendarToolbar,
      }}
      events={weeklyEvents}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 600 }}
      views={['month', 'week', 'day']}
      messages={{
        next: "Avanti",
        previous: "Indietro",
        today: "Oggi",
        month: "Mese",
        week: "Settimana",
        day: "Giorno"
      }}
      min={moment('0:00am', 'h:mma').toDate()}
      max={moment('0:20am', 'h:mma').toDate()}
      date={caledarDate}
      onSelectEvent={null}
      formats={formats}
      onNavigate={(date: any) => {
        console.log('onNavigate', date);
        setCalendarDate(date);
      }}
      getDrillupView={(currentViewName: string) => {
        if (currentViewName === 'month') {
          setCalendarDate(startDate);
        }
      }}

      getDrilldownView={(targetDate: any, currentViewName: string, configuredViewNames: any) => {

        if (currentViewName === 'week' || currentViewName === 'day') {
          setUseRealEvents(true);
          if (currentViewName === 'day') {
            setSpecificationFilter('day');
          } else {
            setSpecificationFilter('week');
          }
        } else {
          setUseRealEvents(true);
          setSpecificationFilter('month');
        }

        return null;
      }}
    />
  );
}
let formats = {
  weekdayFormat: 'dddd',
};
import React, { useEffect, useState } from 'react';
import ActivityCompact from '../../components/activities/activityCompact';
import AdherenceRateComponent from '../AdherenceRateComponent';
import Adeherence from '../../assets/images/icons/list.png';
import Mood from '../../assets/images/icons/dashboard.png';
import Step from '../../assets/images/icons/shoes.png';
import Survey from '../../assets/images/icons/paper.png';
import Stats from '../../assets/images/icons/stats.png';
import Danger from '../../assets/images/icons/danger.png';
import Med1 from '../../assets/images/icons/medication-1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useSelector } from 'react-redux';
import getCallUrl from '../../utils/callUrlUtil';
import { surveyArray } from '../exampleData/exampleData';
import { getFirstDayDate, getLastDayDate, getMonthString } from '../../utils/daysUtils';
import { getMedianSteps } from '../../utils/activitiesUtil';
import { getDateFilteredMoods } from '../../utils/moodUtis';
import { idText, transform } from 'typescript';
import { useAtomValue } from 'jotai';
import { patientMoodsAtom } from '../../jotai/patientMoodsAtoms';


type Props = {
    roundedSelected: string,
    setAdherenceToUse: (adherences: any) => void,
    setSurveyStats: (stats: boolean) => void,
    setUseSteps: (useSteps: boolean) => void,
};

export default function AnalysisInfo({ roundedSelected, setAdherenceToUse, setSurveyStats, setUseSteps }: Props) {
    const [selectedAdherence, setSelectedAherence] = useState<number>(0);
    const [moodInfoSelected, setMoodInfoSelected] = useState<boolean>(true);
    const [activityInfoSelected, setActivityInfoSelected] = useState<boolean>(true);
    //@ts-ignore
    const allAdherences = useSelector((state) => state.patients.allAdherences);
    //@ts-ignore
    const userToken = useSelector((state) => state.user.authToken);
    //@ts-ignore
    const filteredCompletedGoals = useSelector((state) => state.goals.filteredGoalsChartData);
    //@ts-ignore
    const completedGoals = useSelector((state) => state.goals.completedGoals);

    //@ts-ignore
    const aggregatedMood = useSelector((state) => state.patients.aggregatedMood);
    //@ts-ignore
    const totalAdherence = useSelector((state) => state.patients.totalAdherence);
    //@ts-ignore
    const patientSession = useSelector((state) => state.usage.userSessions);
    //@ts-ignore
    const activities = useSelector((state) => state.activities);

    //@ts-ignore
    const userDailyUsage = useSelector((state) => state.usage.userDailyUsage);
    //@ts-ignore
    const filteredPatients = useSelector((state) => state.user.filteredPatients);
    //@ts-ignore
    const allMoods = useSelector((state) => state.patients.moods);
    //@ts-ignore
    const allSurveysChartData = useSelector((state) => state.surveys.surveys);
    //@ts-ignore
    const startDate = useSelector((state) => state.user.startDate);
    const [useInfoFilter, setInfoFilter] = useState([
        {
            id: 0,
            name: 'Ricovero',
            count: 2,
            checked: false,
        },
        {
            id: 1,
            name: 'Ricaduta',
            count: 2,
            checked: false,
        },
        {
            id: 2,
            name: 'Tentato suicidio',
            count: 2,
            checked: false,
        },
        {
            id: 3,
            name: 'Assenza lavoro',
            count: 2,
            checked: false,
        },
    ]);
    const [phq9, setPhq9] = useState<number>(0);
    const [medianUsage, setMedianUsage] = useState<number>(0);
    const [medianSteps, setMedianSteps] = useState<number>(0);
    const patientMoods = useAtomValue(patientMoodsAtom);

    useEffect(() => {
        if (selectedAdherence === 0) {
            setAdherenceToUse(allAdherences);
        }
    }, [selectedAdherence, allAdherences]);



    useEffect(() => {
        const filteredPatientsIds = filteredPatients.map((obj: any) => obj.patient.id);
        const filteredActivities = activities.activites.filter((activity: any) => filteredPatientsIds.includes(activity.id));
        const activitiesArray = filteredActivities.map((singleAcivity: any) => singleAcivity.activities).flat();
        const startDay = getFirstDayDate(new Date(startDate).getMonth() + 1, new Date(startDate).getFullYear());
        const dateFilteredActivities = activitiesArray.filter((activity: any) => activity.date.split('-')[1] === ('0' + (startDay.getMonth() + 1).toString()).slice(-2));

        setMedianSteps(getMedianSteps(dateFilteredActivities));
    }, [activities, startDate]);

    useEffect(() => {
        if (filteredPatients.length > 0 && userDailyUsage.length > 0) {
            const filteredPatientsIds = filteredPatients.map((obj: any) => obj.patient.id);
            const filteredUsage = userDailyUsage.filter((obj: any) => filteredPatientsIds.includes(obj.id));
            const startDay = getFirstDayDate(new Date(startDate).getMonth() + 1, new Date(startDate).getFullYear());
            const dateFilteredSurveys = filteredUsage.filter((use: any) => use.date.split('-')[1] === (startDay.getMonth() + 1).toString());
            // map usage from filteredUsage and get sum
            const sum = dateFilteredSurveys.map((obj: any) => obj.usage).reduce((a: any, b: any) => a + b, 0);
            if (dateFilteredSurveys.length > 0) {

                setMedianUsage(Math.round(sum / dateFilteredSurveys.length));
            } else {
                setMedianUsage(0);
            }
        } else {
            setMedianUsage(0);
        }

    }, [userDailyUsage, filteredPatients, startDate]);

    useEffect(() => {
        const filteredPatientsIds = filteredPatients.map((obj: any) => obj.patient.id);
        const filteredSurveys = allSurveysChartData.filter((el: any) => filteredPatientsIds.includes(el.id));
        const surveys = filteredSurveys.map((el: any) => el.surveyArray).flat();
        const startDay = getFirstDayDate(new Date(startDate).getMonth() + 1, new Date(startDate).getFullYear());
        const lastDay = getLastDayDate(new Date(startDate).getMonth() + 1, new Date(startDate).getFullYear());
        const dateFilteredSurveys = surveys.filter((survey: any) => new Date(survey.date) >= startDay && new Date(survey.date) <= lastDay);
        let score = 0;
        dateFilteredSurveys.forEach((el: any) => {
            score += el.score;
        });
        if (dateFilteredSurveys.length > 0) {
            setPhq9(score / dateFilteredSurveys.length);
        } else {
            setPhq9(0);
        }
    }, [allSurveysChartData, startDate, filteredPatients]);

    useEffect(() => {
        if (roundedSelected === 'terapia') {
            setSelectedAherence(0);
        }
    }, [roundedSelected]);

    const getOnlySystemGoals = () => {
        let totalSystemGoals = 0;

        filteredPatients.forEach((obj: any) => {
            totalSystemGoals += obj.patient.in_app_goals_completed;
        });

        return totalSystemGoals;
    };

    const getSystemGoals = () => {
        const filteredPatientsIds = filteredPatients.map((obj: any) => obj.patient.id);
        const patientGoals = completedGoals.filter((e: any) => filteredPatientsIds.includes(e.id)).map((e: any) => e.goalsArray);
        const startDay = getFirstDayDate(new Date(startDate).getMonth() + 1, new Date(startDate).getFullYear());
        const goalsArray: any[] = [];


        patientGoals.forEach((element: any) => {
            element.forEach((goal: any) => {
                if (goalsArray.findIndex((e: any) => e.id === goal.id) === -1) {
                    if (goal.completed_at) {
                        const completedAt = new Date(goal.completed_at);
                        if (completedAt.getMonth() + 1 >= startDay.getMonth() + 1 && completedAt.getFullYear() >= startDay.getFullYear()) {
                            const createdAt = new Date(goal.created_at);
                            if (createdAt.getMonth() + 1 <= startDay.getMonth() + 1 && createdAt.getFullYear() <= startDay.getFullYear()) {
                                goalsArray.push(goal);
                            }
                        }
                    }
                }
            });
        });

        if (goalsArray.length > 0) {
            return goalsArray.length;
        } else {
            return 0;
        }

    };

    function getMoods() {
        const moodInFilteredPatients = patientMoods.filter((mood: any) => filteredPatients.map((obj: any) => obj.patient.id).includes(mood.patientId));
        const mappenHistory = moodInFilteredPatients.map((mood: any) => mood.moodHistory)?.flat();

        console.log('mappenHistory', mappenHistory);
        console.log('moodInFilteredPatients', moodInFilteredPatients);

        if (mappenHistory && mappenHistory.length > 0) {
            //return aggregated moods mean
            return moodInFilteredPatients.length > 0 ? Math.round(moodInFilteredPatients.map((mood: any) => mood.aggregateMood).reduce((a: any, b: any) => a + b, 0) / moodInFilteredPatients.length) + ' / 5' : 'ND';
        } else {
            return 'ND';
        }

    }

    function getPatientSession(sessions: any, patients: any) {
        const filteredSessions = sessions.filter((el: any) => patients.map((obj: any) => obj.patient.id).includes(el.id));
        const patientUsage = filteredSessions.map((e: any) => e.session);

        if (patientUsage.length > 0) {
            const session = Math.round(patientUsage.reduce((a: any, b: any) => a + b, 0) / patientUsage.length);
            if (session > 0) {
                return (<h5>{session} volte al giorno</h5>);

            } else {
                return (<h5>meno di 1 volta al giorno</h5>);
            }
            return;

        } else {
            return (<h5>ND</h5>);
        }
    }


    function getAllDrugs() {
        const drugs: any[] = [];

        filteredPatients.forEach((el: any) => {
            if (el.patient.patient_drugs) {
                el.patient.patient_drugs.forEach((drug: any) => {
                    if (!drug.suspended) {
                        drugs.push(drug);
                    }
                });
            }
        });

        //@ts-ignore
        return [...new Set(drugs)];
    }

    const infoHandler = (index: any) => {
        setInfoFilter(useInfoFilter.map((item: any) => item.id === index ? {
            ...item,
            checked: !useInfoFilter[index].checked
        } : item));
    };

    function moodToggleHandler(val: boolean) {
        setMoodInfoSelected(val);
        setSurveyStats(val);
    }

    function stepsToggleHandler(val: boolean) {
        setActivityInfoSelected(val);
        setUseSteps(val);
    }

    const setSingleDrugAdherenceData = (drugID: any, adherenceId: any, startDate: string) => {
        setSelectedAherence(adherenceId);

        const date = new Date(startDate);
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        axios.get(`${getCallUrl()}/api/patient-drugs/therapeutic-adherences-stats/${date.getFullYear()}-${date.getMonth() + 1}-01/${date.getFullYear()}-${date.getMonth() + 1}-${lastDay.getDate()}/true?patient=&patientDrug=${drugID}`, {
            headers: { 'X-AUTH-TOKEN': userToken },
        })
            .then(response => {
                // console.log('single drug adherence', response);s
                const newAdherenceToUse: any[] = [];

                response.data.data.data.forEach((el: any) => {
                    const elDate = new Date(el.date);
                    const transformedDate = `${elDate.getDate()}-${elDate.getMonth()}-${elDate.getFullYear()}`;
                    newAdherenceToUse.push({ ...el, date: transformedDate, averageValue: el.rate });
                });
                setAdherenceToUse(newAdherenceToUse);
            })
            .catch(error => console.log(error));

    };



    const sectionInfoHandler = (moodSelected: boolean, activitySelected: boolean) => {

        switch (roundedSelected) {
            case 'terapia':
                break;
            case 'attività':
                return (
                    <React.Fragment>
                        <div onClick={() => stepsToggleHandler(true)}>
                            <ActivityCompact
                                type={Step}
                                title="Passi"
                                data={Math.round(medianSteps)}
                                selectable={true}
                                selected={activitySelected}
                            />
                        </div>
                        <div onClick={() => stepsToggleHandler(false)}>
                            <ActivityCompact
                                type={Stats}
                                title="KM"
                                data={Math.round(medianSteps * 0.0008)}
                                selectable={true}
                                selected={!activitySelected}
                            />
                        </div>
                        <div className="disclaimer">
                            <p>
                                Il tipo di dati raccolti sono sulla base delle autorizzazioni del paziente e possono variare da paziente a paziente.
                            </p>
                        </div>
                    </React.Fragment>
                );
            case 'traguardi':
                return (
                    <React.Fragment>
                        <h5 className="mb-1">Traguardi di sistema: {getOnlySystemGoals()}</h5>
                        <hr></hr>
                        <h5 className="mt-3 mb-2">Traguardi personali</h5>
                        <div className="wrapper-box gray text-center">
                            <h2 className="mb-0">{getSystemGoals()} raggiunti</h2>
                        </div>

                    </React.Fragment>
                );
            case 'utilizzo app':
                return (
                    <React.Fragment>
                        <h5 className="mt-3">Utilizzo medio</h5>
                        <div className="wrapper-box gray">
                            <h5>{getPatientSession(patientSession, filteredPatients)}</h5>
                        </div>
                        <div className="wrapper-box gray">
                            <h5>{medianUsage} minuti a sessione</h5>
                        </div>

                    </React.Fragment>
                );
            case 'info cliniche':
                return (
                    <React.Fragment>
                        <ActivityCompact
                            type={Danger}
                            title="Eventi Clinici"
                            data="4"
                        />
                        {
                            useInfoFilter.map((item: any) => {
                                return (
                                    <div className={`wrapper-box green filter-box ${item.id === 0 ? 'mt-5' : ''}`}>
                                        <div onClick={() => infoHandler(item.id)} className={`filter-check ${item.checked ? 'checked' : ''}`}>
                                            <FontAwesomeIcon icon="check" />
                                        </div>
                                        <p>{item.count}</p>
                                        <p>{item.name}</p>
                                    </div>
                                );
                            })
                        }

                        <button className="button secondary mt-2 mx-auto"><FontAwesomeIcon icon="plus-circle" /> Aggiungi</button>
                    </React.Fragment>
                );
            default:
                return (
                    <React.Fragment>
                        <div onClick={() => moodToggleHandler(true)}>
                            <ActivityCompact
                                type={Mood}
                                title="Umore"
                                data={getMoods()}
                                selectable={true}
                                selected={moodSelected}
                            />
                        </div>
                        <div onClick={() => moodToggleHandler(false)}>
                            <ActivityCompact
                                type={Survey}
                                title="Media PHQ9"
                                data={phq9 % 1 != 0 ? (phq9).toFixed(1) : phq9}
                                selectable={true}
                                selected={!moodSelected}
                            />
                        </div>
                    </React.Fragment>
                );
        }
    };

    return (
        <React.Fragment>
            {sectionInfoHandler(moodInfoSelected, activityInfoSelected)}
            {roundedSelected === 'terapia' ? <React.Fragment>
                <div onClick={() => setSelectedAherence(0)}>
                    <ActivityCompact
                        type={Adeherence}
                        title="Media Aderenza"
                        special={'analysis'}
                        selectable={true}
                        selected={selectedAdherence === 0}
                        data={<AdherenceRateComponent patientID={filteredPatients.map((el: any) => el.patient.id)} drugID={''} userToken={userToken} />}
                    />
                </div>
                {
                    getAllDrugs().map((drugObj, index) => {
                        console.log('drugObj', drugObj);
                        if (!drugObj.suspended) {
                            return (
                                !drugObj.end_date || new Date(drugObj.end_date) >= new Date() ?
                                    <div className={`medication-box type-1 ${selectedAdherence === index + 1 ? 'selected' : ''}`} onClick={() => setSingleDrugAdherenceData(drugObj.id, index + 1, startDate)}>
                                        <div className="medication-img">
                                            <img src={Med1}></img>
                                        </div>
                                        <div className="medication-info">
                                            <p className="medication-name">{drugObj.drug.name}</p>
                                        </div>
                                        <div className="medication-adeherence"><AdherenceRateComponent patientID={filteredPatients.map((el: any) => el.patient.id)} drugID={drugObj.id} userToken={userToken} /></div>
                                    </div> : null
                            );
                        } else {
                            return <></>;
                        }
                    })
                }
            </React.Fragment> : null}
        </React.Fragment>
    );
}



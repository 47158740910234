import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    events: [],
    taggedUncofirmedEvents: [],
    deletedEvents: [],
    calendarGrouped: null,
    nextEvent: null,
    dayEvents: null,
    selectedEvent: null,
    eventsLoaded: false,
    eventAdded: false,
    calendarContext: {
        show: false,
        xPos: 0,
        yPos: 0,
    }
};

export const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        loadEvents: (state, action) => {
            const events = action.payload.calendar_events;
            const unconfirmedEvents = [];
            let nextEvent = null;

            //foreach tagged_event in payload.tagged_events if .confirmed_by_medic is true push it to events array
            if (action.payload.tagged_events) {
                action.payload.tagged_events.forEach(element => {
                    if (element.confirmed_by_medic) {
                        events.push(element);
                    } else {
                        unconfirmedEvents.push(element);
                    }
                });
            }

            if (events.length > 0) {
                const today = new Date().setHours(0, 0, 0, 0);

                events.forEach(element => {
                    const eventDate = new Date(element.date).setHours(0, 0, 0, 0);


                    if (nextEvent === null && today < eventDate) {
                        nextEvent = element;
                    }
                });

            }

            state.events = events;
            state.nextEvent = nextEvent;
            state.taggedUncofirmedEvents = unconfirmedEvents;


            state.calendarGrouped = groupEvents(events);
        },
        getEventByDate: (state, action) => {
            const dayEvents = [];
            const passedDate = new Date(action.payload).setHours(0, 0, 0, 0);

            if (state.events) {
                state.events.forEach(element => {
                    const elementDate = new Date(element.date).setHours(0, 0, 0, 0);
                    if (passedDate === elementDate) {
                        dayEvents.push(element);
                    }
                });
            }

            state.dayEvents = dayEvents;
        },
        getTaggedEvent: (state, action) => {
            const taggedEvent = state.taggedUncofirmedEvents.find(element => element.id === action.payload);

            state.dayEvents = [{
                ...taggedEvent,
                confirmed_by_medic: true,
                taggedType: true,
            }];
        },
        selectEvent: (state, action) => {
            const index = action.payload ? state.events.findIndex(e => e.id === action.payload) : -1;

            if (index !== -1) {
                state.selectedEvent = state.events[index];
            }
        },
        deleteEvent: (state, action) => {
            const filteredEvents = state.events.filter(event => event.id !== action.payload || !state.deletedEvents.includes(event.id));
            state.events = filteredEvents;
            state.calendarGrouped = groupEvents(filteredEvents);
            state.deletedEvents.push(action.payload);
        }
    }

});

export const { loadEvents, getEventByDate, selectEvent, deleteEvent, getTaggedEvent } = eventsSlice.actions;


export default eventsSlice.reducer;

function groupEvents(events) {

    let sortedArray = [];

    if (events && events.length > 1) {
        const arraytoSort = [...events];
        sortedArray = arraytoSort.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
        });
    }

    /** Prepare events for calendar visualization */

    const eventsGrouped = [];

    if (sortedArray.length > 0) {
        sortedArray.forEach(element => {
            const elementDate = new Date(element.date);
            const elementAppoimentEnd = new Date(elementDate.getTime() + 30 * 60000);

            const eventIndex = eventsGrouped.findIndex(e => e.date === elementDate.setHours(0, 0, 0, 0));

            if (eventIndex !== -1) {
                eventsGrouped[eventIndex].eventsNumber = eventsGrouped[eventIndex].eventsNumber + 1;
            } else {
                eventsGrouped.push({
                    title: 'calendar event',
                    start: elementDate.toString(),
                    end: elementAppoimentEnd.toString(),
                    eventsNumber: 1,
                });
            }

        });
    } else if (events[0]) {
        const elementDate = new Date(events[0].date);
        const elementAppoimentEnd = new Date(elementDate.getTime() + 30 * 60000);
        eventsGrouped.push({
            title: 'calendar event',
            start: elementDate.toString(),
            end: elementAppoimentEnd.toString(),
            eventsNumber: 1,
        });
    }

    return eventsGrouped;

}
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';


const initialState = {
    goals: [],
    plainGoals: [],
    completedGoals: [],
    filteredCompletedGoals: [],
    filteredGoalsChartData: [],
    selectedPatientGoalsChartData: [],
    uncompletedGoals: [],
    completedGoalsTotal: 0,
};

export const goalsSlice = createSlice({
    name: 'goals',
    initialState,
    reducers: {
        resetGoals: (state, action) => {
            state.goals.length = 0;
            state.plainGoals.length = 0;
            state.completedGoals.length = 0;
        },
        addGoals: (state, action) => {
            const userCompleted = [];
            const goalsArray = state.goals;
            const plainGoals = [];
            const completedGoalsArray = state.completedGoals;
            let completedTotal = state.completedGoalsTotal;
            const newGoal = {
                id: action.payload.patientID,
                goalsArray: action.payload.goalsArray,
            };

            // if goal is not present in the goals array, adds it
            if (goalsArray.findIndex(e => e === newGoal) === -1) {

                goalsArray.push(newGoal);
            }

            plainGoals.push(action.payload.goalsArray);

            state.goals = goalsArray;

            action.payload.goalsArray.forEach(element => {
                if (element.completed) {
                    userCompleted.push(element);
                } else {
                    state.uncompletedGoals.push(action.payload.patientID);
                }
            });

            // console.log('userCompleted', userCompleted);

            if (userCompleted.length > 0) {
                completedTotal += userCompleted.length;

                completedGoalsArray.push({
                    id: action.payload.patientID,
                    goalsArray: userCompleted,
                });

                state.completedGoals = completedGoalsArray;
                state.completedGoalsTotal = completedTotal;
            }

            if (action.payload.startDate) {

            } else {
                const date = new Date();
                const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                const daysArray = getDaysArray(firstDay, lastDay);
                const completedGoalsFiltering = [];
                const chartCompletedFiltering = [];

                daysArray.forEach(element => {
                    const convertedDate = `${element.getDate()}-${element.getMonth()}-${element.getFullYear()}`;
                    completedGoalsArray.forEach(goal => {
                        goal.goalsArray.forEach(completedGoal => {
                            const goalDate = new Date(completedGoal.created_at);
                            const convertedGoalDate = `${goalDate.getDate()}-${goalDate.getMonth()}-${goalDate.getFullYear()}`;
                            if (convertedDate === convertedGoalDate) {

                                const dataIndex = chartCompletedFiltering.findIndex(e => e.date === convertedGoalDate);

                                if (dataIndex !== -1) {
                                    chartCompletedFiltering[dataIndex].averageValue = chartCompletedFiltering[dataIndex].averageValue + 1;
                                } else {
                                    chartCompletedFiltering.push({
                                        date: convertedGoalDate,
                                        averageValue: 1,
                                    });
                                }
                            }
                        });
                    });
                });
                userCompleted.forEach(goal => {

                    const goalDate = new Date(goal.created_at);
                    // console.log('goalDate', goal);
                    const convertedGoalDate = `${goalDate.getDate()}-${goalDate.getMonth()}-${goalDate.getFullYear()}`;
                    chartCompletedFiltering.push({
                        date: convertedGoalDate,
                        averageValue: 1,
                    });
                });

                state.filteredCompletedGoals = completedGoalsFiltering;
                state.filteredGoalsChartData = chartCompletedFiltering;

            }
        },
        setPatientChartData: (state, action) => {
            const patientChartData = [];
            const completedGoals = state.filteredCompletedGoals;


            completedGoals.forEach(goal => {
                if (action.payload === goal.id) {
                    const arrayIndex = patientChartData.findIndex(e => e.date === goal.date);

                    if (arrayIndex !== -1) {
                        patientChartData[arrayIndex].averageValue = patientChartData[arrayIndex].averageValue + 1;
                    } else {
                        patientChartData.push({
                            title: goal.goal.title,
                            description: goal.goal.description,
                            date: goal.date,
                            averageValue: 1,
                        });
                    }


                }
            });

            state.selectedPatientGoalsChartData = patientChartData;


        },
        addNewGoal: (state, action) => {
            const goals = state.goals;

            const userIndex = goals.findIndex(e => e.id === action.payload.userID);

            if (userIndex !== -1) {
                goals[userIndex].goalsArray.push({
                    id: uuid(),
                    title: action.payload.title,
                    type: action.payload.type,
                    description: action.payload.description,
                    completed: false,
                    created_at: new Date(),
                });
            }


        }
    }
});

const getDaysArray = (start, end) => {
    for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
    }
    return arr;
};


export const { addGoals, setPatientChartData, addNewGoal, resetGoals } = goalsSlice.actions;

export default goalsSlice.reducer;
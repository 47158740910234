import React, { useState, useEffect, Suspense } from 'react';
import TextareaModal from "./../../components/textareaModal";
import {
    BrowserRouter as Redirect,
    useHistory
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import * as patientStore from '../../store/patientsStore';
import { setPatientChartData } from '../../store/goalsStore';
import { getSingleChart } from '../../store/surveysStore';
import { sendMessage } from '../../store/messagesStore';
import { loadPatientClinicalEvent } from '../../store/clinicalEventStore';
import AddPatientGoal from '../../components/AddPatientGoal';

import ActivityCompact from '../../components/activities/activityCompact';
import Caregiver from '../../components/Caregiver';
import SimpleModal from '../../components/simpleModal';
import AddAppointments from '../../components/AddAppointments';
import PatientInfo from '../../components/patient/patientInfo';
import EventModalBuilder from '../../components/eventModalBuilder';
import SmallCalendar from 'react-calendar';
import MultiChartElement from '../../components/charts/MultiChartElement';
import 'moment/locale/it';

import DailyUse from '../../assets/images/icons/phone.png';
import Adeherence from '../../assets/images/icons/list.png';
import Mood from '../../assets/images/icons/dashboard.png';
import Step from '../../assets/images/icons/shoes.png';
import Goal from '../../assets/images/icons/cup.png';
import Survey from '../../assets/images/icons/paper.png';
import CalendarCheckB from '../../assets/images/fa-icons/calendar-check-b.svg';
import CalendarPlusW from '../../assets/images/fa-icons/calendar-plus-w.svg';
import Clock from '../../assets/images/fa-icons/clock.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-calendar/dist/Calendar.css';
import getCallUrl from '../../utils/callUrlUtil';
import ActivitiesCalendar from '../calendar/ActivitiesCalendar/ActivitiesCalendar';
import Comorbility from './Comorbility';
import SectionInfo from './SectionInfo';
import thousandsSeparator from '../../utils/thousandsSeparatorUtil';
import AdherenceRateComponent from '../AdherenceRateComponent';
import { useAtomValue, useSetAtom } from 'jotai';
import { patientMoodsAtom } from '../../jotai/patientMoodsAtoms';
import { getPersonalGoals } from '../../utils/getPatientGoals';
import { useFilteredPHQ } from '../../hooks/useFilteredPHQ';
import { getFirstDayDate } from '../../utils/daysUtils';
import { calendarDrugIdAtom, calendarPatientIdAtom } from '../../jotai/calendarWeeklyEventsAtom';

let realEvents = [];

const Patient = (props) => {
    const [roundedSelected, setRoundedSelected] = useState('umore');
    const [selectedEvent, setselectedEvent] = useState(null);
    const [showCalendar, setShowcalendar] = useState(false);
    const [showAppointmentModal, setShowAppointmentModal] = useState(false);
    const [showEventModal, setShowEventModal] = useState(false);
    const [showGoalModal, setShowGoalModal] = useState(false);
    const [phqCompiled, setPhqCompiled] = useState(false);
    const dispatch = useDispatch();
    const userToken = useSelector((state) => state.user.authToken);
    const moods = useSelector((state) => state.patients.moods);
    const currentPatientMoods = useSelector((state) => state.patients.currentPatientMoods);
    const patientsAdherences = useSelector((state) => state.patients.patientsAdherences);
    const currentPatientAdherence = useSelector((state) => state.patients.currentPatientAdherence);
    const selectedPatientGoalsChartData = useSelector((state) => state.goals.selectedPatientGoalsChartData);
    //@ts-ignore
    const completedGoals = useSelector((state) => state.goals.completedGoals);
    const singleSurveyChartData = useSelector((state) => state.surveys.singleSurveyChartData);
    const userDailyUsage = useSelector((state) => state.usage.userDailyUsage);
    const patient = useSelector((state) => state.user.selectedPatient.patient ?? null);
    const [patientComorbities, setPatientComorbities] = useState(useSelector((state) => state.user.selectedPatient.comorbidities) ?? []);
    const patientMedicId = useSelector((state) => state.user.selectedPatient ? state.user.selectedPatient.id : null);
    const patientNote = useSelector((state) => state.user.selectedPatient ? state.user.selectedPatient.patient.note : null);
    const activites = useSelector((state) => state.activities.activites);
    const events = useSelector((state) => state.events.events);
    const clinicalEvents = useSelector((state) => state.clinicalEvents.clinicalEvents);
    const patientClinicalEvents = useSelector((state) => state.clinicalEvents.patientClinicalEvents);
    const currentDate = useSelector((state) => state.user.startDate);
    let nextEvent = {};
    const [useClinicalEvents, setClinicalEvents] = useState([]);
    const [clinicalEventsCount, setClinicalEventsCount] = useState(0);
    const appointmentDates = [];
    const [currentAdherence, setCurrentAdherence] = useState(currentPatientAdherence);
    const [calendarAdherence, setCalendarAdherence] = useState(currentPatientAdherence);
    const [selectedActivity, setSelectedActivity] = useState(0);
    const history = useHistory();
    const [selectedDrugId, setSelectedDrugId] = useState('');
    // real selected drug id, used because the service return 2 drug.id, one is used for the api calls, the other is the real drug id
    const [realSelectedDrugId, setRealSelectedDrugId] = useState('');
    const patientMoods = useAtomValue(patientMoodsAtom);
    const allGoals = useSelector((state) => state.goals.goals);
    const startDate = useSelector((state) => state.user.startDate);
    const setCalendarPatientIdAtom = useSetAtom(calendarPatientIdAtom);
    const setCalendarDrug = useSetAtom(calendarDrugIdAtom);

    let callUrl = getCallUrl();

    useEffect(() => {
        // console.log('selectedPatientGoalsChartData', selectedPatientGoalsChartData);
    }, [selectedPatientGoalsChartData]);

    useEffect(() => {
        document.title = props.title || "Giada";

        window.scrollTo(0, 0);

        const convertedCurrentDate = currentDate ? new Date(currentDate) : new Date();

        if (patient.id) {
            setCalendarPatientIdAtom(patient.id);
            dispatch(setPatientChartData(patient.id));
            dispatch(getSingleChart({ id: patient.id, startDate: new Date(convertedCurrentDate.getFullYear(), convertedCurrentDate.getMonth(), 1), endDate: new Date(convertedCurrentDate.getFullYear(), convertedCurrentDate.getMonth() + 1, 0) }));
            dispatch(patientStore.setSinglePatientMoods({ moods: moods, id: patient.id }));
            setAppointmentDates();
            dispatch(patientStore.setSinglePatientAdherence({ adherences: patientsAdherences, id: patient.id }));
            dispatch(loadPatientClinicalEvent(patient.id));
        }

        if (patientClinicalEvents) {
            patientClinicalLoad();
        }

    }, [props.title, patient, currentDate, moods]);

    useEffect(() => {
        setCurrentAdherence(patientsAdherences.filter(adherence => adherence.patientID === patient.id)[0]?.adherenceArray);
        setCalendarAdherence(patientsAdherences.filter(adherence => adherence.patientID === patient.id)[0]?.adherenceArray);
    }, [patient, patientsAdherences, currentAdherence, currentPatientAdherence]);

    const clickedEvent = (e) => {
        setselectedEvent(e);
        setShowEventModal(true);
    };

    const setAppointmentDates = () => {
        if (events) {
            events.forEach(e => {
                const userEventIndex = e.tagged_users.findIndex(element => element.id === patient.id);

                if (userEventIndex !== -1) {
                    const elementDate = new Date(e.date);
                    appointmentDates.push(`${elementDate.getFullYear()}-${elementDate.getMonth()}-${elementDate.getDate()}`);
                }

            });
        }
    };

    const patientClinicalLoad = () => {
        const patientClinicals = [];
        let clinicalCount = 0;

        patientClinicalEvents.forEach((element, index) => {
            const clinicalIndex = patientClinicals.findIndex(e => e.name === element.title);
            clinicalCount++;

            if (clinicalIndex === -1) {
                patientClinicals.push({
                    id: index,
                    name: element.title,
                    count: 1,
                    checked: true,
                });
            } else {
                patientClinicals[clinicalIndex].count = patientClinicals[clinicalIndex].count + 1;
            }

        });


        setClinicalEventsCount(clinicalCount);
        setClinicalEvents(patientClinicals);
    };

    const infoHandler = index => {
        setClinicalEvents(useClinicalEvents.map(item => item.id === index ? {
            ...item,
            checked: !useClinicalEvents[index].checked
        } : item));
    };




    const getPatientMood = (patientId) => {
        const patientMoodsFiltered = patientMoods.filter(e => e.patientId === patientId);

        if (!patientMoodsFiltered || !patientMoodsFiltered[0] || patientMoodsFiltered[0].moodHistory.length === 0) {
            return 'ND';
        } else {
            // round the aggregatedMood mood to the nearest integer
            const aggregatedMood = patientMoodsFiltered[0].aggregateMood;

            return Math.round(aggregatedMood) + '/5';
        }
    };

    const getPatientGoals = () => {
        const startDay = getFirstDayDate(new Date(startDate).getMonth() + 1, new Date(startDate).getFullYear());
        const patientGoals = allGoals.filter((e) => e.id === patient.id);
        const goalsArray = [];

        patientGoals.forEach((element) => {
            element.goalsArray.forEach((goal) => {
                if (goalsArray.findIndex((e) => e.id === goal.id) === -1) {
                    if (goal.completed) {
                        if (goal.completed_at) {
                            const completedAt = new Date(goal.completed_at);
                            if (completedAt.getMonth() + 1 >= startDay.getMonth() + 1 && completedAt.getFullYear() >= startDay.getFullYear()) {
                                const createdAt = new Date(goal.created_at);
                                if (createdAt.getMonth() + 1 <= startDay.getMonth() + 1 && createdAt.getFullYear() <= startDay.getFullYear()) {
                                    goalsArray.push(goal);
                                }
                            }
                        } else {
                            const createdAt = new Date(goal.created_at);
                            if (createdAt.getMonth() + 1 <= startDay.getMonth() + 1 && createdAt.getFullYear() <= startDay.getFullYear()) {
                                goalsArray.push(goal);
                            }
                        }
                    }

                }
            });
        });


        return goalsArray.length + patient.in_app_goals_completed;
    };

    const getPatientUsage = () => {
        const usageIndex = userDailyUsage.findIndex(e => e.id === patient.id);

        if (usageIndex !== -1) {
            return userDailyUsage[usageIndex].usage;
        } else {
            return 'ND';
        }
    };



    const getPhq = () => {
        if (patient.last_survey_date != null) {
            const day = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            const firstDate = new Date();
            const secondDate = new Date(patient.last_survey_date);

            const diffDays = Math.round(Math.abs((firstDate - secondDate) / day));

            return diffDays <= 28 ? 'Compilato' : 'Non compilato';
        } else {
            return 'Non compilato';
        }


    };

    const getEvent = () => {
        let eventSelected = null;

        if (events) {
            events.forEach(e => {
                if (!eventSelected && new Date(e.date) > new Date()) {
                    const userEventIndex = e.tagged_users.findIndex(element => element.id === patient.id);

                    if (userEventIndex !== -1) {
                        eventSelected = e;
                    }
                }
            });
        }

        if (eventSelected) {
            nextEvent = {
                name: eventSelected.title,
                diagnosis: null,
                eventTitle: null,
                eventDays: `${new Date(eventSelected.date).getDate()}-${new Date(eventSelected.date).getMonth() + 1}-${new Date(eventSelected.date).getFullYear()}`,
                eventTiming: `${new Date(eventSelected.date).getHours()} ${new Date(eventSelected.date).getMinutes()}`,
            };

            return true;
        } else {
            return false;
        }
    };

    const [noteOpen, setNoteOpen] = useState(false);
    const [noteText, setNoteText] = useState(patientNote ? patientNote : "Inserisci qui la nota...");
    const [tempNoteText, setTempNoteText] = useState(patientNote ? patientNote : "Inserisci qui la nota...");

    useEffect(() => {
        setNoteText(patientNote);
        setTempNoteText(patientNote);
    }, [patientNote]);

    const openNote = () => {
        setNoteOpen(true);
    };

    const saveNote = () => {
        setNoteText(tempNoteText);

        axios.post(`${callUrl}/api/medics/patients/${patientMedicId}/update-note`, { note: tempNoteText }, {
            headers: { 'X-AUTH-TOKEN': userToken },
        })
            .then(response => {
                dispatch(sendMessage({
                    message: 'Nota salvata correttamente',
                    positive: true,
                }));

                setNoteOpen(false);
                setTempNoteText(tempNoteText);

            })
            .catch(error => {
                console.log(error);
                dispatch(sendMessage({
                    message: 'Impossibile salvare la nota: ' + error,
                    positive: false,
                }));
            });

    };

    const closeNote = () => {
        setNoteOpen(false);
        setTempNoteText(noteText);
    };

    const selectDateRange = (event) => {
        const date = event;
        props.updateRange(date);
        setShowcalendar(false);
    };

    const setSingleDrugAdherenceData = (drugID, adherenceId, startDate) => {
        setCalendarDrug(drugID);
        setSelectedDrugId(drugID);
        setSelectedActivity(adherenceId);

        const date = new Date(startDate);
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        axios.get(`${callUrl}/api/patient-drugs/therapeutic-adherences-stats/${date.getFullYear()}-${date.getMonth() + 1}-01/${date.getFullYear()}-${date.getMonth() + 1}-${lastDay.getDate()}/true?patient=${patient.id}&patientDrug=${drugID}`, {
            headers: { 'X-AUTH-TOKEN': userToken },
        })
            .then(response => {
                setCalendarAdherence(response.data.data);
            })
            .catch(error => console.log(error));

    };

    const getPatientSteps = () => {
        const activitiesIndex = activites.findIndex(e => e.id === patient.id);
        let totalSteps = 0;


        if (activitiesIndex !== -1) {

            activites[activitiesIndex].activities.forEach(e => {
                totalSteps += e.total_steps;
            });

        }

        return activitiesIndex === -1 ? 'ND' : thousandsSeparator(totalSteps) + ' Passi';


    };


    return (
        <React.Fragment>
            {patient === null ? <Redirect to="/pazienti" /> : null}
            <TextareaModal visible={noteOpen} toggleModal={() => closeNote()}>
                <textarea id="textAreaNote" value={tempNoteText} onChange={(e) => setTempNoteText(e.target.value)}></textarea>
                <div className="d-flex justify-content-center mx-auto">
                    <button className="button secondary mr-2" onClick={() => saveNote()}>SALVA</button>
                    <button className="button secondary" onClick={() => closeNote()}>ANNULLA</button>
                </div>
            </TextareaModal>

            <SimpleModal visible={showGoalModal} toggleModal={() => setShowGoalModal(false)} modalTitle={'Aggiungi traguardo'}>
                <AddPatientGoal
                    closeModal={() => setShowGoalModal(false)}
                    userID={patient.id}
                />
            </SimpleModal>
            <SimpleModal visible={showEventModal} toggleModal={() => setShowEventModal(false)} modalTitle={selectedEvent ? `${selectedEvent.start.getDate()} ${selectedEvent.start.toLocaleString('default', { month: 'long' })} ${selectedEvent.start.getFullYear()}` : ''}>
                <EventModalBuilder
                    patient={patient}
                    selected={roundedSelected}
                    event={selectedEvent}
                    selectedDrugId={realSelectedDrugId}
                    selectedActivity={selectedActivity}
                />
            </SimpleModal>

            <SimpleModal visible={showAppointmentModal} toggleModal={() => setShowAppointmentModal(!showAppointmentModal)} modalTitle="Aggiungi appuntamento">
                <AddAppointments visible={showAppointmentModal} patientData={patient} avoidReset={true} />
            </SimpleModal>

            <div className="page-header">
                <div className="breadcrumbs-wrapper">
                    <div
                        onClick={() => history.push("/pazienti/")}
                        className="breadcrumbs"
                    >
                        Pazienti
                    </div>
                    <div className="breadcrumbs current">
                        <React.Fragment>
                            <span className="breadcrumb-separator">{'>'}</span><span>{patient.firstname} {patient.lastname}</span>
                        </React.Fragment>
                    </div>
                </div>
                <div className="page-actions right">
                    <p>Filtra per:</p>
                    <div className="select-group ">
                        <span className="pre-select">Periodo: </span>
                        <span className="select-arrow top"></span>

                        <input className="form-control month-selector" readOnly placeholder="Seleziona data" type="text" value={props.selectedRangeString ? props.selectedRangeString : ''} onClick={() => setShowcalendar(true)}></input>
                        <SimpleModal visible={showCalendar} toggleModal={() => setShowcalendar(!showCalendar)} modalTitle="Seleziona Data">
                            <SmallCalendar
                                className={`range-picker`}
                                view="year"
                                locale="it-IT"
                                onClickMonth={(event, value) => selectDateRange(event)}
                            />
                        </SimpleModal>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-3">
                    <div className="row mb-3 mb-xl-0 h-100">
                        <div className="col-12">
                            <div className="rounded-box patient-box" style={{ height: 'calc(100% - 3.5rem)', display: 'flex', flexFlow: 'column', overflowY: 'auto', cursor: 'default' }}>
                                <div className="header">
                                    <h2>
                                        {patient.firstname} {patient.lastname}
                                    </h2>
                                    <Comorbility object={patient} userToken={userToken} userComorbidities={patientComorbities} setNewPatientComorbities={(arg) => setPatientComorbities(arg)} />
                                </div>

                                <PatientInfo object={patient} comorbidities={patientComorbities} />

                                <h2>
                                    Caregiver
                                </h2>
                                <div className="caregiver-container">
                                    {patient.patient_caregivers.map(element => (
                                        <Caregiver caregiver={element.caregiver} />
                                    ))}
                                </div>
                                <div className="divider mt-auto">
                                    <h2>
                                        Note
                                    </h2>
                                    <button className="button outlined" onClick={(e) => openNote()}><FontAwesomeIcon icon="pen" /></button>
                                </div>
                                <p>
                                    {noteText}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-9">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="rounded-box flexed">
                                {getEvent() ? (
                                    <React.Fragment>
                                        <img className="icon-img big-icon" src={CalendarCheckB} alt="" />

                                        <div>
                                            <p className="colored-title">
                                                Prossimo appuntamento
                                            </p>
                                            <div className="d-flex justify-content-between">
                                                <p>{nextEvent.eventDays}</p>
                                                <div>
                                                    <img src={Clock} className="icon-img" icon="clock" alt="" /> {nextEvent.eventTiming}
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>

                                ) : (
                                    <React.Fragment>
                                        <FontAwesomeIcon className="big-icon" icon="calendar-times" />
                                        <div>
                                            <p className="colored-title">Prossimo appuntamento</p>
                                            <p className="my-2">Nessun appuntamento programmato</p>
                                            <button className="button secondary flexed small" onClick={() => setShowAppointmentModal(true)}><img className="icon-img mr-2" src={CalendarPlusW} /> Aggiungi appuntamento</button>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="rounded-box p-0">
                                <div className="patient-activities">
                                    <ActivityCompact
                                        type={DailyUse}
                                        title="Uso App"
                                        data={getPatientUsage() !== 'ND' ? getPatientUsage() >= 1 ? 'Giornaliero' : 'Non Giornaliero' : 'Non Giornaliero'}
                                    />
                                    <ActivityCompact
                                        type={Adeherence}
                                        title="Aderenza"
                                        data={<AdherenceRateComponent patientID={patient.id} drugID={''} userToken={userToken} />}
                                    />
                                    <ActivityCompact
                                        type={Mood}
                                        title="Umore"
                                        data={getPatientMood(patient.id)}
                                    />
                                    <ActivityCompact
                                        type={Step}
                                        title="Attività"
                                        data={getPatientSteps()}
                                    />
                                    <ActivityCompact
                                        type={Goal}
                                        title="Traguardi"
                                        data={getPatientGoals()}
                                    />
                                    <ActivityCompact
                                        type={Survey}
                                        title="PHQ9"
                                        data={useFilteredPHQ(patient.id, currentDate).length > 0 ? 'Compilato' : 'Non Compilato'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">

                            <div className="rounded-box chart-box position-relative">

                                <MultiChartElement
                                    Adherences={currentPatientAdherence}
                                    dailyMoods={currentPatientMoods}
                                    allAdherences={currentAdherence}
                                    filteredGoalsChartData={getPersonalGoals(completedGoals, patient.id)}
                                    allSurveysChartData={singleSurveyChartData}
                                    dayUsageChartData={userDailyUsage.filter(el => el.id === patient.id)}
                                />

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-12">
                    <div className="rounded-selector">
                        <span onClick={() => { setRoundedSelected('umore'); }} className={`rounded-selectable ${roundedSelected === 'umore' ? 'active' : ''}`}>umore</span>
                        <span onClick={() => { setRoundedSelected('terapia'); }} className={`rounded-selectable ${roundedSelected === 'terapia' ? 'active' : ''}`}>terapia</span>
                        <span onClick={() => { setRoundedSelected('attivita'); }} className={`rounded-selectable ${roundedSelected === 'attivita' ? 'active' : ''}`}>attività</span>
                        <span onClick={() => { setRoundedSelected('traguardi'); }} className={`rounded-selectable ${roundedSelected === 'traguardi' ? 'active' : ''}`}>traguardi</span>
                        <span onClick={() => { setRoundedSelected('utilizzo'); }} className={`rounded-selectable ${roundedSelected === 'utilizzo' ? 'active' : ''}`}>utilizzo app</span>
                        {/* <span onClick={() => { setRoundedSelected('info') }} className={`rounded-selectable ${roundedSelected === 'info' ? 'active' : ''}`}>info cliniche</span> */}
                    </div>
                    <div className="rounded-box absolute fixed-h" >
                        <div className="selectable-section" style={{ height: '100%' }}>
                            <div className="section-info" style={{ paddingRight: '1rem', width: '27rem', height: '100%', maxHeight: 'unset' }}>
                                <SectionInfo
                                    patient={patient}
                                    userToken={userToken}
                                    activities={activites}
                                    roundedSelected={roundedSelected}
                                    useClinicalEvents={useClinicalEvents}
                                    clinicalEventsCount={clinicalEventsCount}
                                    singleSurveyChartData={singleSurveyChartData}
                                    selectedActivity={selectedActivity}
                                    setSelectedActivity={(arg => setSelectedActivity(arg))}
                                    getPatientMood={() => getPatientMood(patient.id)}
                                    infoHandler={(arg) => infoHandler(arg)}
                                    setShowGoalModal={(arg) => setShowGoalModal(arg)}
                                    setSingleDrugAdherenceData={(arg1, arg2) => setSingleDrugAdherenceData(arg1, arg2, currentDate)}
                                    setRealDrugID={(arg1) => setRealSelectedDrugId(arg1)}
                                />
                            </div>
                            <div className="section-calendar">
                                <Suspense fallback={<div>Loading...</div>}>
                                    <ActivitiesCalendar
                                        realEvents={realEvents}
                                        eventsData={{ moods: currentPatientMoods, activities: activites, surveys: singleSurveyChartData, goals: getPersonalGoals(completedGoals, patient.id), adherence: calendarAdherence, appUse: userDailyUsage, clinicalEvents: clinicalEvents }}
                                        roundedSelected={roundedSelected}
                                        patientID={patient.id}
                                        eventClick={clickedEvent}
                                        selectedActivity={selectedActivity}
                                        selectedDrug={selectedDrugId}
                                    />
                                </Suspense>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment >
    );
};

export default Patient;

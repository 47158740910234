import React from 'react'
import { useSelector } from 'react-redux';
import PatientRow from '../../components/patient/patientRow';

export default function UnusedFiltered({ patients, patientClick }) {
    const unused = useSelector((state) => state.usage.unused);



    return (
        patients.filter(element => unused.includes(element.patient.id)).length > 0 ? patients.filter(element => unused.includes(element.patient.id)).filter(p => p.status === 'accepted').map(item => (
            <div key={item.patient.id} className="col-12 col-lg-6 col-xl-12">
                <PatientRow
                    patientClick={() => patientClick(item.patient.id)}
                    patient={item.patient}
                    appointment={null}
                />
            </div>
        ))
            : < h3 > Nessun paziente presente</h3 >
    )
}

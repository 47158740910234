import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setContext } from '../../store/contextStore';



const ContextMenu = props => {
    const [contextVisible, setContextVisible] = useState(true);
    const dispatch = useDispatch();
    const contextState = useSelector((state) => state.context);
    let content = null;

    useEffect(() => {
        if (props.contextName && props.contextName !== contextState.contextName) {
            setContextVisible(false);
        } else {
            setContextVisible(true);
        }

    }, [contextState])

    const toggleContext = () => {
        if (props.hideContext) {
            props.hideContext();
        }
        dispatch(setContext({
            xPos: null
        }));
    }


    if (contextState.context.show && contextVisible) {
        content = (
            <React.Fragment>
                <div className="context-menu-wrapper" onClick={toggleContext}>
                </div>
                <div
                    style={{
                        top: contextState.context.yPos,
                        left: contextState.context.xPos
                    }}
                    className={`context-menu ${props.type ?? ''}`}>
                    {props.type !== 'big' ?
                        <ul>
                            {props.children}
                        </ul>
                        :
                        props.children}
                </div>
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            {
                content
            }

        </React.Fragment>
    )
}

export default ContextMenu;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useSelector, useDispatch } from 'react-redux';
import { selectEvent } from '../../store/eventsStore';
import { sendMessage } from '../../store/messagesStore';
import moment from 'moment';
import 'moment/locale/it';
import CalendarToolbar from './CalendarToolbar';
import DayEvent from './dayEvents/DayEvent';
import DayEventWrapper from './dayEvents/DayEventWrapper';
import SimpleModal from '../../components/simpleModal';
import ContextMenu from '../../components/contextMenu/contextMenu';
import ContextAppointment from '../../components/contextMenu/contextAppointment';
import AddAppointments from '../../components/AddAppointments';
import AppointmentConfirmation from './AppointmentConfirmation';
import CalendarPlusW from '../../assets/images/fa-icons/calendar-plus-w.svg';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import isDev from '../../hooks/isDev';
import getCallUrl from '../../utils/callUrlUtil';
import { useAtomValue } from 'jotai';
import { acceptedEventsAtom } from '../../jotai/calendarEvents/taggedEventsAtom';

const localizer = momentLocalizer(moment);

let formats = {
    weekdayFormat: 'dddd',
};

const MyCalendar = props => {
    const dispatch = useDispatch();
    const [useRealEvents, setUseRealEvents] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showAppointmentModal, setShowAppointmentModal] = useState(false);
    const userToken = useSelector((state) => state.user.authToken);
    const actualEvents = useSelector((state) => state.events.calendarGrouped);
    const realEvents = useSelector((state) => state.events.dayEvents);
    const selectedEvent = useSelector((state) => state.events.selectedEvent);
    const [eventsToUse, setEventsToUse] = useState([]);
    const acceptedEvents = useAtomValue(acceptedEventsAtom);



    useEffect(() => {
        document.title = props.title || "Giada";

        window.scrollTo(0, 0);

    }, [props.title]);

    useEffect(() => {
        const newEventsToUse = [];
        if (actualEvents) {
            const newEvents = actualEvents.map(event => ({
                ...event,
                start: new Date(event.start),
                end: new Date(event.end),
            }));
            const newAcceptedEvents = acceptedEvents.map(event => ({
                ...event,
                eventsNumber: 1,
                start: new Date(event.date),
                end: new Date(event.date),
            }));
            newEventsToUse.push(...newEvents, ...newAcceptedEvents);
            console.log('newEventsToUse', newEventsToUse);
            setEventsToUse(newEventsToUse);
        }
    }, [actualEvents, acceptedEvents]);

    const modalHandler = () => {
        setShowModal(!showModal);
    };

    const confirmAppointmentHandler = (appointmentId) => {
        dispatch(selectEvent(appointmentId));

        modalHandler();
    };

    const setAppointmentHandler = (appointmentId, action) => {
        let callUrl = getCallUrl();

        const url = `${callUrl}/api/users/calendar-events/medic/${appointmentId}/confirm/${action}`;
        axios.get(url, {
            headers: { 'X-AUTH-TOKEN': userToken },
        })
            .then(response => {
                if (response.data.status === 'success') {
                    dispatch(sendMessage({
                        message: `Appuntamento ${action ? 'confermato' : 'rifiutato'} con successo!`,
                        positive: true,
                    }));
                } else {
                    dispatch(sendMessage({
                        message: `Impossibile ${action ? 'confermare' : 'rifiutare'} appuntamento: ` + response.data.message,
                        positive: false,
                    }));
                }
            })
            .catch(error => dispatch(sendMessage({
                message: `Impossibile ${action ? 'confermare' : 'rifiutare'} appuntamento errore: ` + error,
                positive: false,
            })));

    };
    return (
        <React.Fragment>
            <ContextMenu
                contextName='calendar'
            >
                <div className="appointments-wrapper">
                    {realEvents ? realEvents.map((element, i) => {
                        return (
                            <ContextAppointment
                                object={element}
                                confirmAppointment={() => confirmAppointmentHandler(element.id)}
                            />
                        );
                    }) : null}
                </div>

                <button className="button secondary flexed" onClick={() => setShowAppointmentModal(true)}><img className="icon-img mr-2" src={CalendarPlusW} /> Aggiungi Appuntamento</button>
            </ContextMenu>
            <SimpleModal visible={showModal} toggleModal={modalHandler} modalTitle={selectedEvent ? selectedEvent.confirmed_by_medic ? "Appuntamento" : "Richiesta di appuntamento" : null}>
                <AppointmentConfirmation object={selectedEvent} confirmAppointment={() => setAppointmentHandler(selectedEvent.id, true)} denyAppointment={() => setAppointmentHandler(selectedEvent.id, false)} />
            </SimpleModal>
            <SimpleModal visible={showAppointmentModal} toggleModal={() => setShowAppointmentModal(!showAppointmentModal)} modalTitle="Aggiungi appuntamento">
                <AddAppointments visible={showAppointmentModal} />
            </SimpleModal>
            <div className="page-header">
                <div className="breadcrumbs">
                    Calendario
                </div>
            </div>
            <Calendar
                localizer={localizer}
                components={{
                    event: DayEvent,
                    toolbar: CalendarToolbar,
                }}
                events={eventsToUse ?? []}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 700 }}
                views={['month', 'week', 'day']}
                messages={{
                    next: "Avanti",
                    previous: "Indietro",
                    today: "Oggi",
                    month: "Mese",
                    week: "Settimana",
                    day: "Giorno",
                    showMore: total => (
                        <div
                            style={{ cursor: 'pointer' }}
                            onMouseOver={e => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                        >{`+${total} more`}
                        </div>
                    ),
                }}
                popup={false}
                onSelectEvent={null}
                onSelectSlot={() => console.log(this.slotSelected)}
                formats={formats}

            />
            <button className="button secondary left mt-4" onClick={() => setShowAppointmentModal(true)}><img className="icon-img mr-2" src={CalendarPlusW} /> Aggiungi Appuntamento</button>
        </React.Fragment>
    );
};

export default MyCalendar;
import React from 'react';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class CalendarToolbar extends Toolbar {
    constructor(props) {
        super(props);
        this.state = {
            btn1: true,
            btn2: false,
            btn3: false,
            selectedView: 'month',
        };
    }

    componentDidMount() {
        const view = this.props.view;
    }



    clickedHandler(selectedView) {
        this.view(selectedView);
        if (selectedView === 'day') {
            this.setState({
                btn1: false,
                btn2: false,
                btn3: true,
                selectedView: selectedView,

            });
        } else if (selectedView === 'week') {
            this.setState({
                btn1: false,
                btn2: true,
                btn3: false,
                selectedView: selectedView,

            });
        } else {
            this.setState({
                btn1: true,
                btn2: false,
                btn3: false,
                selectedView: selectedView,

            });
        }

    }
    render() {
        return (
            <div className="rbc-toolbar">
                <div className="rbc-btn-group">
                    <button type="button" className={`${this.state.btn1 ? 'active' : ''}`} onClick={() => this.clickedHandler('month')}>Mese</button>
                    <button type="button" className={`${this.state.btn2 ? 'active' : ''}`} onClick={() => this.clickedHandler('week')}>Settimana</button>
                    <button type="button" className={`${this.state.btn3 ? 'active' : ''}`} onClick={() => this.clickedHandler('day')}>Giorno</button>
                </div>
                <div className="rbc-date-managment">
                    {this.state.selectedView === 'week' | this.state.selectedView === 'day' ? <p type="button" onClick={() => this.navigate('PREV')}><FontAwesomeIcon icon="chevron-left" /></p> : null}
                    <div className="rbc-toolbar-label">{this.props.label}</div>
                    {this.state.selectedView === 'week' | this.state.selectedView === 'day' ? <p type="button" onClick={() => this.navigate('NEXT')}><FontAwesomeIcon icon="chevron-right" /></p> : null}
                </div>
            </div>
        );
    }
}
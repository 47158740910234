import React, { useState, useEffect } from 'react'
import PatientInfo from './patientInfo';
import ActivityCompact from '../activities/activityCompact';
import Caregiver from '../Caregiver';
import { useSelector } from 'react-redux';

import DailyUse from '../../assets/images/icons/phone.png';
import Adeherence from '../../assets/images/icons/list.png';
import Mood from '../../assets/images/icons/dashboard.png';
import Step from '../../assets/images/icons/shoes.png';
import Goal from '../../assets/images/icons/cup.png';
import Survey from '../../assets/images/icons/paper.png';
import CalendarCheckB from '../../assets/images/fa-icons/calendar-check-b.svg';
import CalendarTimesB from '../../assets/images/fa-icons/calendar-times-b.svg';
import { getMonthString } from '../../utils/getMonthString';
import thousandsSeparator from '../../utils/thousandsSeparatorUtil';
import AdherenceRateComponent from '../../containers/AdherenceRateComponent';
import { useAtomValue } from 'jotai';
import { patientMoodsAtom } from '../../jotai/patientMoodsAtoms';
import { useFilteredPHQ } from '../../hooks/useFilteredPHQ';
import { getFirstDayDate } from '../../utils/daysUtils';

const PatientRow = props => {
    const userToken = useSelector((state) => state.user.authToken);
    const currentDate = useSelector((state) => state.user.startDate);
    const allMoods = useSelector((state) => state.patients.moods);
    const activites = useSelector((state) => state.activities.activites);
    const events = useSelector((state) => state.events.events);
    const userDailyUsage = useSelector((state) => state.usage.userDailyUsage);
    const [pMoods, setPMoods] = useState();
    const [pSpecialMoods, setSpecialPMoods] = useState();
    const patientMoods = useAtomValue(patientMoodsAtom);
    const startDate = useSelector((state) => state.user.startDate);
    const allGoals = useSelector((state) => state.goals.goals);



    useEffect(() => {
        setPMoods(getPatientMood(props.patient.id))
        setSpecialPMoods(getSpecialMood())
    }, [currentDate, props]);


    let appointment = (
        <div className="appointment">
            <img className="icon-img" src={CalendarTimesB}  alt=""/>
            <p className="appointment-label">
                Nessun appuntamento Programmato
            </p>
        </div>
    )

    function patientPhq(date) {
        const day = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const firstDate = new Date();
        const secondDate = new Date(date);

        const diffDays = Math.round(Math.abs((firstDate - secondDate) / day));



        return diffDays <= 28 ? 'Compilato' : 'Non compilato';

    }

    const getPatientUsage = () => {
        const usageIndex = userDailyUsage.findIndex(e => e.id === props.patient.id);

        if (usageIndex !== -1) {
            return userDailyUsage[usageIndex].usage;
        } else {
            return 'ND';
        }
    }

    const getPatientSteps = () => {
        const activitiesIndex = activites.findIndex(e => e.id === props.patient.id);
        let totalSteps = 0;


        if (activitiesIndex !== -1) {

            activites[activitiesIndex].activities.forEach(e => {
                totalSteps += e.total_steps;
            })

        }

        return activitiesIndex === -1 ? 'ND' : thousandsSeparator(totalSteps) + ' Passi'


    }

    const getPatientMood = (patientId) => {
        // foreach mood, get the aggregated mood of the patient with the same patientId

        const patientMoodsFiltered = patientMoods.filter(e => e.patientId === patientId);
        // console.log(patientMoodsFiltered);
        // console.log(patientId)

        if (!patientMoodsFiltered || !patientMoodsFiltered[0] || patientMoodsFiltered[0].moodHistory.length === 0) {
            return 'ND';
        } else {
            // round the aggregatedMood mood to the nearest integer
            const aggregatedMood = patientMoodsFiltered[0].aggregateMood;

            return Math.round(aggregatedMood) + '/5';
        }
}
    const getPatientGoals = () => {
        const startDay = getFirstDayDate(new Date(startDate).getMonth() + 1, new Date(startDate).getFullYear());
        const patientGoals = allGoals.filter((e) => e.id === props.patient.id);
        const goalsArray = [];

        console.log('patientGoals', patientGoals);

        patientGoals.forEach((element ) => {
            element.goalsArray.forEach((goal ) => {
                if (goalsArray.findIndex((e ) => e.id === goal.id) === -1) {
                    if(goal.completed){
                        if (goal.completed_at) {
                            console.log('goal', goal);
                            const completedAt = new Date(goal.completed_at);
                            if (completedAt.getMonth() + 1 >= startDay.getMonth() + 1 && completedAt.getFullYear() >= startDay.getFullYear()) {
                                const createdAt = new Date(goal.created_at);
                                if (createdAt.getMonth() + 1 <= startDay.getMonth() + 1 && createdAt.getFullYear() <= startDay.getFullYear()) {
                                    goalsArray.push(goal);
                                }
                            }
                        } else {
                            const createdAt = new Date(goal.created_at);
                            if (createdAt.getMonth() + 1 <= startDay.getMonth() + 1 && createdAt.getFullYear() <= startDay.getFullYear()) {
                                goalsArray.push(goal);
                            }
                        }
                    }

                }
            });
        });

        console.log('goalsArray', goalsArray);

        return goalsArray.length + props.patient.in_app_goals_completed;

    }

    const getEvent = () => {
        let eventSelected = null;
        if (events) {
            events.forEach(e => {
                if (!eventSelected && new Date(e.date) > new Date()) {
                    const userEventIndex = e.tagged_users.findIndex(element => element.id === props.patient.id);

                    if (userEventIndex !== -1) {
                        eventSelected = e;
                    }
                }
            })
        }

        if (eventSelected) {
            const eventDate = new Date(eventSelected.date);
            const eventDays = `${eventDate.getDate()} ${eventDate.getMonth()} ${eventDate.getFullYear()}`;
            const eventTime = `${eventDate.getHours()}:${eventDate.getMinutes()}`;
            return {
                day: `${eventDate.getDate()}`,
                month: eventDate.getMonth(),
                year: `${eventDate.getFullYear()}`,
                date: eventDays,
                time: eventTime,
            }
        } else {
            return false;
        }
    }


    if (getEvent()) {
        const eventObj = getEvent();
        appointment = (
            <div className="appointment">
                <img className="icon-img" src={CalendarCheckB} />

                <p className="appointment-label">
                    Prossimo appuntamento
                </p>
                <p>
                    {eventObj.day} {getMonthString(eventObj.month)} {eventObj.year} - Ore {eventObj.time}
                </p>
            </div>
        )
    }

    //calculate patient mood jump

    //allMoods find the object that has patientID === props.patient.id
    //gets the inner moodsArray
    // order by object.date
    //get most recent mood object,
    //check if object.rating has a difference of +-2 from the prev object.rating
    function getSpecialMood() {
        let toReturn = undefined;
        const patientMoods = allMoods.find(obj => obj.patientID === props.patient.id);

        if (patientMoods) {
            const moodArray = [...patientMoods.moodsArray];

            if (moodArray && moodArray.length > 0) {

                moodArray.sort(function (a, b) {

                    return new Date(b.date) - new Date(a.date);
                });
                if(moodArray[0] && moodArray[1]){
                    const diffObj = differenceUtil(moodArray[0].rating, moodArray[1].rating);
                    if (diffObj !== undefined) {
                        if (diffObj.difference >= 2) {
                            if (diffObj.variation === 'positive') {
                                toReturn = 'positive';
                            } else {
                                toReturn = 'negative';
                            }
                        }
                    }
                }
            }
        }

        return toReturn;
    }

    function getMood(){
        const mood = patientMoods.find(e => e.patientId === props.patient.id);
        if(mood && mood.moodHistory.length > 0){
            // round to 1 decimal
            return Math.round(mood.aggregateMood) +' / 5';
        }else{
            return 'ND';
        }

    }


    return (
        <div className="patient-row" onClick={props.patientClick}>
            <PatientInfo object={props.patient} />

            <div className="caregivers">
                {props.patient.patient_caregivers ? props.patient.patient_caregivers.map((item, index) => (
                    <Caregiver key={index} caregiver={item.caregiver} />
                )) : null}
            </div>

            {appointment}
            <div className="activities">
                <ActivityCompact
                    type={DailyUse}
                    title="Uso App"
                    data={getPatientUsage() !== 'ND' ? getPatientUsage() >= 1 ? 'Giornaliero' : 'Non Giornaliero' : 'ND'}
                />
                <ActivityCompact
                    type={Adeherence}
                    title="Aderenza"
                    data={<AdherenceRateComponent patientID={props.patient.id} drugID={''} userToken={userToken} />}
                />
                <ActivityCompact
                    type={Mood}
                    title="Umore"
                    data={getMood()}
                    specialData={pSpecialMoods}
                />
                <ActivityCompact
                    type={Step}
                    title="Attività"
                    data={getPatientSteps()}
                />
                <ActivityCompact
                    type={Goal}
                    title="Traguardi"
                    data={getPatientGoals()}
                />
                <ActivityCompact
                    type={Survey}
                    title="PHQ9"
                    data={useFilteredPHQ(props.patient.id, currentDate).length > 0 ? 'Compilato' : 'Non Compilato'}
                />
            </div>
        </div>
    )
}

export default PatientRow;

//check the difference between moods
// if mood a is higher than mood b, the mood variation is positive
function differenceUtil(a, b) {

    if (isNaN(a) || isNaN(b)) {
        return undefined;
    }

    if (a > b) {
        return { variation: 'positive', difference: a - b }
    } else {
        return { variation: 'negative', difference: b - a }
    }

}

// return alla days in the month until today
// function getDays() {
//     let date = new Date();
//     let initialMonth = new Date(date.getFullYear(), date.getMonth(), 1);

//     console.log((date-initialMonth) / (1000 * 3600 * 24) );
   
// }
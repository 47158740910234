import React, { useEffect, useState } from 'react';
import getChartData from '../../hooks/getChartData';

import LineChart from './LineChart';
import AbsoluteChart from './AbsoluteChart';
import { useSelector } from 'react-redux';
import FixedAbsoluteChart from './FixedAbsoluteChart';


const optionsArray = [
    {
        id: 1,
        label: 'Utilizzo App',
    },
    {
        id: 2,
        label: 'Terapia',
    },
    {
        id: 3,
        label: 'Umore',
    },
    {
        id: 4,
        label: 'Traguardi',
    },
    {
        id: 6,
        label: 'Attività',
    },
];


const MultiChartElementSelectable = (props) => {
    const startDate = useSelector((state) => state.user.startDate);
    const specialChartData = getChartData(props.Adherences, null, null, true);
    const [moodChartData, setMoodChartData] = useState(getChartData(props.dailyMoods, startDate, null, 'moods'));
    const [adherenceChartData, setAdherenceChartData] = useState(getChartData(props.allAdherences, startDate, null, 'adherence'));
    const [goalsChartData, setGoalsChartData] = useState(getChartData(props.filteredGoalsChartData, startDate, null, 'goal'));
    const [surveyChartData, setSurveyChartData] = useState(getChartData(props.allSurveysChartData, startDate, null, 'survey'));
    const [usageChartData, setUsageChartData] = useState(getChartData(props.dayUsageChartData, startDate, null, 'usage'));
    const [activityChartData, setActivityChartData] = useState(getChartData(props.activity, startDate, null, props.steps ? 'steps' : 'kms'));
    const [adherenceId, setAdherenceId] = useState(Math.random() * Math.random());
    const [chartSelection, setChartSelection] = useState({
        1: false,
        2: false,
        3: true,
        4: false,
        5: false,
        6: false,
    });

    // console.log('props.filteredGoalsChartData', props.filteredGoalsChartData);

    useEffect(() => setMoodChartData(getChartData(props.dailyMoods, startDate, null, 'moods')), [startDate, props.dailyMoods]);

    useEffect(() => setActivityChartData(getChartData(props.activity, startDate, null, props.steps ? 'steps' : 'kms')), [startDate, props.activity, props.steps]);

    useEffect(() => setAdherenceChartData(getChartData(props.allAdherences, startDate, null, 'adherence')), [startDate, props.allAdherences]);

    useEffect(() => setGoalsChartData(getChartData(props.filteredGoalsChartData, startDate, null, 'goal')), [startDate, props.filteredGoalsChartData]);

    useEffect(() => setSurveyChartData(getChartData(props.allSurveysChartData, startDate, null, 'survey')), [startDate, props.allSurveysChartData]);

    useEffect(() => setUsageChartData(getChartData(props.dayUsageChartData, startDate, null, 'usage')), [startDate, props.dayUsageChartData]);

    useEffect(() => {
        console.log('props.allAdherences', props.allAdherences);
    }, [props.allAdherences]);


    const [confrontChart, setConfrontChart] = useState('-');
    const [chartSelected, setChartSelected] = useState(null);
    const ids = [3, 2, 6, 4, 1];
    const names = ['umore', 'terapia', 'attività', 'traguardi', 'utilizzo app'];


    useEffect(() => {
        const newChartSelection = { ...props.selectedCharts };

        if (!props.moodInfoSelected && newChartSelection[3]) {
            newChartSelection[3] = false;
            newChartSelection[5] = true;
        }

        setChartSelection(newChartSelection);
    }, [props.selectedCharts, props.moodInfoSelected]);


    const selectConfrontCharthandler = (e) => {
        const selectedChart = e.target.value;



        setConfrontChart(selectedChart);


        const chartId = ids[names.indexOf(selectedChart.toLowerCase())];

        setChartSelected(chartId);
    };

    return (
        <div className="rounded-box chart-box position-relative">
            {!props.selectedCharts[6] ?
                <div className="chart-confront">
                    <p>Confronta con:</p>
                    <select
                        value={confrontChart}
                        onChange={selectConfrontCharthandler}
                    >
                        <option>-</option>
                        {optionsArray.filter(el => !chartSelection[el.id]).map(value => <option key={"option-" + value.id}>{value.label}</option>)}
                    </select>
                </div> : null}
            <LineChart data={[{
                "id": "Uso App",
                "color": "transparent",
                "data": adherenceChartData
            }]} setID={0} passedID={chartSelection} specialID={chartSelected} minValue={0}
            />
            {/* Chart setter  needed for multi select tooltip*/}
            <AbsoluteChart data={[{
                "id": "Uso App",
                "color": "rgb(0, 0, 0)",
                "data": specialChartData
            }]} setID={1}
                passedID={chartSelection}
                specialID={chartSelected}
                special={true}
                surveyToggle={!props.moodInfoSelected}
                chartsData={[{ id: 1, data: usageChartData, name: 'Uso App' }, { id: 2, data: adherenceChartData, name: 'Aderenza' }, { id: 3, data: moodChartData, name: 'Umore' }, { id: 4, data: goalsChartData, name: 'Traguardi' }, { id: 5, data: surveyChartData, name: 'Questionari' }, { id: 6, data: activityChartData, name: 'Attività' }]} />
            {/* Chart setter */}
            {chartSelection[1] || chartSelected === 1 ?
                <AbsoluteChart data={[{
                    "id": "Uso App",
                    "color": "rgb(0, 0, 0)",
                    "data": usageChartData
                }]} setID={1} passedID={chartSelection} specialID={chartSelected}
                />
                : null}
            {chartSelection[2] || chartSelected === 2 ?
                <AbsoluteChart data={[{
                    "id": "Aderenza",
                    "color": "rgb(206, 75, 75)",
                    "data": adherenceChartData
                }]} setID={2} passedID={chartSelection} specialID={chartSelected}
                />
                : null}
            {chartSelection[3] || chartSelected === 3 ?
                <AbsoluteChart data={[{
                    "id": 'Umore',
                    "color": "hsl(158, 56%, 43%)",
                    "data": moodChartData
                }]} setID={3} passedID={chartSelection} specialID={chartSelected}
                />
                : null}
            {chartSelection[4] || chartSelected === 4 ?
                <AbsoluteChart data={[{
                    "id": "Traguardi",
                    "color": "hsl(203, 7%, 42%)",
                    "data": goalsChartData
                }]} setID={4} passedID={chartSelection} specialID={chartSelected}
                />
                : null}
            {chartSelection[5] || chartSelected === 5 ? <FixedAbsoluteChart data={[{
                "id": "PHQ9",
                "color": "hsl(233, 46%, 55%)",
                "data": surveyChartData
            }]} setID={5} passedID={chartSelection} specialID={5} active={!props.moodInfoSelected}
            /> : null}
            {chartSelection[6] || chartSelected === 6 ?
                <AbsoluteChart data={[{
                    "id": "Attività",
                    "color": "hsl(27, 74%, 55%)",
                    "data": activityChartData
                }]} setID={6} passedID={chartSelection} specialID={chartSelected}
                />
                : null}
        </div>
    );
};

export default MultiChartElementSelectable;
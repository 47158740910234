import React, { useState } from 'react';
import SimpleModal from "./simpleModal";

const NewsCard = (props) =>{
    const [detailNewsOpen, setDetailNewsOpen] = useState(false)
    const articleDate = new Date(props.date);
    const imgBaseUrl = 'https://cms.giada-accantoate.it';
    // const imgBaseUrl = 'http://never-alone.melazeta.com/';

    function clickDetail(_props){
        console.info("clickDetail",_props)
        setDetailNewsOpen(true)
    }

    function closeDetail(){
        console.info("closeDetail")
        setDetailNewsOpen(false)
    }


    return(
        <React.Fragment>
        <SimpleModal visible={detailNewsOpen} toggleModal={closeDetail} img={imgBaseUrl + props.img}>
        <div className={`news-card`}>

            <div className="card-content">
                <div className="title">{props.title}</div>
                {/*<div className="tags">{props.tags}</div>*/}
                <div className="date mb-3">{articleDate.getDate()}/{articleDate.getMonth() + 1}/{articleDate.getFullYear()}</div>
                <div className="article inner mb-5" dangerouslySetInnerHTML={{ __html: props.content}} ></div>
                <div className="button-wrapper">
                    <button className="button" onClick={closeDetail}>Chiudi</button>
                </div>
            </div>
        </div>
        </SimpleModal>
            <div className={`card news-card  ${props.type ?? ''}`} onClick={(e) => clickDetail(props)}>
            <div className="card-header">
                <img src={imgBaseUrl + props.img} alt={props.alt} />
            </div>
            <div className="card-content">
                <div className="title">{props.title}</div>
                <div className="tags">{props.tags}</div>
                <div className="date">{articleDate.getDate()}/{articleDate.getMonth() + 1}/{articleDate.getFullYear()}</div>
                <div className="excerpt">{props.excerpt}</div>
                <div className="button-wrapper">
                    <button className="button" onClick={(e) => clickDetail(props)}>Continua a leggere</button>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

export default NewsCard

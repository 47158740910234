import { atom } from "jotai";

export type PHQAnswerType = {
  question: string;
  answer: number;
};

type PHQType = {
  patientID: string,
  surveyID: string,
  date: string,
  score: number,
  answers: PHQAnswerType[];
};

export const patientsCompiledPHQAtom = atom<PHQType[]>([]);

export const setUniquePatientPHQAtom = atom(
  null,
  (get, set, payload: PHQType) => {
    const patientsPHQ = get(patientsCompiledPHQAtom);
    // check if surveyID already exists if it doesn't add the new survey
    if (!patientsPHQ.some((phq) => phq.surveyID === payload.surveyID)) {
      set(patientsCompiledPHQAtom, [...patientsPHQ, payload]);
    }

  }
);

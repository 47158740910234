import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { clearMessage } from '../store/messagesStore';

export default function SystemMessage() {
  const dispatch = useDispatch();
  const systemMessage = useSelector((state) => state.message.message);
  const systemMessageStatus = useSelector((state) => state.message.messagePositive);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (systemMessage?.length > 0) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [systemMessage])

  function transitionEndHandler() {
    if (visible) {
      setTimeout(() => {
        setVisible(false);
      }, 2000)
    }else{
      dispatch(clearMessage());
    }
  }


  return (
    <div
      className={`system-message ${visible ? 'visible' : 'hidden'} ${systemMessageStatus ? 'positive' : 'negative'}`}
      onTransitionEnd={transitionEndHandler}
    >
      <p>{systemMessage}</p>
    </div>
  )
}

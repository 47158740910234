import { configureStore } from '@reduxjs/toolkit';
import patientsReducer from './patientsStore';
import newsReducer from './newsStore';
import userReducer from './userStore';
import surveyReducer from './surveysStore';
import goalReducer from './goalsStore';
import calendarEventsReducer from './calendarEventsStore';
import eventsReducer from './eventsStore';
import contextReducer from './contextStore';
import messageReducer from './messagesStore';
import appUsageReducer from './appUsageStore';
import activitiesReducer from './activitiesStore';
import clinicalEventReducer from './clinicalEventStore';
import loadingReducer from './loadingStore';

export const store = configureStore({
  reducer: {
    news: newsReducer,
    user: userReducer,
    goals: goalReducer,
    events: eventsReducer,
    surveys: surveyReducer,
    usage: appUsageReducer,
    context: contextReducer,
    message: messageReducer,
    patients: patientsReducer,
    activities: activitiesReducer,
    clinicalEvents: clinicalEventReducer,
    calendarEvents: calendarEventsReducer,
    loading: loadingReducer,
  },
});

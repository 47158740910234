import { useState } from "react";

const ActivityCompact = props => {

    return (
        <div className={`activity-compact ${props.special ?? ''} ${props.selectable ? props.selected ? 'selected' : 'unselected' : ''}`} style={props.style ?? {}} >
            <div className="activity-icon">
                <img src={props.type} />
            </div>
            <div className="activity-info-wrapper" >
                <p className="activity-title">{props.title}</p>
                <p className={`activity-info ${props.specialData ?? ''}`}>{props.data}</p>
            </div>
        </div>
    )
}

export default ActivityCompact;
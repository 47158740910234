import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    clinicalEvents: [],
    patientClinicalEvents: [],
    currentEvents: [],
};

export const clinicalEventsSlice = createSlice({
    name: 'clinicalEvents',
    initialState,
    reducers: {
        loadClinicalEvents: (state, action) => {
            const currentEvents = [...state.currentEvents];
            const clinicalEvents =[...state.clinicalEvents];
            const dates = getDaysArray(action.payload.startDate, action.payload.endDate);

            if(action.payload.events && action.payload.events.length > 0){
                clinicalEvents.push({
                    id: action.payload.id,
                    events: action.payload.events,
                })

                dates.forEach(day => {
                    action.payload.events.forEach(clinicalEvent => {
                        const eventDate = new Date(clinicalEvent.date);
                        if (`${day.getDate()}-${day.getMonth()}-${day.getFullYear()}` === `${eventDate.getDate()}-${eventDate.getMonth()}-${eventDate.getFullYear()}`){
                            currentEvents.push(clinicalEvent);
                        }
                    });
                });

            }

            state.clinicalEvents = clinicalEvents;
            state.currentEvents = currentEvents;
        },
        loadPatientClinicalEvent: (state, action) => {
            const clinicalEvents = state.clinicalEvents;
            const patientEvents = [];

            if(clinicalEvents.length > 0){
                const eventsIndex = clinicalEvents.findIndex(e => e.id === action.payload);

                if(eventsIndex !== -1){
                    clinicalEvents[eventsIndex].events.forEach(element => {
                        patientEvents.push(element);
                    })
                }
            }


            state.patientClinicalEvents = patientEvents;
        },
        resetCurrentEvents: (state) => {
            state.currentEvents = [];
        }
    },

})

export const { loadClinicalEvents, loadPatientClinicalEvent, resetCurrentEvents } = clinicalEventsSlice.actions

export default clinicalEventsSlice.reducer;

const getDaysArray = (start, end) => {
    for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
    }
    return arr;
}
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
  };


  export const newsSlice = createSlice({
    name:'loading',
    initialState,
    reducers:{
        startLoading:(state) => {
          state.loading = true;
        },
        stopLoading:(state) => {
          state.loading = false;
        }
    }
})


export const { startLoading, stopLoading } = newsSlice.actions;

export default newsSlice.reducer;
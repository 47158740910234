export default function getDataDifference(date:Date){
    const msPerMinute = 1000 * 60;
    //@ts-ignore
    const minutes = Math.floor((new Date() - new Date(date)) / msPerMinute);

    let toReturn = minutes + ' min fa';

    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if(days >= 1){
        if(days === 1){
            toReturn = days + ' giorno fa';

        }else{
            toReturn = days + ' giorni fa';
        }
    }else if(hours >= 1){
        if(hours === 1){
            toReturn = hours + ' ora fa';
        }else{
            toReturn = hours + ' ore fa';
        }
    }

    return toReturn;
}
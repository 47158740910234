import React from 'react';
import { useSelector } from 'react-redux';
import PatientRow from '../../components/patient/patientRow';
import { useFilteredPHQ } from '../../hooks/useFilteredPHQ';

export default function SurveysFiltered({ patients, patientClick }) {
    const startDate = useSelector((state) => state.user.startDate);
    const totalSurveys = useFilteredPHQ(patients.map(el => el.patient.id), startDate);


    return (
        patients.filter(element => totalSurveys.map(el => el.patientID).includes(element.patient.id)).map(item => (
            <div key={item.patient.id} className="col-12 col-lg-6 col-xl-12">
                <PatientRow
                    patientClick={() => patientClick(item.patient.id)}
                    patient={item.patient}
                    appointment={null}
                />
            </div>
        ))

    );
}

import { atom } from "jotai";

type pathologiesType = {
  id: string;
  name: string;
};

type taggedEvent = {
  eventId: string;
  date: Date;
  title: string;
  content: string;
  name: string;
  lastName: string;
  pathologies: pathologiesType[];
};

export const taggedEventsAtom = atom<taggedEvent[]>([]);
export const acceptedEventsAtom = atom<taggedEvent[]>([]);

export const acceptTaggedEventAtom = atom(
  null,
  (get, set, eventId: string) => {
    const taggedEvents = get(taggedEventsAtom);
    const acceptedEvents = get(acceptedEventsAtom);
    const eventToAcceptIndex = taggedEvents.findIndex(event => event.eventId === eventId);
    console.log("eventToAcceptIndex", eventToAcceptIndex);
    if (eventToAcceptIndex !== -1) {
      console.log("accetedEventsAtom", [...acceptedEvents, taggedEvents[eventToAcceptIndex]]);
      set(acceptedEventsAtom, [...acceptedEvents, taggedEvents[eventToAcceptIndex]]);
    }
  }
);

// removed taggedEvent by eventId
export const removeTaggedEventAtom = atom(
  null,
  (get, set, eventId: string) => {
    const taggedEvents = get(taggedEventsAtom);
    const taggedEventIndex = taggedEvents.findIndex((event) => event.eventId === eventId);
    if (taggedEventIndex !== -1) {
      taggedEvents.splice(taggedEventIndex, 1);
      set(taggedEventsAtom, [...taggedEvents]);
    }
  }
);
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MultiChartElement from '../../components/charts/MultiChartElement';
import MultiChartElementSelectable from '../../components/charts/MultiChartElementSelectable';
import SimpleModal from '../../components/simpleModal';
import SmallCalendar from 'react-calendar';
import PatientsBarBoxComponent from '../../components/PatientsBarBoxComponent';
import PatientsConnectionModal from '../../components/modals/PatientsConnectionModal';
import PageFilters from '../../components/PageFilters';
import AnalysisInfo from './AnalysisInfo';
import { getArrayMedian } from '../../store/patientsStore';
import { getFilteredGoals } from '../../utils/getPatientGoals';
import { useFilteredPHQ } from '../../hooks/useFilteredPHQ';
import { useFilteredAdherence } from '../../hooks/useFilteredAhderence';
import { useCombinedAdherence } from '../../hooks/useCombinedAdherence';


const Analysis = (props) => {
    const [showConnectionModal, setShowConnectionModal] = useState(false);
    const [roundedSelected, setRoundedSelected] = useState('umore');
    const [showCalendar, setShowcalendar] = useState(false);
    const [selectedCharts, setSelectedCharts] = useState({
        1: false,
        2: false,
        3: true,
        4: false,
        5: false,
        6: false,
    });
    const [dailyMoods, setDailyMoods] = useState([]);
    const moods = useSelector((state) => state.patients.moods);
    const allAdherences = useSelector((state) => state.patients.allAdherences);
    const dayUsageChartData = useSelector((state) => state.usage.dayUsageChartData);
    const activitiesForChart = useSelector((state) => state.activities.dayStepsChartData);
    const filteredAdherence = useFilteredAdherence();
    const [adherenceToUse, setAdherenceToUse] = useState(allAdherences);
    const [moodInfoSelected, setMoodInfoSelected] = useState(true);
    const [stepsSelected, setStepsSelected] = useState(true);
    const filteredPatients = useSelector((state) => state.user.filteredPatients);
    const startDate = useSelector((state) => state.user.startDate);
    const completedGoals = useSelector((state) => state.goals.completedGoals);

    //log adherenceToUse in a useEffect

    useEffect(() => {
        console.log('dayUsageChartData', dayUsageChartData);
    }, [dayUsageChartData]);


    useEffect(() => {
        document.title = props.title || "Giada";

        window.scrollTo(0, 0);

    }, [props.title]);


    useEffect(() => {
        const newMoodsArray = [];

        // console.log('moods', moods);


        moods.forEach(element => {
            element.moodsArray.forEach(mood => {
                const moodDate = new Date(mood.date);
                const convertedDate = `${moodDate.getDate()}-${moodDate.getMonth()}-${moodDate.getFullYear()}`;
                const moodIndex = newMoodsArray.findIndex(e => e.date === convertedDate);
                if (moodIndex !== -1) {
                    const currentMoods = newMoodsArray[moodIndex].ratings;

                    currentMoods.push(mood.rating);

                    newMoodsArray[moodIndex].ratings = currentMoods;
                    newMoodsArray[moodIndex].medianMood = getArrayMedian(currentMoods);

                } else {
                    newMoodsArray.push({
                        ratings: [mood.rating],
                        date: convertedDate,
                        averageValue: mood.rating,
                    });
                }

            });
        });
        // console.log('newMoodsArray', newMoodsArray);

        setDailyMoods(newMoodsArray);


    }, [moods, startDate]);



    // useEffect(() => {
    //     console.log('allAdherences', allAdherences)
    // }, [allAdherences])

    /* toggle true to selectedCharts[id], false to the others*/
    const handleChartsSelection = (id) => {

        const charts = { ...selectedCharts };
        const chartKeys = Object.keys(charts);

        chartKeys.forEach(k => {
            if (k === `${id}`) {
                charts[k] = true;
            } else {
                charts[k] = false;
            }
        });

        setSelectedCharts(charts);
    };

    const handleRoundSelection = (name) => {
        const ids = [3, 2, 6, 4, 1, 5];
        const names = ['umore', 'terapia', 'attività', 'traguardi', 'utilizzo app', 'info cliniche'];
        const chartId = ids[names.indexOf(name)];

        setRoundedSelected(name);
        /*check if selected chart is not visible already*/
        if (!selectedCharts[chartId]) {
            /* hides all other charts and shows selected one */

            handleChartsSelection(chartId);

        }
    };

    const pathologyFilterHandler = (value) => {
        props.pathologyFilter(value);
    };

    const selectDateRange = (event) => {
        const date = event;
        props.updateRange(date);
        setShowcalendar(false);
    };


    return (
        <React.Fragment>
            <div className="page-header">
                <div className="breadcrumbs">
                    Analisi
                </div>
                <div className="page-actions right">
                    <p>Filtra per:</p>
                    <div className="select-group ">
                        <span className="pre-select">Periodo: </span>
                        <span className="select-arrow top"></span>

                        <input className="form-control month-selector" readOnly placeholder="Seleziona data" type="text" value={props.selectedRangeString ? props.selectedRangeString : ''} onClick={() => setShowcalendar(true)}></input>
                        <SimpleModal visible={showCalendar} toggleModal={() => setShowcalendar(!showCalendar)} modalTitle="Seleziona Data">
                            <SmallCalendar
                                className={`range-picker`}
                                view="year"
                                locale="it-IT"
                                onClickMonth={(event, value) => selectDateRange(event)}
                            />
                        </SimpleModal>
                    </div>
                    <PageFilters
                        currentPathology={props.currentPathology}
                        pathologyFilterHandler={(arg) => pathologyFilterHandler(arg)}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-xl-4">
                    <PatientsBarBoxComponent
                        buttonClick={() => setShowConnectionModal(true)}
                    />
                </div>
                <div className="col-xl-8">
                    <div className="row">
                        <div className="col-12">
                            <div className="rounded-box chart-box position-relative exportable">
                                <MultiChartElement
                                    Adherences={allAdherences}
                                    dailyMoods={dailyMoods}
                                    allAdherences={allAdherences}
                                    filteredGoalsChartData={getFilteredGoals(completedGoals, filteredPatients && filteredPatients.length > 0 ? filteredPatients.map(el => el.patient.id) : [])}
                                    allSurveysChartData={useFilteredPHQ(filteredPatients.map(el => el.patient.id), startDate)}
                                    dayUsageChartData={dayUsageChartData}
                                    exportable={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="rounded-selector">
                        <div onClick={() => { handleRoundSelection('umore'); }} className={`rounded-selectable ${roundedSelected === 'umore' ? 'active' : ''}`}>umore</div>
                        <div onClick={() => { handleRoundSelection('terapia'); }} className={`rounded-selectable ${roundedSelected === 'terapia' ? 'active' : ''}`}>terapia</div>
                        <div onClick={() => { handleRoundSelection('attività'); }} className={`rounded-selectable ${roundedSelected === 'attività' ? 'active' : ''}`}>attività</div>
                        <div onClick={() => { handleRoundSelection('traguardi'); }} className={`rounded-selectable ${roundedSelected === 'traguardi' ? 'active' : ''}`}>traguardi</div>
                        <div onClick={() => { handleRoundSelection('utilizzo app'); }} className={`rounded-selectable ${roundedSelected === 'utilizzo app' ? 'active' : ''}`}>utilizzo app</div>
                        {/* <span onClick={() => { handleRoundSelection('info cliniche') }} className={`rounded-selectable ${roundedSelected === 'info cliniche' ? 'active' : ''}`}>info cliniche</span> */}
                    </div>
                    <div className="rounded-box absolute">

                        <div className="selectable-section distribution">
                            <div className="section-info">
                                <AnalysisInfo roundedSelected={roundedSelected} setAdherenceToUse={setAdherenceToUse} setSurveyStats={(arg) => setMoodInfoSelected(arg)} setUseSteps={setStepsSelected} />
                            </div>
                            <div className="section-calendar">
                                <div className="rounded-box chart-box comparative-chart">
                                    <h6 className='mt-2 ml-2'>Andamento</h6>
                                    <MultiChartElementSelectable
                                        Adherences={adherenceToUse}
                                        dailyMoods={dailyMoods}
                                        allAdherences={adherenceToUse}
                                        filteredGoalsChartData={getFilteredGoals(completedGoals, filteredPatients && filteredPatients.length > 0 ? filteredPatients.map(el => el.patient.id) : [])}
                                        allSurveysChartData={useFilteredPHQ(filteredPatients.map(el => el.patient.id), startDate)}
                                        dayUsageChartData={dayUsageChartData}
                                        selectedCharts={selectedCharts}
                                        activity={activitiesForChart}
                                        multichartActive={true}
                                        steps={stepsSelected}
                                        moodInfoSelected={moodInfoSelected}
                                    />
                                </div>
                            </div>
                            {/* <div className="section-calendar last">
                                <div className="rounded-box chart-box comparative-chart">
                                    <h6 className='mt-2 ml-2'>Distribuzione</h6>
                                    <MultiChartDistribution
                                        Adherences={adherenceToUse}
                                        dailyMoods={dailyMoods}
                                        allAdherences={adherenceToUse}
                                        filteredGoalsChartData={filteredGoalsChartData}
                                        allSurveysChartData={allSurveysChartData}
                                        selectedCharts={selectedCharts}
                                        activity={activitiesForChart}
                                        multichartActive={true}

                                    />
                                </div>
                            </div> */}
                        </div>

                    </div>
                </div>
            </div>
            <PatientsConnectionModal
                visible={showConnectionModal}
                toggleModal={() => setShowConnectionModal(!showConnectionModal)}
            />
        </React.Fragment>
    );
};

export default Analysis;
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, Link, useHistory } from "react-router-dom";
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import { setUserStartDate, userLogin } from './store/userStore';
import { loadNews } from './store/newsStore';
import { addGoals, resetGoals } from './store/goalsStore';
import { loadEvents } from './store/eventsStore';
import { addSurveys } from './store/surveysStore';
import { sendMessage } from './store/messagesStore';
import { loadActivites, loadPastSteps, resetActivities } from './store/activitiesStore';
import { setUsage, resetUsage, setUnused, resetUnused } from './store/appUsageStore';
import { loadClinicalEvents, resetCurrentEvents } from './store/clinicalEventStore';
import { pathologyFilter } from './store/userStore';
import { saveMoods, saveAdherences, saveTotalAdherence, savePatientsAdherence, resetData, savePatientsTotals, resetAdherenceTarget } from './store/patientsStore';
import * as exampleData from "./containers/exampleData/exampleData";
import Dashboard from './containers/dashboard/Dashboard';
import Login from './containers/login/login';
import News from './containers/news/News';
import Patients from './containers/patients/Patients';
import Patient from './containers/patient/Patient';
import Calendar from './containers/calendar/Calendar';
import Analysis from './containers/anlysis/Analysis';
import Menu from './components/menu';
import HeaderSearch from './components/headerSearch';
import 'bootstrap';
import "./components/ListFontAwesomeIcons";
import SystemMessage from './components/SystemMessage';
import isDev from './hooks/isDev';
import { getMonthString } from './utils/getMonthString';
import getCallUrl from './utils/callUrlUtil';
import { startLoading, stopLoading } from './store/loadingStore';
import AbsoluteLoader from './components/AbsoluteLoader';
import { useAtom, useSetAtom } from 'jotai';
import { addPatientMoodAtom } from './jotai/patientMoodsAtoms';
import { taggedEventsAtom } from './jotai/calendarEvents/taggedEventsAtom';
import { setUniquePatientPHQAtom } from './jotai/patientPHQAtoms';
import { addPatientAdherence, resetAherences } from './jotai/patientsAdherenceAtom';
import FilteredPatients from './containers/patients/FilteredPatients';
import { navigationTargetAtom } from './jotai/navigationTargetAtom';
import { setUniqueGoalsAtom } from './jotai/calendarWeeklyGoalsAtom';


/* prod
  https://cms.giada-accantoate.it/
  stage
  http://never-alone.melazeta.com/
  */
function App() {
  const [navigationTarget, setNavigationTarget] = useAtom(navigationTargetAtom);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0));
  const [selectedDateString, setSelectedDateString] = useState('');
  const [currentPathologyFilter, setCurrentPathologyFilter] = useState('Tutte');
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const [firstLoadCollapse, setFirstLoadCollapse] = useState(false);
  const [logged, setLogged] = useState(false);
  const [patientRedirect, setPatientRedirect] = useState(false);
  const [eventsLoaded, setEventsLoaded] = useState(0);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.user);
  const userInfoLoading = useSelector((state) => state.user.loading);
  const userToken = useSelector((state) => state.user.authToken);
  const filteredPatients = useSelector((state) => state.user.filteredPatients);
  const patientsPathologies = useSelector((state) => state.user.patientsPathologies);
  const callUrl = getCallUrl();
  const setPatientMoods = useSetAtom(addPatientMoodAtom);
  const setPatientTaggedEvents = useSetAtom(taggedEventsAtom);
  const setUniquePHQ = useSetAtom(setUniquePatientPHQAtom);
  const setPatientAdherence = useSetAtom(addPatientAdherence);
  const resetPatientAdherence = useSetAtom(resetAherences);
  const setUniqueGoals = useSetAtom(setUniqueGoalsAtom);


  const menuToggleHandler = () => {
    setMenuCollapsed(!menuCollapsed);
  };

  const toggleLogged = () => {
    setLogged(!logged);
  };

  useEffect(() => {
    if (userToken === null) {
      setLogged(false);
    }
  }, [userToken]);

  useEffect(() => {
    if (userToken) {
      loadNewsHandler();
    }

    if (filteredPatients) {
      console.log('filteredPatients', filteredPatients);
      dispatch(resetUsage());
      loadPatientDataHandler(filteredPatients);
      loadFilteredPatientsAdherence(filteredPatients.map((el) => el.patient.id));
    }

    if (!firstLoadCollapse) {

      if (window.innerWidth < 1300) {
        setMenuCollapsed(true);
      }
      setFirstLoadCollapse(true);
    }

    eventLoadingHandler();

  }, [userToken, filteredPatients, startDate]);

  const togglePatient = () => {
    setPatientRedirect(!patientRedirect);
  };

  let routing = (
    <Switch>
      <Route path="/login">
        <Login loggedAction={toggleLogged} title="Giada - Login" />
      </Route>
      <Redirect to='/login' />
    </Switch>

  );

  const loadPatientDataHandler = (customPatients) => {
    let patientsToLoad = customPatients ? customPatients : filteredPatients;
    // if events loaded is true, the first page loading has already been made. In that case the data is reset before beign updated. 
    if (eventsLoaded) {
      dispatch(resetData());
      dispatch(resetUsage());
      dispatch(resetCurrentEvents());
      dispatch(savePatientsTotals());
    }
    resetPatientAdherence();
    dispatch(resetUnused());
    dispatch(resetGoals());
    dispatch(resetActivities());
    dispatch(resetAdherenceTarget());
    dispatch(startLoading());

    Promise.all(patientsToLoad.map(element => Promise.all([
      loadMoodCall(element.patient.id),
      loadSurveys(element.patient.id),
      // loadClicnicalEvents(element.patient.id),
      loadPatientsAdherence(element.patient.id),
      loadActivitesHandler(element.patient.id),
      loadGoals(element.patient.id),
      loadUsage(element.patient.id),
    ]))).then((values) => {
      dispatch(stopLoading());
    });


  };

  /** Activities Loading*/
  const loadActivitesHandler = (id) => {
    const date = startDate ? startDate : new Date();
    const lastDay = endDate ? endDate : new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const currentDay = new Date().getDate();

    // load current data
    axios.get(`${callUrl}/api/activity/${id}/list/${date.getFullYear()}-${date.getMonth() + 1}-01/${lastDay.getFullYear()}-${lastDay.getMonth() + 1}-${lastDay.getDate() + 1}`, {
      headers: { 'X-AUTH-TOKEN': userToken },
    })
      .then(response => {

        if (response.data.status === "success") {
          dispatch(loadActivites({
            id: id,
            activites: response.data.data.daily_activities
          }));
        } else {
          dispatch(sendMessage({
            message: 'Impossibile recuperare dati attività: ' + response.data.message,
            positive: false,
          }));
        }
      })
      .catch(error => dispatch(sendMessage({
        message: 'Impossibile recuperare dati attività: ' + error,
        positive: false,
      })));

    // load past data
    return axios.get(`${callUrl}/api/activity/${id}/list/${date.getFullYear()}-${date.getMonth()}-01/${lastDay.getFullYear()}-${lastDay.getMonth() + 1}-${currentDay + 1}`, {
      headers: { 'X-AUTH-TOKEN': userToken },
    })
      .then(response => {
        if (response.data.status === "success") {
          dispatch(loadPastSteps({
            id: id,
            activites: response.data.data.daily_activities
          }));
        } else {
          dispatch(sendMessage({
            message: 'Impossibile recuperare dati attività: ' + response.data.message,
            positive: false,
          }));
        }
      })
      .catch(error => dispatch(sendMessage({
        message: 'Impossibile recuperare dati attività: ' + error,
        positive: false,
      })));
  };


  /** Calendar Events Loading */
  const eventLoadingHandler = () => {
    /* Only loads events if events haven't been loaded yet. Used to avoid errors when applying filters to patients*/
    if (eventsLoaded <= 2 && userToken) {
      setSelectedDateString(`${getMonthString(startDate.getMonth())}, ${startDate.getFullYear()}`);
      setEventsLoaded(eventsLoaded + 1);
      if (callUrl !== 'fakeData') {
        axios.get(`${callUrl}/api/users/calendar-events/list/all`, {
          headers: { 'X-AUTH-TOKEN': userToken },
        })
          .then(response => {
            if (response.data.status === "success") {
              dispatch(loadEvents(response.data.data));

              const taggedEvents = response.data.data.tagged_events;
              console.log(taggedEvents);
              if (taggedEvents && taggedEvents.length > 0) {
                const events = [];
                taggedEvents.forEach(element => {
                  if (!element.confirmed_by_medic) {
                    const event = {
                      eventId: element.id,
                      date: new Date(element.date),
                      title: element.title,
                      content: element.content,
                      name: element.user.firstname,
                      lastName: element.user.lastname,
                      pathologies: element.user.pathologies,
                    };
                    events.push(event);
                  }
                });

                setPatientTaggedEvents(events);
              }

            } else {

              dispatch(sendMessage({
                message: 'Impossibile recuperare dati eventi: ' + response.data.message,
                positive: false,
              }));
            }
          })
          .catch(error => {
            console.log('error');
            console.log(error.response);
            // additional check to see if token is expired
            if (error.response.status === 403) {
              setLogged(false);
              localStorage.removeItem('token');
              dispatch(userLogin({ data: null, logError: "Token scaduto, effettua nuovamente il login pr continuare" }));
            }
            dispatch(sendMessage({
              message: 'Impossibile recuperare dati eventi: ' + error,
              positive: false,
            }));
          });
      }
    }

  };

  /** Moods Loading */
  const loadMoodCall = (id) => {
    const date = startDate ? new Date(startDate) : new Date();
    const lastDay = endDate ? endDate : new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return axios.get(`${callUrl}/api/moods/patients/${id}/list/${date.getFullYear()}-${date.getMonth() + 1}-01/${date.getFullYear()}-${date.getMonth() + 1}-${lastDay.getDate()}`, {
      headers: { 'X-AUTH-TOKEN': userToken },
    })
      .then(response => {
        const incomingMoodsArray = response.data.data.moods;
        const moodHistory = [];
        let aggregateMood = 0;

        dispatch(saveMoods({ patientID: id, moodsArray: incomingMoodsArray ?? [] }));
        if (incomingMoodsArray && incomingMoodsArray.length > 0) {
          incomingMoodsArray.forEach(mood => {
            aggregateMood += mood.rating;
            moodHistory.push(mood.rating);
          });
        }

        if (aggregateMood > 0 && moodHistory.length > 0) {
          aggregateMood = aggregateMood / moodHistory.length;
        }

        setPatientMoods({
          patientId: id,
          moodHistory,
          aggregateMood,
        });
      })
      .catch(error => dispatch(sendMessage({
        message: 'Impossibile recuperare dati umore: ' + error,
        positive: false,
      })));
  };

  /** Adherence Loading */
  const loadPatientsAdherence = (id) => {

    const date = startDate ? startDate : new Date();
    const lastDay = endDate ? endDate : new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return axios.get(`${callUrl}/api/patient-drugs/therapeutic-adherences-stats/${date.getFullYear()}-${date.getMonth() + 1}-01/${date.getFullYear()}-${date.getMonth() + 1}-${lastDay.getDate() + 1}/true?patient=${id
      }`, {
      headers: { 'X-AUTH-TOKEN': userToken },
    })
      .then(response => {

        setPatientAdherence({
          patientID: id,
          rate: response.data.data.rate,
          data: response.data.data.data,
        });

        dispatch(savePatientsAdherence({
          id: id,
          array: response.data.data,
          rate: response.data.data.rate
        }));
      })
      .catch(error => {
        dispatch(sendMessage({
          message: 'Impossibile recuperare dati aderenza: ' + error,
          positive: false,
        }));
        if (isDev()) console.log('adherence call', `${callUrl}/api/patient-drugs/therapeutic-adherences-stats/${date.getFullYear()}-${date.getMonth() + 1}-01/${date.getFullYear()}-${date.getMonth() + 1}-${lastDay.getDate() + 1}/true?patient=${id
          }`);
      }
      );

  };

  /** load filterdPatients adherence */
  const loadFilteredPatientsAdherence = (ids) => {
    if (ids.length > 0) {
      const date = startDate ? startDate : new Date();
      const lastDay = endDate ? endDate : new Date(date.getFullYear(), date.getMonth() + 1, 0);

      axios.get(`${callUrl}/api/patient-drugs/therapeutic-adherences-stats/${date.getFullYear()}-${date.getMonth() + 1}-01/${date.getFullYear()}-${date.getMonth() + 1}-${lastDay.getDate() + 1}/true?patients=${ids.join(',')}`, {
        headers: { 'X-AUTH-TOKEN': userToken },
      })
        .then(response => {
          dispatch(saveAdherences(response.data.data.data));
          dispatch(saveTotalAdherence(response.data.data.rate));
        })
        .catch(error => dispatch(sendMessage({
          message: 'Impossibile recuperare dati aderenza: ' + error,
          positive: false,
        })));
    } else {
      dispatch(saveAdherences([]));
      dispatch(saveTotalAdherence(0));
    }

  };
  /** News Loading */
  const loadNewsHandler = () => {
    if (callUrl !== 'fakeData') {

      axios.get(`${callUrl}/api/news/list/all`, {
        headers: { 'X-AUTH-TOKEN': userToken },
      })
        .then(response => {
          dispatch(loadNews(response.data));
        })
        .catch(error => dispatch(sendMessage({
          message: 'Impossibile recuperare le news: ' + error,
          positive: false,
        })));

    } else {

      dispatch(loadNews(exampleData.newsData));
    }
  };

  /** Goals Loading */

  const loadGoals = (id) => {

    return axios.get(`${callUrl}/api/goals/patients/${id}/all`, {
      headers: { 'X-AUTH-TOKEN': userToken },
    })
      .then(response => {
        dispatch(addGoals({ patientID: id, goalsArray: response.data.data.goals }));
        setUniqueGoals({ patientID: id, goalsArray: response.data.data.goals });
      })
      .catch(error => dispatch(sendMessage({
        message: 'Impossibile recuperare dati traguardi: ' + error,
        positive: false,
      })));
  };

  /** Surveys Loading */
  const loadSurveys = (id) => {

    return axios.get(`${callUrl}/api/surveys/patients/${id}/list`, {
      headers: { 'X-AUTH-TOKEN': userToken },
    })
      .then(response => {
        const patientSurveys = response.data.data.surveys;

        if (patientSurveys && patientSurveys.length > 0) {
          dispatch(addSurveys({ patientID: id, surveyArray: response.data.data.surveys }));
          patientSurveys.forEach((survey) => {
            setUniquePHQ({
              patientID: id,
              surveyID: survey.id,
              date: survey.date,
              score: survey.score,
              answers: survey.answers,
            });
          });

        }
      })
      .catch(error => dispatch(sendMessage({
        message: 'Impossibile recuperare dati questionari: ' + error,
        positive: false,
      })));
  };

  /** Usage Loading */

  const loadUsage = (id) => {
    console.log('loadUsage', id);
    const date = startDate ? startDate : new Date();
    const lastDay = endDate ? endDate : new Date(date.getFullYear(), date.getMonth() + 1, 0);


    axios.get(`${callUrl}/api/app-session/patient/${id}/app-session-stats/${date.getFullYear()}-${date.getMonth() + 1}-01/${date.getFullYear()}-${date.getMonth() + 1}-${lastDay.getDate() + 1}`, {
      headers: { 'X-AUTH-TOKEN': userToken },
    })
      .then(response => {

        if (response.data.status === "success") {
          dispatch(setUsage({ id: id, usage: response.data.data }));
          dispatch(setUnused({ id: id, usage: response.data.data }));

        } else {
          dispatch(sendMessage({
            message: 'Impossibile recuperare dati utilizzo: ' + response.data.message + ' ' + id,
            positive: false,
          }));
        }
      })
      .catch(error => dispatch(sendMessage({
        message: 'Impossibile recuperare dati utilizzo: ' + error,
        positive: false,
      })));


  };

  // dispatches pathology selected filter
  const pathologyFilterHandler = (pathology) => {
    setCurrentPathologyFilter(pathology);
    dispatch(pathologyFilter(pathology));
  };

  // dispatches gender selected filter
  const genderFilterHandler = (gender) => {

  };


  const updateRange = (date) => {
    setStartDate(new Date(date));
    dispatch(setUserStartDate(new Date(date).toString()));
    setEndDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));

    setSelectedDateString(`${getMonthString(new Date(date).getMonth())}, ${new Date(date).getFullYear()}`);
  };

  if (logged) {

    routing = (
      <React.Fragment>
        {navigationTarget !== "" && <Redirect to={navigationTarget} />}
        <Menu collapsed={menuCollapsed} />
        <div className={`main-container ${menuCollapsed ? 'collapsed' : ''}`}>
          <div className="container">
            <HeaderSearch collapsed={menuCollapsed} collapseMenu={() => menuToggleHandler()} patientRedir={togglePatient} />
            <Switch>
              <Route path="/dashboard">
                {/* <Notification />*/}
                <Dashboard title="Giada - Dashboard"
                  loading={{ user: userInfoLoading }}
                  data={userInfo}
                  currentPathology={currentPathologyFilter}
                  pathologyFilter={pathologyFilterHandler}
                  pathologies={patientsPathologies}
                  updateRange={updateRange}
                  selectedRangeString={selectedDateString}
                  menuCollapsed={menuCollapsed} />
              </Route>
              <Route path="/pazienti">
                <Patients title="Giada - Pazienti"
                  currentPathology={currentPathologyFilter}
                  genderFilter={genderFilterHandler}
                  pathologyFilter={pathologyFilterHandler}
                  pathologies={patientsPathologies}
                  updateRange={updateRange}
                  selectedRangeString={selectedDateString} />
              </Route>
              <Route path="/pazienti-filtrati/*">
                <FilteredPatients title="Giada - Pazienti"
                  currentPathology={currentPathologyFilter}
                  genderFilter={genderFilterHandler}
                  pathologyFilter={pathologyFilterHandler}
                  pathologies={patientsPathologies}
                  updateRange={updateRange}
                  selectedRangeString={selectedDateString} />
              </Route>
              <Route path="/analisi">
                <Analysis title="Giada - Analisi"
                  currentPathology={currentPathologyFilter}
                  genderFilter={genderFilterHandler}
                  pathologyFilter={pathologyFilterHandler}
                  pathologies={patientsPathologies}
                  updateRange={updateRange}
                  selectedRangeString={selectedDateString} />
              </Route>
              <Route path="/paziente">
                <Patient title="Giada - Paziente"
                  updateRange={updateRange}
                  selectedRangeString={selectedDateString} />
              </Route>
              <Route path="/calendario">
                <Calendar title="Giada - Calendario" />
              </Route>
              <Route path="/news">
                <News title="Giada - News" />
              </Route>
              <Route path="/components/menu">
                <Menu />
              </Route>
              <Route path="/">
                <Redirect to='/dashboard' />
              </Route>
            </Switch>
          </div>
        </div >
      </React.Fragment >
    );

  }

  return (
    <div className="App">
      <SystemMessage />
      <AbsoluteLoader />
      <Router>
        {patientRedirect ? <Redirect to='/paziente' /> : null}
        {routing}
      </Router>
    </div>
  );
}

export default App;

import React from 'react'

const Loader = props => {

    return(
        <React.Fragment>

            <h1>{props.title}</h1>
    
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </React.Fragment>

    )
}

export default Loader;
import React, { useState, useEffect } from 'react'
import { getEventByDate } from '../store/eventsStore';
import { useSelector, useDispatch } from 'react-redux';
import HorizontalDatepicker from "./verticaleCalendarDatepicker/verticalCalendarDatepicker";

import ChevronLeft from '../assets/images/fa-icons/chevron-left.svg';
import ChevronRight from '../assets/images/fa-icons/chevron-right.svg';
import Clock from '../assets/images/fa-icons/clock.svg';


const VerticalCalendar = (props) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currentMonth, setCurrentMonth] = useState('');
    const months=['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'];
    const dayEvents = useSelector((state) => state.events.dayEvents);
    const dispatch = useDispatch();

    
    useEffect(() => {

        selectedDayHandler();
        changeMonthHandler()
     
    }, [currentDate]);

    const selectedDay = (val) => {
        setCurrentDate(val);
    };

    const changeMonthHandler = () => {
        const monthIndex = currentDate.getMonth();
        setCurrentMonth(months[monthIndex]);
    }
    

    const nextDay = () => {
        let oldDate = currentDate;
        oldDate.setDate(oldDate.getDate() + 1);
        setCurrentDate(new Date(oldDate));
    };
    const prevDay = () => {
        let oldDate = currentDate;
        oldDate.setDate(oldDate.getDate() - 1);
        setCurrentDate(new Date(oldDate));
    };

    const selectedDayHandler = () => {
        const dateString = currentDate.toString()
        dispatch(getEventByDate(`${dateString}`));
    }

    const transformEvents = () => {
        const transformedEvents = [];
        if(dayEvents){
            dayEvents.forEach(element => {
                const date = new Date(element.date);
                let appointmentUser = null;

                element.tagged_users.forEach(user => {

                    if (appointmentUser === null) {
                        appointmentUser = user;
                    }
                })

                transformedEvents.push({
                    name: appointmentUser ? `${appointmentUser.firstname} ${appointmentUser.lastname}` : '',
                    title: element.title,
                    time: `${date.getHours()}:${date.getMinutes()}`,
                })
            })
        }
        return transformedEvents
    }

    /* TODO Change to daily props */
    const dailyAppointments = {
        1:{
            name: 'Shawn Hampton',
            event: 'Titolo evento',
            time: '9:00'
        },
        2:{
            name: 'Polly Paul',
            event: 'Titolo evento',
            time: '9:30'
        },
        3:{
            name: 'Jessie Paul',
            event: 'Titolo evento',
            time: '10:00'
        },
        4:{
            name: 'Mabel Perkins',
            event: 'Titolo evento',
            time: '11:20'
        }
    }

    return(
        <div className="rounded-box tall vertical-calendar">
            <div className="title">
                Appuntamenti programmati
            </div>
            <div className="month">
                {currentMonth}
            </div>
            <div className="calendar-days">
                <img className="icon-img" src={ChevronLeft} onClick={prevDay} />
                <HorizontalDatepicker
                    getSelectedDay={selectedDay}
                    labelFormat={"MMMM"}
                    color={"#374e8c"}
                    selectDate={currentDate}
                />
                <img className="icon-img" src={ChevronRight} onClick={nextDay}/>
            </div>
            <div className="calendar-content">
                {transformEvents().length > 0 ? transformEvents().map((element, i) =>(
                    <div key={i} className="appointment">
                        <div className="avatar"></div>
                        <div className="info-wrapper ml-1">
                            <div className="info">
                                <p className="name">{element.name}</p>
                                <p>{element.title}</p>
                            </div>
                            <div className="time">
                                <p>{element.time} <img className="icon-img" src={Clock} /></p>
                            </div>
                        </div>
                    </div>  
                )) : <p className="text-center mt-5">Nessun evento nella giornata selezionata</p> }
            </div>
        </div>
    )
}

export default VerticalCalendar;
import React, { useState, useEffect } from 'react'
import { getEventByDate } from '../store/eventsStore';
import { useSelector, useDispatch } from 'react-redux';
import HorizontalDatepicker from "./verticaleCalendarDatepicker/verticalCalendarDatepicker";

import ChevronLeft from '../assets/images/fa-icons/chevron-left.svg';
import ChevronRight from '../assets/images/fa-icons/chevron-right.svg';
import Clock from '../assets/images/fa-icons/clock.svg';
import IntersectionDayElement from './IntersectionDayElement';


let interval = null;
export default function VerticalScrollableCalendar(props) {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currentMonth, setCurrentMonth] = useState('');
    const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
    const dayEvents = useSelector((state) => state.events.dayEvents);
    const allEvents = useSelector((state) => state.events.events);
    const dispatch = useDispatch();
    const [dates, setDates] = useState([]);
    const [scrollToMatch, setScrollToMatch] = useState(false);
    const [prevScrolling, setPrevScrolling] = useState(false);
    const [nextScrolling, setNextScrolling] = useState(false);

    useEffect(() => {

        selectedDayHandler();
        changeMonthHandler()

    }, [currentDate]);

    useEffect(() => {
        if (nextScrolling) {
            nextStepHandler();
        }

    }, [nextScrolling])


    function nextStepHandler() {
        scroll()
            .then(
                function (result) {
                    if (nextScrolling) {
                        nextStepHandler();
                    }
                }
            );

    }

    function scroll() {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                resolve(nextDay());
            }, 200);
        });


    }

    useEffect(() => {
        const daysArray = [];
        const today = new Date();
        // daysArray.push(getDaysInMonth(new Date().getMonth(), new Date().getFullYear()))
        for (let i = 1; i < 3; i++) {
            daysArray.push(getDaysInMonth(new Date().getMonth() + i, new Date().getFullYear()))
        }

        setDates(daysArray.flat().filter(el => el > today));
    }, [])

    function selectedDay(val) {

        if (currentDate !== val) {
            setScrollToMatch(true);
            setCurrentDate(val);
        }
    };

    function changeMonthHandler() {
        const monthIndex = currentDate.getMonth();
        setCurrentMonth(months[monthIndex]);
    }


    function nextDay() {
        let navigatable = false;
        let oldDate = currentDate;
        oldDate.setDate(oldDate.getDate() + 1);
        dates.forEach((element, index) => {
            if (`${oldDate.getDate()}-${oldDate.getMonth()}` === `${element.getDate()}-${element.getMonth()}`) {
                navigatable = true;
            }
        })
        if (navigatable) {
            setCurrentDate(new Date(oldDate));
        }
    };
    function prevDay() {
        let navigatable = false;
        let oldDate = currentDate;
        oldDate.setDate(oldDate.getDate() - 1);
        dates.forEach((element, index) => {
            if (`${oldDate.getDate()}-${oldDate.getMonth()}` === `${element.getDate()}-${element.getMonth()}`) {
                navigatable = true;
            }
        })

        if (navigatable) {
            setCurrentDate(new Date(oldDate));
        }
    };

    function selectedDayHandler() {
        const dateString = currentDate.toString()
        dispatch(getEventByDate(`${dateString}`));
    }

    function dayIntoView(date) {
        setCurrentDate(new Date(date));
    }

    // check if there's an event for the given date
    function getDayEvents(eventDate) {
        return allEvents ? allEvents.filter(el => new Date(el.date).getDate() === new Date(eventDate).getDate() && new Date(el.date).getMonth() === new Date(eventDate).getMonth()) : [];
    }

    function addIntevalHandler(dir) {
        if (interval === null) {
            interval = setInterval(() => {
                if (dir === 'next') {
                    nextDay();

                } else {
                    prevDay();
                }
            }, 200);
        }
    }

    function clearIntervalHandler() {
        clearInterval(interval)
        interval = null;
        setScrollToMatch(true);

    }


    return (
        <div className="rounded-box tall vertical-calendar">
            <div className="title">
                Appuntamenti programmati
            </div>
            <div className="month">
                {currentMonth}
            </div>
            <div className="calendar-days">
                <div style={{ padding: "1rem 0.2rem", cursor: "pointer" }} onClick={prevDay}>
                    <img className="icon-img" src={ChevronLeft}  />
                </div>
                <HorizontalDatepicker
                    getSelectedDay={selectedDay}
                    labelFormat={"MMMM"}
                    color={"#374e8c"}
                    selectDate={currentDate}
                />
                <div style={{ padding: "1rem 0.2rem", cursor: "pointer" }} onClick={nextDay}>
                    <img className="icon-img" src={ChevronRight}  />
                </div>
            </div>
            <div className="calendar-content">
                <div className={`scrollable-calendar`}>
                    {dates.map((d, index) =>
                    (
                        <div key={'d' + index}>

                            <IntersectionDayElement
                                date={new Date(d)}
                                entersInView={(arg) => dayIntoView(arg)}
                                intoView={currentDate}
                                scrolled={() => setScrollToMatch(false)}
                                scrollToMatch={scrollToMatch}
                                monthsArray={months}
                            />
                            <div className="appointmens-container">
                                {getDayEvents(d).length > 0 ? getDayEvents(d).map((event, index) => (
                                    <div key={'sc-' + index} className="appointment">
                                        <div className="avatar"></div>
                                        <div className="info-wrapper ml-1">
                                            <div className="info">
                                                <p className="name">{event.tagged_users.length > 0 ? <span>{event.tagged_users[0]?.firstname ?? ''} {event.tagged_users[0]?.lastname ?? ''} </span> : 'nessun utente collegato'} </p>
                                                <p className="name colorless">{event.title}</p>
                                            </div>
                                            <div className="time">
                                                <p>{`${new Date(event.date).getHours()}:${new Date(event.date).getMinutes()}`} </p>
                                                <img className="icon-img" src={Clock} />
                                            </div>
                                        </div>
                                    </div>
                                )) : <p className="empty">Nessun Appuntamento</p>}
                            </div>
                        </div>
                    )
                    )}
                </div>
                {/* {transformEvents().length > 0 ? transformEvents().map((element, i) =>(
                    <div key={i} className="appointment">
                        <div className="avatar"></div>
                        <div className="info-wrapper ml-1">
                            <div className="info">
                                <p className="name">{element.name}</p>
                                <p>{element.title}</p>
                            </div>
                            <div className="time">
                                <p>{element.time} <img className="icon-img" src={Clock} /></p>
                            </div>
                        </div>
                    </div>  
                )) : <p className="text-center mt-5">Nessun evento nella giornata selezionata</p> } */}
            </div>
        </div>
    )
}


function getDaysInMonth(month, year) { return (new Array(31)).fill('').map((v, i) => new Date(year, month - 1, i + 1)).filter(v => v.getMonth() === month - 1) }
import { useEffect, useState } from 'react';

import CalendarCheck from '../../assets/images/fa-icons/calendar-check.svg';
import CalendarTimes from '../../assets/images/fa-icons/calendar-times.svg';
import Clock from '../../assets/images/fa-icons/clock.svg';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { sendMessage } from '../../store/messagesStore';
import { loadEvents } from '../../store/eventsStore';
import getCallUrl from '../../utils/callUrlUtil';

const ContextAppointment = props => {
    const dispatch = useDispatch();
    const userToken = useSelector((state) => state.user.authToken);
    const user = useSelector((state) => state.user.user);
    const date = new Date(props.object.date);
    const [appointmentDeleted, setAppointmentDeleted] = useState(false);
    const taggedUsers = [];
    let callUrl = getCallUrl();

    if (props.object.tagged_users.length > 0) {
        props.object.tagged_users.forEach(user => {
            taggedUsers.push(`${taggedUsers.length > 0 ? ', ' : ''}${user.firstname} ${user.lastname}${user.pathologies ? '' : ' '}`);
        });
    }

    useEffect(() => {
        console.log('user', user);
    }, [user]);


    // delete appointment
    function deleteAppointemntHandler() {
        console.log('delete appointment', props.object);
        console.log('delete appointment', props.object.id);
        if (props.object.id) {
            axios.post(`${callUrl}/api/users/calendar-events/${props.object.id}/delete`, null, {
                headers: { 'X-AUTH-TOKEN': userToken },
            })
                .then(response => {
                    if (response.data.status === 'success') {
                        dispatch(sendMessage({
                            message: `Appuntamento eliminato con successo!`,
                            positive: true,
                        }));
                        setAppointmentDeleted(true);
                        eventLoadingHandler();
                    } else {
                        dispatch(sendMessage({
                            message: `Impossibile eliminare l'appuntamento: ` + response.data.message,
                            positive: false,
                        }));
                    }
                })
                .catch(error => dispatch(sendMessage({
                    message: `Impossibile eliminare l'appuntamento errore: ` + error,
                    positive: false,
                }))
                );
        } else {
            sendMessage({
                message: 'Impossibile creare appuntamento: Appointment id not found',
                positive: false,
            });
        }
    }

    /** Calendar Events Loading, called when appoitment deleted */
    const eventLoadingHandler = () => {

        axios.get(`${callUrl}/api/users/calendar-events/list/all`, {
            headers: { 'X-AUTH-TOKEN': userToken },
        })
            .then(response => {
                if (response.data.status === "success") {
                    dispatch(loadEvents(response.data.data));
                } else {
                    dispatch(sendMessage({
                        message: 'Impossibile recuperare dati eventi: ' + response.data.message,
                        positive: false,
                    }));
                }
            })
            .catch(error => dispatch(sendMessage({
                message: 'Impossibile recuperare dati eventi: ' + error,
                positive: false,
            })));
    };

    return (
        !appointmentDeleted ?
            <div className="context-appointment" >
                <div className="appointment-inner" onClick={props.confirmAppointment}>
                    <div className="avatar"></div>
                    <div className="content">
                        <p className="name">{props.object.title}</p>
                        <div>{props.object.confirmed_by_medic ? <p className="confirmed" ><img className="icon-img" src={CalendarCheck} alt="" /> Confermato</p> : <p><img className="icon-img" src={CalendarTimes} alt="" /> Da confermare</p>}</div>
                        {/*<p>{props.object.title}</p>
                    props.object.caregiver ? <p>Caregiver: {props.object.caregiver}</p> : 'Nessun caregiver selezionato'*/}
                        <p>
                            {
                                taggedUsers.length > 0 ?
                                    taggedUsers.map((user, index) => <span key={'u' + index}>{user}</span>)
                                    : null
                            }
                        </p>
                    </div>
                    <div className="time">
                        {date.getHours()}:{('0' + date.getMinutes()).slice(-2)} <img src={Clock} className="icon-img" alt="clock" />
                    </div>
                </div>
                {/* delete appointment button */}
                {!props.object.taggedType && props.object.user.id === user.id ?
                    <button className="button deny delete-button" onClick={() => deleteAppointemntHandler()}>
                        Elimina
                    </button> : null}
            </div>
            : null
    );
};

export default ContextAppointment;
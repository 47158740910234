import React from 'react';
import PatientInfo from '../../components/patient/patientInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Confirmation = props => {

    const date = props.object ? new Date(props.object.date) : null;
    const patientsArray = [];
    const nonPatiensArray = [];

    if (props.object && props.object.tagged_users.length > 0) {
        props.object.tagged_users.forEach(user => {
            user.pathologies ? patientsArray.push(user) : nonPatiensArray.push(user);
        })
    }

    if(!props.object){
        return null
    }

    return(
        <React.Fragment>
            <div className="section-title">
                <p>{props.object.title}</p>
                <div className="time">
                    {date.getHours()}:{date.getMinutes()}  <FontAwesomeIcon icon="clock" />
                </div>
            </div>
            {
                patientsArray.length > 0 ? 
                    patientsArray.map(patient => <PatientInfo object={patient} />)
                : null
            }
            <div className="section-title">
                <p>Caregiver</p>
            </div>
            <p>{nonPatiensArray.length > 0 ? nonPatiensArray.map((caregiver,index) => {
                return `${index > 0 ? ', ' : ''}${caregiver.firstname} ${caregiver.lastname}`
            }) : 'Nessun caregiver selezionato' }</p>

            <div className={`modal-buttons ${props.object.confirmed_by_medic ? 'd-none' : ''}`}>
                <button onClick={props.denyAppointment} className="button big deny">
                    <FontAwesomeIcon icon="calendar-times" />
                    Rifiuta
                </button>
                <button onClick={props.confirmAppointment} className="button big accept">
                    <FontAwesomeIcon icon="calendar-check" />
                    Conferma
                </button>
            </div>
        </React.Fragment>
    )
}

export default Confirmation;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    message:null,
    messagePositive: false,
};


export const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        sendMessage: (state, action) => {
            state.message = action.payload.message;
            state.messagePositive = action.payload.positive;
        },
        clearMessage : (state) => {
             state.message = ''
        },
        
    }
    
});


export const { sendMessage, clearMessage } = messageSlice.actions;


export default messageSlice.reducer;
import { ResponsiveBar } from '@nivo/bar';
import { type } from 'os';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import isDev from '../../hooks/isDev';
import { genderFilter } from '../../store/userStore';


const colors = { 'uomo': 'hsl(215, 100%, 84%)', 'donna': 'hsl(302, 100%, 84%)', 'altro': 'hsl(42, 100%, 66%)' };



const PatientsGenderBars = ({ filteredPatients }) => {
  const userPatients = useSelector((state) => state.user.userPatients);
  const dispatch = useDispatch();
  const history = useHistory();
  const getColor = bar => colors[bar.id];
  const [patientHover, setPatientHover] = useState('');
  const [patientCount, setPatientCount] = useState({
    male: 0,
    female: 0,
    other: 0,
  });

  useEffect(() => {
    getPatientsCount();
    // console.log('filteredPatients', filteredPatients)
  }, [filteredPatients]);

  const getPatientsCount = () => {
    let other = 0;
    let male = 0;
    let female = 0;

    if (filteredPatients) {
      filteredPatients.forEach(element => {
        if (element.patient.gender === 'f') {
          female++;
        } else if (element.patient.gender === 'm') {
          male++;

        } else {
          other++;
        }
      });


    }

    setPatientCount({
      male: male,
      female: female,
      other: other
    })
  }

  function guideClickHandler(gender) {
    dispatch(genderFilter({ gender: gender }));
    history.push("/pazienti/");
  }

  function mouseHoverHandler(value) {
    switch (value) {
      case 'uomo':
        setPatientHover('m');
        break;
      case 'donna':
        setPatientHover('f');
        break;
      case 'altro':
        setPatientHover('o');
        break;
      default:
        setPatientHover('');
        break;
    }
  }

  function barClickHandler(value) {
    switch (value) {
      case 'uomo':
        guideClickHandler('m');
        break;
      case 'donna':
        guideClickHandler('f');
        break;
      default:
        guideClickHandler('o');
        break;
    }
  }

  return (
    <React.Fragment>
      <div className="chart-wrapper">
        <ResponsiveBar
          keys={[
            'uomo',
            'donna',
            'altro',
          ]}
          enableGridX={true}
          axisBottom={{
            "orient": "bottom",
            "tickSize": 0,
            "tickPadding": 5,
            "tickRotation": 0,
            "format": () => null,
          }}
          axisLeft={{
            "orient": "left",
            "tickSize": 0,
            "tickPadding": 5,
            "tickRotation": 0,
            "format": () => null,
          }}
          margin={{ top: 10, right: 10, bottom: 20, left: 5 }}
          padding={0.05}
          innerPadding={8}
          groupMode='grouped'
          layout="horizontal"
          tooltip={({ id, value, color }) => (
            <div
              style={{
                padding: 12,
                background: '#fff',
                borderRadius: '5px',
                boxShadow: '0 0.8rem 0.8rem rgba(20, 46, 110, 0.1)'
              }}
            >
              <div style={{ display: 'inline-block', height: '1rem', width: '1rem', background: color, marginRight: '1rem' }}></div>
              <strong>
                {id}: {value}
              </strong>
            </div>
          )}
          data={[
            {
              "uomo": patientCount.male,
              "donna": patientCount.female,
              "altro": patientCount.other,
            },
          ]}
          colors={getColor}
          onClick={(data) => {
            barClickHandler(data["id"]);

          }}
          onMouseEnter={(data, event) => {
            mouseHoverHandler(data.id);
          }}
          onMouseLeave={(data, event) => {
            mouseHoverHandler('');
          }}
        />
      </div>
      <div className="legend-wrapper">
        <div onClick={() => guideClickHandler('m')} className={`legend-item ${patientHover === 'm' ? 'selected' : ''}`}>
          {patientCount.male} UOMO
        </div>
        <div onClick={() => guideClickHandler('f')} className={`legend-item female ${patientHover === 'f' ? 'selected' : ''}`}>
          {patientCount.female} DONNA
        </div>
        <div onClick={() => guideClickHandler('o')} className={`legend-item other ${patientHover === 'o' ? 'selected' : ''}`}>
          {patientCount.other} ALTRO
        </div>
      </div>
    </React.Fragment>
  );
};

export default PatientsGenderBars;
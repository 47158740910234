import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SimpleModal from '../../components/simpleModal';
import { sendMessage } from '../../store/messagesStore';
import { updateComorbidity } from '../../store/userStore';
import getCallUrl from '../../utils/callUrlUtil';

type Props = {
    userToken: string,
    setNewPatientComorbities: (arg: any[]) => void,
};

export default function Comorbility({ userToken, setNewPatientComorbities }: Props) {
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [newComorbidityName, setNewComorbidityName] = useState<string>('');
    const [comorbidities, setComorbidities] = useState<any[]>([]);
    const [patientComorbidities, setPatientComorbidities] = useState<any[]>([]);
    const callUrl = getCallUrl();
    const dispatch = useDispatch();
    //@ts-ignore
    const patient = useSelector((state) => state.user.selectedPatient ? state.user.selectedPatient.patient : null);
    //@ts-ignore
    const patientMedicId = useSelector((state) => state.user.selectedPatient ? state.user.selectedPatient.id : null);


    useEffect(() => {
        if (showEditModal) {
            setPatientComorbidities(patient.comorbidities);
            getComorbidities();
        }
    }, [patient, showEditModal]);

    const getComorbidities = () => {

        axios.post(`${callUrl}/api/medics/comorbidity/list`, {}, {
            headers: { 'X-AUTH-TOKEN': userToken },
        })
            .then(response => {
                setComorbidities(response.data.data.comorbidities);
                setNewComorbidityName("");
            })
            .catch(error => {
                console.log(error);
                console.log(error.message);
                console.log(error.response);
                console.log(error.request);
            });

    };

    const addComorbidity = (comor: string) => {
        if (comor !== '') {
            const currentPathArr = [...patientComorbidities];
            console.log(currentPathArr);

            currentPathArr.push(newComorbidityName);

            setPatientComorbidities(currentPathArr);
            setNewComorbidityName("");
        }

    };
    const removeComorbidityHandler = (removingIndex: number) => {
        const newComorArr: any[] = [];


        patientComorbidities.forEach((element, index) => {
            if (removingIndex !== index) {
                newComorArr.push(element);
            }
        });


        setPatientComorbidities(newComorArr);
    };

    const cancelProfileUpdateHandler = () => {
        setShowEditModal(false);
        setPatientComorbidities([]);
    };

    const saveProfileUpdateHandler = () => {

        axios.post(`${callUrl}/api/medics/patients/${patientMedicId}/update-comorbidity`, { "comorbidities": patientComorbidities }, {
            headers: { 'X-AUTH-TOKEN': userToken },
        })
            .then(response => {
                if (response.data.status === 'success') {
                    dispatch(sendMessage({
                        message: 'Profilo paziente aggiornato',
                        positive: true,
                    }));
                    dispatch(updateComorbidity(patientComorbidities));
                    setNewPatientComorbities(patientComorbidities);
                    setShowEditModal(false);
                } else {
                    dispatch(sendMessage({
                        message: 'Impossibile aggiornare profilo paziente: ' + response.data.message,
                        positive: false,
                    }));
                }

            })
            .catch(error => {
                console.log(error.message);
                console.log(error.response);
                console.log(error.request);
                dispatch(sendMessage({
                    message: 'Impossibile aggiornare profilo paziente: ' + error,
                    positive: false,
                }));
            });
    };


    return (
        <React.Fragment>
            <SimpleModal visible={showEditModal} toggleModal={() => setShowEditModal(!showEditModal)} modalTitle="Modifica comorbilità utente">
                <div>
                    <div className="comorbidity-adder">
                        <div className="d-flex align-items-center">
                            <label>Seleziona Comorbilità</label>
                            <div className="select-group ml-1">
                                <span className="select-arrow top"></span>
                                <select value={newComorbidityName} onChange={(e) => setNewComorbidityName(e.target.value)}>
                                    <option value="">Scegli comorbilità</option>
                                    {comorbidities.length > 0 ? comorbidities.filter(comorbity => !patientComorbidities.includes(comorbity)).map((item, index) => <option key={'comOption' + index}>{item}</option>) : null}
                                </select>
                            </div>
                        </div>
                        <button className={`button secondary ${newComorbidityName === '' ? 'disabled' : ''}`} onClick={() => addComorbidity(newComorbidityName)}>Aggiungi</button>
                    </div>
                    <ul className="comorbidity-list">
                        {
                            patientComorbidities.length > 0 ? patientComorbidities.map((item, index) => {

                                return (<li key={index}> {item} <button className="button deny" onClick={() => removeComorbidityHandler(index)}>Rimuovi</button></li>);

                            }) : <p>Nessuna comorbilità</p>
                        }
                    </ul>
                    <div className="comorbidities-buttons">
                        <button className="button big deny" onClick={cancelProfileUpdateHandler}>Cancella</button>
                        <button className="button big" onClick={saveProfileUpdateHandler}>Salva</button>
                    </div>
                </div>
            </SimpleModal>
            <button className="button outlined" onClick={() => {
                setNewComorbidityName('');
                setShowEditModal(true);
            }}><FontAwesomeIcon icon="pen" /></button>
        </React.Fragment>
    );
}

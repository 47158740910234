import isDev from "../hooks/isDev";

export default function getCallUrl(){
  //prod
  let urlToReturn = `https://cms.giada-accantoate.it`;
  // // stage
  // let urlToReturn = `http://never-alone.melazeta.com`;

  if(isDev()){
      urlToReturn = 'https://localhost:8000';
  }

  return urlToReturn;

}
const Caregiver = props => {

    function getGenderClass(gender) {
        switch (gender) {
            case 'm':
                return 'gender-m';
            case 'f':
                return 'gender-f';
            default:
                return 'gender-x';
        }
    }

    return(
        <div className="caregiver-wrapper">
            <div className={`avatar ${props.caregiver ? getGenderClass(props.caregiver.gender) : ''}`}></div>
            <div className="name-wrapper">
                {/* <p>Caregiver</p> */}
                <p className="caregiver-name">{ props.caregiver ? <span>{props.caregiver.firstname} {props.caregiver.lastname}</span> : null}</p>
            </div>
        </div>
    )
}

export default Caregiver;
import React from 'react';

const ActivityBox = props => {
    return(
        <div className="rounded-box big activity-box" onClick={props.click}>
            <div className="info-wrapper">
                <div className="activity-title">{props.title}</div>
                <div className={`activity-info ${props.dataStatus}`}>{props.data}</div>
                <div className="activity-timing">{props.date}</div>
            </div>
            <div className="activity-icon">
                <img src={props.type} />
            </div>
        </div>
    )
}

export default ActivityBox;
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSurveyTotal } from '../../store/surveysStore';
import ActivityBox from './activityBox';
import DailyUse from '../../assets/images/icons/phone.png';
import Adeherence from '../../assets/images/icons/list.png';
import Mood from '../../assets/images/icons/dashboard.png';
import Step from '../../assets/images/icons/shoes.png';
import Goal from '../../assets/images/icons/cup.png';
import Survey from '../../assets/images/icons/paper.png';
import { useFilteredPHQ } from '../../hooks/useFilteredPHQ';
import { useAtomValue, useSetAtom } from 'jotai';
import { navigationTargetAtom } from '../../jotai/navigationTargetAtom';
import { patientIdsUnderTargetAtom } from '../../jotai/patientMoodsAtoms';
import { useUncompletedGoals } from '../../hooks/useUncompletedGoals';

export default function ActivityBoxes() {
  const startDate = useSelector((state) => state.user.startDate);
  const setNavigationTarget = useSetAtom(navigationTargetAtom);
  const dispatch = useDispatch();
  const adherencesUnderTarget = useSelector((state) => state.patients.adherencesUnderTarget);
  const unused = useSelector((state) => state.usage.unused);
  const decreasingActivities = useSelector((state) => state.activities.decreasingActivities);
  const filteredPatients = useSelector((state) => state.user.filteredPatients);
  const moodsUnderTarget = useAtomValue(patientIdsUnderTargetAtom);





  useEffect(() => {
    dispatch(getSurveyTotal({ startDate: startDate, endDate: startDate }));
  }, [moodsUnderTarget, startDate]);

  return (
    <div className="row">
      <div className="col-12">
        <div className='activity-box-wrapper'>
          <ActivityBox
            title="Non usano l'app"
            data={unused.length}
            click={() => setNavigationTarget("/pazienti-filtrati/app-non-usata")}
            date="Questo Mese"
            type={DailyUse}
          />

          <ActivityBox
            title="Aderenza <90%"
            data={Math.round(adherencesUnderTarget.length)}
            dataStatus={'good'}
            // dataStatus={adherencesUnderTarget ? adherencesUnderTarget < 0.7 ? adherencesUnderTarget < 0.55 ? 'danger' : 'warning' : 'good' : 'good'}
            date="Questo Mese"
            click={() => {
              setNavigationTarget("/pazienti-filtrati/aderenza-bassa");
            }}
            type={Adeherence}
          />

          <ActivityBox
            title="Umore <3/5"
            data={moodsUnderTarget.length}
            // dataStatus={moodsUnderTarget ? moodsUnderTarget < 7 ? moodsUnderTarget < 5.5 ? 'danger' : 'warning' : 'good' : 'danger'}
            dataStatus={'good'}
            date="Questo Mese"
            click={() => setNavigationTarget("/pazienti-filtrati/umore-basso")}
            type={Mood}
          />

          <ActivityBox
            title="Attività in diminuzione"
            data={decreasingActivities.length}
            dataStatus="good"
            date="Questo Mese"
            click={() => setNavigationTarget("/pazienti-filtrati/attività-diminuzione")}
            type={Step}
          />

          <ActivityBox
            title="Traguardi mancati"
            data={useUncompletedGoals()}
            dataStatus="good"
            date="Questo Mese"
            click={() => setNavigationTarget("/pazienti-filtrati/traguardi-incompleti")}
            type={Goal}
          />

          <ActivityBox
            title="PHQ9 compilato"
            data={useFilteredPHQ(filteredPatients.map(el => el.patient.id), startDate).length}
            dataStatus="good"
            date="Questo Mese"
            click={() => setNavigationTarget("/pazienti-filtrati/questionari-compilati")}
            type={Survey}
          />
        </div>
      </div>
    </div>
  );
}

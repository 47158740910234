import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    news: null,
    newsArray: null,
    lastestNews: null,
    filteredArray:null,
    ascendingOrder: false,
  };


  export const newsSlice = createSlice({
    name:'news',
    initialState,
    reducers:{
          loadNews:(state, action) => {

            if(action.payload !== undefined){

              state.news = action.payload.data.news

              if(action.payload.data.news.length > 0){

                const sortedNewsArray = action.payload.data.news.sort(function (a, b) {
                  return new Date(b.date) - new Date(a.date);
                })
      
                state.newsArray = sortedNewsArray;
  
                state.lastestNews = sortedNewsArray.length > 0 ? sortedNewsArray[0] : null; 
              }

            }
            
        }, 
        getLatestNews:(state) => {
          
        },
        toggleOrder: (state) => {
          if(state.newsArray){
            state.newsArray.reverse()
          }
        },
        filterNews : (state, action) => {
          if(state.newsArray){
           state.filteredArray = state.newsArray.filter(item => item.title.toLowerCase().includes(action.payload) || item.text.toLowerCase().includes(action.payload))
          }
        },
        removeNewsFilter: (state) => {
          state.newsArray = state.news.data.news.sort(function(a,b){
            return new Date(b.date) - new Date(a.date);
          })
        }
    }
})


export const { loadNews, toggleOrder, filterNews, removeNewsFilter } = newsSlice.actions;

export default newsSlice.reducer;
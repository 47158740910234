import { getFirstDayDate } from "../utils/daysUtils";

const getChartData = (data, startDay, endDay, type) => {

    if (data) {
        // if (type === 'survey') {
        //     console.log('datacheck', data);
        // }
        const dateUsableData = Array.isArray(data) ? dateTransformer(data) : dateTransformer(data.data);
        const datedChartData = [];
        const date = startDay ? new Date(startDay) : new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const datesArray = getDaysArray(firstDay, lastDay);


        datesArray.forEach(element => {
            let yValue = 0;
            let found = 0;
            const elementDate = `${element.getDate()}-${element.getMonth()}-${element.getFullYear()}`;

            if (type === 'goal') {
                //foreach element in data check if date is equal to elementDate
                // console.log('data', data);
                data.forEach(el => {
                    // format el.date to be the same as elementDate
                    const elDate = new Date(el.date);
                    const elDateFormatted = `${elDate.getDate()}-${elDate.getMonth()}-${elDate.getFullYear()}`;
                    if (elDateFormatted === elementDate) {
                        // console.log('elDateFormatted', elDateFormatted);
                        // console.log('elementDate', elementDate);
                        yValue++;
                        found++;
                    }
                });
            }

            if (type === 'usage') {
                data.forEach((el, index) => {
                    if (el.usage) {
                        const usageDate = el.date.split('-');
                        usageDate[1] = parseInt(usageDate[1]) - 1;

                        if (elementDate === usageDate.join('-')) {
                            yValue += el.usage;
                            found++;
                        }
                    } else {
                        if (elementDate === el.date) {
                            yValue += el.averageValue;
                            found++;
                        }
                    }
                });
            } else if (type !== 'goal') {
                let dataIndex = -1;

                if (type === 'survey') {
                    dataIndex = data.findIndex(e => `${new Date(e.date).getDate()}-${new Date(e.date).getMonth()}-${new Date(e.date).getFullYear()}` === elementDate);
                }
                else if (Array.isArray(data)) {
                    dataIndex = data.findIndex(e => e.date === elementDate);
                } else {
                    dataIndex = data.data.findIndex(e => `${new Date(e.date).getDate()}-${new Date(e.date).getMonth()}-${new Date(e.date).getFullYear()}` === elementDate);
                }

                if (dataIndex !== -1) {
                    if (type === 'moods') {
                        yValue = dateUsableData[dataIndex].score ?? dateUsableData[dataIndex].averageValue;

                    } else if (type === 'survey') {
                        yValue++;
                    } else {
                        if (type === 'steps' || type === 'kms') {
                            // console.log('activity')
                            if (type === 'steps') {
                                yValue += dateUsableData[dataIndex].averageValue / 0.0008;
                            } else {
                                yValue += dateUsableData[dataIndex].averageValue;
                            }
                            found++;
                        } else {

                            if (type === 'adherence') {
                                if (dateUsableData[dataIndex].rate !== null) {
                                    yValue += dateUsableData[dataIndex].rate * 100;
                                } else {
                                    yValue = dateUsableData[dataIndex].rate;
                                }
                            } else {

                                yValue = dateUsableData[dataIndex].averageValue ?? dateUsableData[dataIndex].rate * 100 ?? 0;
                            }

                        }

                    }
                } else {
                    if (type === "moods" || type === "adherence") {
                        yValue = null;
                    }
                }
            }

            if (found > 1 && type !== 'goal') {
                yValue = yValue / found;
            }

            // if (type === 'goal' && yValue > 0) {
            //     console.log('datedChartData', yValue);
            //     console.log('datedChartData', element.getDate());
            // }

            datedChartData.push({
                x: element.getDate(),
                y: yValue,
                // y:0
            });
        });


        return datedChartData;
    } else {
        return [];
    }


};

const dateTransformer = (data) => {
    const transformedElements = [];
    data.forEach(el => {
        const date = new Date(el.date);

        if (dateIsValid(date)) {
            transformedElements.push({ ...el, date: `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}` });
        } else {
            transformedElements.push({ ...el, date: el.date });
        }

    });

    return transformedElements;
};

const getDaysArray = (start, end) => {
    for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
    }
    return arr;
};

function dateIsValid(date) {
    return date instanceof Date && !isNaN(date);
}


export default getChartData;
import { atom } from "jotai";

type patientMood = {
  patientId: string;
  aggregateMood: number;
  moodHistory: number[];
};

export const patientMoodsAtom = atom<patientMood[]>([]);

export const patientIdsUnderTargetAtom = atom<string[]>(get => {
  const patientMoods = get(patientMoodsAtom);
  const ids = [];
  for (const patient of patientMoods) {
    if (patient.aggregateMood < 3) {
      ids.push(patient.patientId);
    }
  }
  return ids;
});


// add patient mood if element with patientId does not exist else overwrite its values
export const addPatientMoodAtom = atom(
  null,
  (get, set, patientMood: patientMood) => {
    const patientMoods = get(patientMoodsAtom);
    const patientMoodIndex = patientMoods.findIndex((mood) => mood.patientId === patientMood.patientId);
    if (patientMoodIndex === -1) {
      set(patientMoodsAtom, [...patientMoods, patientMood]);
    } else {
      patientMoods[patientMoodIndex] = patientMood;
      set(patientMoodsAtom, [...patientMoods]);
    }
  }
);

// get the aggregate mood of a patient if patient exists else return ND
export const getAggregateMoodAtom = atom(
  (get) => (patientId: string) => {
    const patientMoods = get(patientMoodsAtom);
    const patientMood = patientMoods.find((mood) => mood.patientId === patientId);
    if (patientMood) {
      return patientMood.aggregateMood;
    } else {
      return "ND";
    }
  });

import { ResponsiveLine } from '@nivo/line';
import { useEffect } from 'react';

const LineChart = ({ data, setID, passedID, relative, bottomAxisHidden }) => {
    const chartData = [
        {
            "id": "Aderenza",
            "color": "hsl(0, 100%, 50%)",
            "data": [
                {
                    "x": "Gen",
                    "y": 20
                },
                {
                    "x": "Feb",
                    "y": 57
                },
                {
                    "x": "Mar",
                    "y": 32
                },
                {
                    "x": "Apr",
                    "y": 70
                },
                {
                    "x": "Mag",
                    "y": 75
                },
                {
                    "x": "Giu",
                    "y": 77
                },
                {
                    "x": "Lug",
                    "y": 51
                },
                {
                    "x": "Ago",
                    "y": 42
                },
                {
                    "x": "Set",
                    "y": 80
                },
                {
                    "x": "Ott",
                    "y": 88
                },
                {
                    "x": "Nov",
                    "y": 88
                },
                {
                    "x": "Dec",
                    "y": 70
                }
            ]
        },
        {
            "id": "Uso App",
            "color": "hsl(0, 0%, 0%)",
            "data": [
                {
                    "x": "Gen",
                    "y": 5
                },
                {
                    "x": "Feb",
                    "y": 6
                },
                {
                    "x": "Mar",
                    "y": 4.5
                },
                {
                    "x": "Apr",
                    "y": 6
                },
                {
                    "x": "Mag",
                    "y": 6
                },
                {
                    "x": "Giu",
                    "y": 6
                },
                {
                    "x": "Lug",
                    "y": 6
                },
                {
                    "x": "Ago",
                    "y": 6
                },
                {
                    "x": "Set",
                    "y": 5
                },
                {
                    "x": "Ott",
                    "y": 3
                },
                {
                    "x": "Nov",
                    "y": 2
                },
                {
                    "x": "Dec",
                    "y": 1
                }
            ]
        },

        {
            "id": "Traquardi",

            "data": [
                {
                    "x": "Gen",
                    "y": 13
                },
                {
                    "x": "Feb",
                    "y": 15
                },
                {
                    "x": "Mar",
                    "y": 13
                },
                {
                    "x": "Apr",
                    "y": 11
                },
                {
                    "x": "Mag",
                    "y": 15
                },
                {
                    "x": "Giu",
                    "y": 14
                },
                {
                    "x": "Lug",
                    "y": 15
                },
                {
                    "x": "Ago",
                    "y": 14
                },
                {
                    "x": "Set",
                    "y": 12
                },
                {
                    "x": "Ott",
                    "y": 15
                },
                {
                    "x": "Nov",
                    "y": 14
                },
                {
                    "x": "Dec",
                    "y": 15
                }
            ]
        },
        {
            "id": "Umore",
            "color": "hsl(158, 56%, 43%)",
            "data": [
                {
                    "x": "Gen",
                    "y": 3
                },
                {
                    "x": "Feb",
                    "y": 5
                },
                {
                    "x": "Mar",
                    "y": 3
                },
                {
                    "x": "Apr",
                    "y": 1
                },
                {
                    "x": "Mag",
                    "y": 5
                },
                {
                    "x": "Giu",
                    "y": 4
                },
                {
                    "x": "Lug",
                    "y": 5
                },
                {
                    "x": "Ago",
                    "y": 4
                },
                {
                    "x": "Set",
                    "y": 3.2
                },
                {
                    "x": "Ott",
                    "y": 5
                },
                {
                    "x": "Nov",
                    "y": 4
                },
                {
                    "x": "Dec",
                    "y": 5
                }
            ]
        },
    ];

    // useEffect(() => {
    //     console.log('lineChartData', data);
    // }, [data]);


    const DashedSolidLine = ({ series, lineGenerator, xScale, yScale }) => {
        return series.map(({ id, data, color }, index) => (
            <path
                key={id}
                d={lineGenerator(
                    data.map((d) => ({
                        x: xScale(d.data.x),
                        y: yScale(d.data.y !== null ? d.data.y : 0)
                    }))
                )}
                fill="none"
                stroke={color}
                style={
                    index % 2 === 0
                        ? {
                            // simulate line will dash stroke when index is even
                            strokeDasharray: "3, 6",
                            strokeWidth: 3
                        }
                        : {
                            // simulate line with solid stroke
                            strokeWidth: 1
                        }
                }
            />
        ));
    };





    return (
        <div className={`regular-chart ${relative ? 'relative' : ''} ${setID === passedID ? 'chart-active' : ''}`}>

            {setID !== passedID ?
                <ResponsiveLine
                    data={data ? data : chartData}
                    margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', min: 0, max: 100, stacked: true, reverse: false }}
                    yFormat=" >-.2f"
                    curve="catmullRom"
                    axisTop={null}
                    axisRight={null}
                    axisLeft={null}
                    layers={[
                        // includes all default layers
                        "grid",
                        "markers",
                        "axes",
                        "areas",
                        "crosshair",
                        "line",
                        "slices",
                        "points",
                        "mesh",
                        "legends",
                        DashedSolidLine // add the custome layer here
                    ]}
                    axisBottom={!bottomAxisHidden
                        ? {
                            orient: 'bottom',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Giorni',
                            legendOffset: 36,
                            legendPosition: 'middle'
                        } : { tickValues: [] }}

                    pointSize={10}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    colors={{ datum: 'color' }}
                />
                :
                <ResponsiveLine
                    data={data ? data : chartData}
                    margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', min: 0, max: 100, stacked: true, reverse: false }}
                    yFormat=" >-.2f"
                    curve="catmullRom"
                    axisTop={null}
                    axisRight={null}
                    axisLeft={null}
                    lineWidth={4}
                    axisBottom={!bottomAxisHidden
                        ? {
                            orient: 'bottom',
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Giorni',
                            legendOffset: 36,
                            legendPosition: 'middle'
                        } : { tickValues: [] }}

                    pointSize={10}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    enableSlices="x"
                    useMesh={true}
                    colors={{ datum: 'color' }}
                />}
        </div>
    );
};



export default LineChart;
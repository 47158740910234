import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ageFilter, genderFilter } from '../store/userStore';
import getGenderToDisplay from '../utils/getGenderToDisplay';

export default function PageFilters({ currentPathology, pathologyFilterHandler }) {
  const dispatch = useDispatch();
  const patientsPathologies = useSelector((state) => state.user.patientsPathologies);
  const genders = useSelector(state => state.user.genders);
  const currentGender = useSelector(state => state.user.genderFilter);
  const currentAge = useSelector(state => state.user.ageFilter);
  const [showPathologySelect, setShowPathologySelect] = useState(false);
  const [showGenderSelect, setShowGenderSelect] = useState(false);
  const [showAgeSelect, setShowAgeSelect] = useState(false);
  const pathologyDiv = useRef(null);
  const pathologyOptions = useRef(null);
  const genderDiv = useRef(null);
  const genderDivOptions = useRef(null);
  const ageDiv = useRef(null);
  const ageDivOptions = useRef(null);

  // TODO: DO A PROPER COMPONENT PAGLIO

  useEffect(() => {
    if (pathologyDiv.current && pathologyOptions.current) {
      pathologyDiv.current.style.width = pathologyOptions.current.getBoundingClientRect().width + 'px';
    }

  }, [pathologyDiv]);

  useEffect(() => {
    if (genderDiv.current && genderDivOptions.current) {
      if (genderDiv.current.getBoundingClientRect().width < genderDivOptions.current.getBoundingClientRect().width) {
        genderDiv.current.style.width = genderDivOptions.current.getBoundingClientRect().width + 'px';
      } else {
        genderDivOptions.current.style.width = genderDiv.current.getBoundingClientRect().width + 'px';
      }
    }

  }, [genderDiv]);

  useEffect(() => {
    if (ageDiv.current && ageDivOptions.current) {
      if (ageDiv.current.getBoundingClientRect().width < ageDivOptions.current.getBoundingClientRect().width) {
        ageDiv.current.style.width = ageDivOptions.current.getBoundingClientRect().width + 'px';
      } else {
        ageDivOptions.current.style.width = ageDiv.current.getBoundingClientRect().width + 'px';
      }
    }

  }, [ageDiv]);

  useEffect(() => {
  }, [currentGender]);


  function genderFilterHandler(gender) {
    dispatch(genderFilter({ gender: gender }));
  }

  function ageFilterHandler(age) {
    setShowAgeSelect(false);
    dispatch(ageFilter({ age: age }));
  }

  function toggleFilterHandler(filterToToggle) {

    switch (filterToToggle) {
      case 'pathology':
        setShowPathologySelect(!showPathologySelect);
        setShowAgeSelect(false);
        setShowGenderSelect(false);
        break;
      case 'gender':
        setShowGenderSelect(!showGenderSelect);
        setShowAgeSelect(false);
        setShowPathologySelect(false);
        break;
      default:
        setShowAgeSelect(!showAgeSelect);
        setShowPathologySelect(false);
        setShowGenderSelect(false);
        break;
    }

  }

  return (
    <React.Fragment>
      <div className="select-group">
        <div className={`fake-select ${showPathologySelect ? 'open' : 'close'}`} ref={pathologyDiv} onClick={() => toggleFilterHandler('pathology')}>
          Diagnosi: {currentPathology}
          <span className="select-arrow"></span>

          <ul className="fake-options" style={{ visibility: showPathologySelect ? 'visible' : 'hidden' }} ref={pathologyOptions}>
            <li onClick={() => pathologyFilterHandler('Tutte')}>Tutte</li>
            {patientsPathologies ?
              patientsPathologies.map((pathology, index) => <li key={`pathologyList-${index}`} onClick={() => pathologyFilterHandler(pathology[0])}>{pathology[0]} <span className='filter-number'>{`(${pathology[1]})`}</span></li>)
              : null}
          </ul>
        </div>
      </div>
      <div className="select-group">
        <div className={`fake-select ${showGenderSelect ? 'open' : 'close'}`} ref={genderDiv} onClick={() => toggleFilterHandler('gender')}>
          Genere: {getGenderToDisplay(currentGender)}
          <span className="select-arrow"></span>
          <ul className="fake-options" style={{ visibility: showGenderSelect ? 'visible' : 'hidden' }} ref={genderDivOptions}>
            <li onClick={() => genderFilterHandler(null)}>Tutti </li>
            <li onClick={() => genderFilterHandler('m')}>Uomo <span className='filter-number'>{`(${genders.male})`}</span></li>
            <li onClick={() => genderFilterHandler('f')}>Donna <span className='filter-number'>{`(${genders.female})`}</span></li>
            <li onClick={() => genderFilterHandler('o')}>Altro <span className='filter-number'>{`(${genders.other})`}</span></li>
          </ul>
        </div>
      </div>
      <div className='select-group'>
        <div className={`fake-select ${showAgeSelect ? 'open' : 'close'}`} ref={ageDiv} onClick={() => toggleFilterHandler('age')}>
          Età: {currentAge ?? 'Tutte'}
          <span className="select-arrow"></span>
          <ul className="fake-options" style={{ visibility: showAgeSelect ? 'visible' : 'hidden' }} ref={ageDivOptions}>
            <li onClick={() => ageFilterHandler(null)}>Tutte</li>
            <li onClick={() => ageFilterHandler('18-30')}>18-30</li>
            <li onClick={() => ageFilterHandler('31-40')}>31-40</li>
            <li onClick={() => ageFilterHandler('41-50')}>41-50</li>
            <li onClick={() => ageFilterHandler('51-60')}>51-60</li>
            <li onClick={() => ageFilterHandler('61-70')}>61-70</li>
            <li onClick={() => ageFilterHandler('71-120')}>70+</li>
          </ul>
        </div>




      </div>
    </React.Fragment>
  );
}

import axios from "axios";
import { atom } from "jotai";
import getCallUrl from "../utils/callUrlUtil";
import { calendarStartDateAtom, calendarEndDateAtom, calendarPatientIdAtom } from "./calendarWeeklyEventsAtom";
import { userTokenAtom } from "./userInfoAtom";

export type ActivitiesObject = {
  id: string;
  activities: any[];
  steps: number;
};

export const getCalendarWeeklyActivities = atom(
  async (get) => {
    const startDate = get(calendarStartDateAtom);
    const endDate = get(calendarEndDateAtom);
    const callUrl = getCallUrl();
    const userToken = get(userTokenAtom);
    const patientId = get(calendarPatientIdAtom);


    const response = await axios.get(`${callUrl}/api/activity/${patientId}/list/${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}/${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`, {
      headers: { 'X-AUTH-TOKEN': userToken },
    })
      .then(response => {

        return response.data;
      })
      .catch(error => {
        console.log(error);
      });

    if (response) {
      const responseArray = response.data.daily_activities;
      let totalSteps = 0;

      responseArray.forEach((element: any) => {
        totalSteps += element.total_steps;
      });

      return {
        id: patientId,
        activities: responseArray,
        steps: totalSteps
      };


    } else {
      return null;
    }

  }
);
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import isDev from '../../hooks/isDev';
import { sendMessage } from '../../store/messagesStore';
import { acceptUserPatient } from '../../store/userStore';
import getCallUrl from '../../utils/callUrlUtil';
import PatientInfo from '../patient/patientInfo'
import SimpleModal from '../simpleModal'

export default function PatientsConnectionModal({ visible, toggleModal }) {
  const dispatch = useDispatch();
  const patientsPending = useSelector((state) => state.user.patientsPending);
  const userToken = useSelector((state) => state.user.authToken);
  let callUrl = getCallUrl();

  const rejectUser = (id) => {
    axios.post(`${callUrl}/api/medics/pending-patients/${id}/remove`, null, {
      headers: { 'X-AUTH-TOKEN': userToken },
    })
      .then(response => {
        if (response.data.status !== 'error') {
          dispatch(acceptUserPatient({ id: id, accept: false }));
          dispatch(sendMessage({
            message: 'Utente rifiutato',
            positive: true,
          }))
        } else {
          dispatch(sendMessage({
            message: 'impossibile rifiutare l\'utente',
            positive: false,
          }))
        }
      })
      .catch(error => dispatch(sendMessage({
        message: 'Impossibile rifiutare l\'utente: ' + error,
        positive: false,
      }))
      );
  }

  const acceptUser = (id) => {
    axios.post(`${callUrl}/api/medics/pending-patients/${id}/accept`, null, {
      headers: { 'X-AUTH-TOKEN': userToken },
    })
      .then(response => {
        if (response.data.status !== 'error') {
          dispatch(acceptUserPatient({ id: id, accept: true }));
          dispatch(sendMessage({
            message: 'Utente accettato',
            positive: true,
          }))
        } else {
          dispatch(sendMessage({
            message: 'impossibile accettare l\'utente',
            positive: false,
          }))
        }
      })
      .catch(error => dispatch(sendMessage({
        message: 'Impossibile accettare l\'utente: ' + error,
        positive: false,
      }))
      );
  }

  return (
    <SimpleModal visible={visible} toggleModal={toggleModal}>
      {patientsPending ? patientsPending.map((element, index) => (
        <div key={`pp-${index}`} className="accept-wrapper">
          <PatientInfo object={element.patient} />
          <div className="buttons">
            <button onClick={() => acceptUser(element.id)} className="button big accept">
              <FontAwesomeIcon icon="calendar-check" />
              Conferma
            </button>
            <button onClick={() => rejectUser(element.id)} className="button big deny">
              <FontAwesomeIcon icon="calendar-times" />
              Rifiuta
            </button>
          </div>
        </div>
      )) : null}
    </SimpleModal>
  )
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activites: [],
    patientActivities: [],
    medianKms: 0,
    medianSteps: 0,
    medianPatientSteps: 0,
    decreasingActivities: [],
    dayStepsChartData: [],

};
// {
//     "id": 2,
//     "date": "2021-08-01T00:00:00+02:00",
//     "activity": [
//       {
//         "time": "2021-08-1T09:19:00+02:00",
//         "steps": 5000,
//         "distance": 4
//       }
//     ],
//     "total_steps": 5000,
//     "total_distance": 4
//   },
export const activitiesSlice = createSlice({
    name: 'activites',
    initialState,
    reducers: {
        loadActivites: (state, action) => {
            const activities = state.activites;
            let medianSteps = 0;
            let medianKms = 0;
            if (action.payload.activites) {
                let steps = 0;

                action.payload.activites.forEach(act => {
                    steps += act.total_steps;
                })

                activities.push({
                    id: action.payload.id,
                    activities: action.payload.activites,
                    steps: steps,
                })
            }

            // gets current stepsChartData
            const newStepsChartData = state.dayStepsChartData;

            activities.forEach(activity => {
                activity.activities.forEach(element => {
                    // add activity total steps to median steps
                    medianSteps += element.total_steps;
                    medianKms += element.distance;

                    //gets inner activity date
                    const elementDate = new Date(element.date);

                    // finds index of date in newStepsChartData
                    const dateIndex = newStepsChartData.findIndex(e => e.date === elementDate);

                    //if dateIndex === -1, add new data else updates data for that given index

                    if (dateIndex !== -1) {
                        newStepsChartData[dateIndex].averageValue = newStepsChartData[dateIndex].averageValue + activity.totalDistance;
                    } else {
                        newStepsChartData.push({
                            date: `${elementDate.getDate()}-${elementDate.getMonth()}-${elementDate.getFullYear()}`,
                            averageValue: element.total_distance,
                        })
                    }

                })
            })

            state.medianSteps = medianSteps > 0 ? Math.round(medianSteps / activities.length) : 0;
            state.medianKms = medianKms > 0 ? Math.round(medianKms / activities.length) : 0;

            state.activites = activities;
            state.dayStepsChartData = newStepsChartData;
        },
        loadPastSteps: (state, action) => {
            if (action.payload.activites) {
                let steps = 0;


                action.payload.activites.forEach(act => {
                    steps += act.total_step;
                })

                const currentMonthSteps = state.activites.find(el => el.id === action.payload.id)?.steps;

                if(currentMonthSteps && currentMonthSteps < steps){
                    state.decreasingActivities.push(action.payload.id)
                }

            }

    
        },
        resetActivities: (state) => {
            state.activites.length = 0;
            state.patientActivities.length = 0;
            state.medianKms = 0;
            state.medianSteps = 0;
            state.medianPatientSteps = 0;
            state.decreasingActivities.length = 0;
            state.dayStepsChartData.length = 0;
        }
    }

});

export const { loadActivites, loadPastSteps, resetActivities } = activitiesSlice.actions

export default activitiesSlice.reducer;
import React from 'react'

import Clock from '../assets/images/fa-icons/clock.svg';

const InfoBox = props => {

    return(
        <div className="rounded-box info-box">
            <div className="info-title">Prossimo Appuntamento</div>
            <div className="info-wrapper">
                <div className="avatar"></div>
                <div className="user-info">
                    <div className="user-name">{props.name}</div>
                    <div className="user-diagnosis">{props.diagnosis ? `Diagnosi: ${props.diagnosis}` : null}</div>
                </div>
            </div>
            <hr />
            <div className="footer">
                <div className="event-title">{props.eventTitle}</div>
                <div className="event-time">{props.eventTiming} <img src={Clock} className="icon-img" icon="clock" /></div>
            </div>
        </div>  
    )
}

export default InfoBox;
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import PatientRow from '../../components/patient/patientRow';

export default function GoalsFiltered({ patients, patientClick }) {
  const goals =  useSelector((state) => state.goals.goals)
  const [hasUncompletedGoals, setHasUncompletedGoals] = React.useState([]);


    useEffect(() => {
        const hasUncompleted = [];

        goals.forEach(el => {
            el.goalsArray.forEach(goal => {
                if (!goal.completed) {
                    hasUncompleted.push(el.id)
                }
            })
        })

        setHasUncompletedGoals(hasUncompleted)
    }, [goals])

    useEffect(() => {
    }, [hasUncompletedGoals])

    return (
      patients.filter(element => hasUncompletedGoals.includes(element.patient.id)).length > 0 ? patients.filter(element => hasUncompletedGoals.includes(element.patient.id)).map(item => {
            /* if user is accepted*/
            if (item.status === 'accepted') {
                return (
                    <div key={item.patient.id} className="col-12 col-lg-6 col-xl-12">
                        <PatientRow
                            patientClick={() => patientClick(item.patient.id)}
                            patient={item.patient}
                            appointment={null}
                        />
                    </div>
                )
            }
        })
            : < h3 > Nessun paziente presente</h3 >
    )
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    modalVisible: false,
    modalContent: null,
    modalTitle: null,
};

export const calendarEventsSlice = createSlice({
    name: 'calendarEvents',
    initialState,
    reducers: {
        clickedEvent: (state, action) => {
            const modalVisble = state.modalVisible;
            state.modalVisible = !modalVisble;
        },
        closeEventModal: (state) => {
            state.modalVisible = false;
        },
        openEventModal: (state, action) => {
            action.payload.content ? state.modalContent = action.payload.content : state.modalContent = null;
            action.payload.title ? state.modalTitle = action.payload.title : state.modalTitle = null;
            state.modalVisible = true;
        }
    },

})

export const { clickedEvent, closeEventModal, openEventModal } = calendarEventsSlice.actions

export default calendarEventsSlice.reducer;
const SimpleModal = props => {

    return(
        <div className={`modal-wrapper ${props.visible ? 'visible' : ''}`}>
            <div className="rounded-box bg text-area-modal">
                {props.children}
            </div>
        </div>
    )
}

export default SimpleModal;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    surveys: [],
    plainSurveys: [],
    allSurveysChartData: [],
    singleSurveyChartData: [],
    filterTemp: [],
    totalSurveys: 0,
    surveysDone: [],
};

export const surveysSlice = createSlice({
    name: 'surveys',
    initialState,
    reducers: {
        addSurveys: (state, action) => {
            const surveys = state.surveys;
            const plainSurveys = state.plainSurveys;


            action.payload.surveyArray.forEach(element => {
                plainSurveys.push(element);


            });
            surveys.push({
                id: action.payload.patientID,
                surveyArray: action.payload.surveyArray,
            })

            state.surveys = surveys;
            state.plainSurveys = plainSurveys

        },
        getSurveyTotal: (state, action) => {
            const plainSurveys = state.plainSurveys;
            const surveysChartData = state.allSurveysChartData;
            const surveys = state.surveys;


            let total = 0;
            const date = new Date();
            const firstDay = action.payload.startDate ? action.payload.startDate : new Date(date.getFullYear(), date.getMonth(), 1);
            const lastDay = action.payload.endDate ? action.payload.endDate : new Date(date.getFullYear(), date.getMonth() + 1, 0);
            const daysArray = getDaysArray(firstDay, lastDay);
            const doneArray = [];

            daysArray.forEach(day => {
                const dayDate = `${day.getDate()}-${day.getMonth()}-${day.getFullYear()}`;

                surveys.forEach(survey => {
                    survey.surveyArray.forEach(element => {
                        const surveyDate = new Date(element.date);
                        const convertedDate = `${surveyDate.getDate()}-${surveyDate.getMonth()}-${surveyDate.getFullYear()}`;
                        if (dayDate === convertedDate) {
                            doneArray.push(survey.patientID);
                        }

                    })
                })
                plainSurveys.forEach(survey => {
                    const surveyDate = new Date(survey.date);
                    const convertedDate = `${surveyDate.getDate()}-${surveyDate.getMonth()}-${surveyDate.getFullYear()}`;

                    if (dayDate === convertedDate) {
                        total++;

                        const surveysChartIndex = surveysChartData.findIndex(e => e.date === convertedDate);

                        if (surveysChartIndex !== -1) {
                            surveysChartData[surveysChartIndex].averageValue = surveysChartData[surveysChartIndex].averageValue + 1;
                        } else {
                            surveysChartData.push({
                                date: convertedDate,
                                averageValue: 1,
                            })
                        }
                    }
                })
            });

            state.allSurveysChartData = surveysChartData;
            state.totalSurveys = total;
        },
        getSingleChart: (state, action) => {
            const date = new Date();
            const firstDay = action.payload.startDate ? action.payload.startDate : new Date(date.getFullYear(), date.getMonth(), 1);
            const lastDay = action.payload.endDate ? action.payload.endDate : new Date(date.getFullYear(), date.getMonth() + 1, 0);
            const daysArray = getDaysArray(firstDay, lastDay);
            const filterSurveys = state.surveys.filter(e => e.id === action.payload.id);
            const surveysChartData = [];
            state.filterTemp = filterSurveys;

            daysArray.forEach(day => {
                const dayDate = `${day.getDate()}-${day.getMonth()}-${day.getFullYear()}`;

                filterSurveys.forEach(survey => {
                    survey.surveyArray.forEach(element => {

                        const elementDate = new Date(element.date)
                        const convertedDate = `${elementDate.getDate()}-${elementDate.getMonth()}-${elementDate.getFullYear()}`;

                        if (dayDate === convertedDate) {

                            const surveysChartIndex = surveysChartData.findIndex(e => e.date === convertedDate);

                            if (surveysChartIndex !== -1) {
                                surveysChartData[surveysChartIndex].averageValue = surveysChartData[surveysChartIndex].averageValue + 1;
                            } else {
                                surveysChartData.push({
                                    date: convertedDate,
                                    averageValue: 1,
                                    answers: element.answers
                                })
                            }
                        }
                    })
                })

            });

            state.singleSurveyChartData = surveysChartData;

        }
    }
})

const getDaysArray = (start, end) => {
    for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
    }
    return arr;
}

export const { addSurveys, getSurveyTotal, getSingleChart } = surveysSlice.actions;


export default surveysSlice.reducer;


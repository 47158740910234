import React, { useEffect, useState } from 'react';
import axios from 'axios';
import getCallUrl from '../utils/callUrlUtil';
import { useSelector } from 'react-redux';

type Props = {
  drugID: string;
  userToken: string;
  patientID: string | string[];
};

export default function AdherenceRateComponent(props: Props) {
  const [rate, setRate] = useState(0);
  const startDate = useSelector((state: any) => state.user.startDate);
  let callUrl = getCallUrl();

  useEffect(() => {

    const date = new Date(startDate);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const patientString = Array.isArray(props.patientID) ? props.patientID.join(',') : props.patientID;

    if (Array.isArray(props.patientID) && props.patientID.length === 0) {
      setRate(0);
      return;
    }

    axios.get(`${callUrl}/api/patient-drugs/therapeutic-adherences-stats/${date.getFullYear()}-${date.getMonth() + 1}-01/${date.getFullYear()}-${date.getMonth() + 1}-${lastDay.getDate()}/false?patientDrug=${props.drugID}&patients=${patientString}`, {
      headers: { 'X-AUTH-TOKEN': props.userToken },
    })
      .then(response => {
        // console.log('inner adherence response', response.data);
        // console.log('inner adherence call', `${callUrl}/api/patient-drugs/therapeutic-adherences-stats/${date.getFullYear()}-${date.getMonth() + 1}-01/${date.getFullYear()}-${date.getMonth() + 1}-${lastDay.getDate()}/false?patient=${props.patientID}&patientDrug=${props.drugID}`);
        //@ts-ignore
        if (response.data.data.rate * 100 !== isNaN()) {
          setRate(response.data.data.rate * 100);
        }
      })
      .catch(error => console.log(error));
  }, [props, startDate]);


  return (
    <span>{Math.round(rate)}%</span>
  );
}

import React, { useEffect, useState } from 'react'
import ReactExport from "react-export-excel";
import { useSelector } from 'react-redux';
import CalendarPlusW from '../assets/images/fa-icons/calendar-plus-w.svg';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ExportComponent({ data, active }) {
  const date = new Date(useSelector(state => state.user.startDate));
  const [dataArray, setDataArray] = useState([]);

  useEffect(() => {
    const newData = [];

    data[0].forEach((element, index) => {
      newData.push(
        {
          x:index + 1,
          use: element.y,
          adherence: data[1][index].y,
          mood: data[2][index].y,
          goals: data[3][index].y,
          surveys: data[4][index].y,
        }
      )
    })


    setDataArray(newData);


  }, [data])

  return (
    <React.Fragment>
      <ExcelFile element={<button className="button secondary flexed"><img className="icon-img mr-2" src={CalendarPlusW} /> <span>Download Excel</span></button>}>
        <ExcelSheet data={dataArray} name="UsoApp">
          <ExcelColumn label="Giorno" value={(col) => `${date.getFullYear()}-${date.getMonth() + 1}-${col.x}`} />
          <ExcelColumn label="Percentuale Utilizzo" value={(col) => Math.round(col.use)} />
          <ExcelColumn label="Aderenza Terapeutica" value={(col) => Math.round(col.adherence)} />
          <ExcelColumn label="Umore" value={(col) => Math.round(col.mood)} />
          <ExcelColumn label="Traguardi Raggiunti" value={(col) => Math.round(col.goals)} />
          <ExcelColumn label="Sondaggi Completati" value={(col) => Math.round(col.surveys)} />
        </ExcelSheet>
      </ExcelFile>
    </React.Fragment>
  )
}

const SimpleModal = props => {

    return(
        <div className={`modal-wrapper ${props.visible ? 'visible' : ''}`}>
           {!props.forced ?  <div className="modal-bg" onClick={props.toggleModal}></div> : null}
            <div className="rounded-box tall bg" style={{maxHeight:'95vh'}}>
                <div className={`modal-header ${props.img ? 'with-img' : ''}`}>
                    {props.img ? <img src={props.img} /> : null}
                    <p className="modal-title">{props.modalTitle ? props.modalTitle : ''}</p>
                    {!props.forced ? <button className={`modal-close ${props.img ? 'absolute' : ''}`} onClick={props.toggleModal}>X</button>:null}
                </div>
                <div className="modal-content">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default SimpleModal;
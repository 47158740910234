import React, { useState } from 'react';
import NewsCard from './newsCard';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Pagination({ data, pageLimit, dataLimit }) {
    const [pages] = useState(data.length / dataLimit > 1 && data.length / dataLimit < 2 ? 2 : Math.round(data.length / dataLimit));
    const [currentPage, setCurrentPage] = useState(1);

    function goToNextPage() {
        setCurrentPage((page) => page + 1);
    }

    function goToPreviousPage() {
        setCurrentPage((page) => page - 1);
    }

    function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        if(pageNumber <= pages){

            setCurrentPage(pageNumber);
        }
    }

    const getPaginatedData = () => {
        const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return data.slice(startIndex, endIndex);
    };

    const getPaginationGroup = () => {
        let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
        return new Array(pages).fill().map((_, idx) => start + idx + 1);
    };

    return (
        <React.Fragment>

            {getPaginatedData().map((element, idx) => element)}

            {pages > 1 ?

        <div className="row">
            <div className="col-12">
                <div className="pagination">
                    {/* previous button */}
                    <button
                        onClick={goToPreviousPage}
                        className={`prev ${currentPage === 1 ? 'disabled' : ''}`}
                    >
                        <FontAwesomeIcon icon="chevron-left" />
                    </button>

                    {/* show page numbers */}
                    {getPaginationGroup().map((item, index) => (
                        <button
                        key={index}
                        onClick={changePage}
                        className={`paginationItem ${currentPage === item ? 'active' : null}`}
                        >
                        <span>{item}</span>
                        </button>
                    ))}

                    {/* next button */}
                    <button
                        onClick={goToNextPage}
                        className={`next ${currentPage === pages ? 'disabled' : ''}`}
                    >
                        <FontAwesomeIcon icon="chevron-right" />
                    </button>
                </div>
            </div>
        </div> : null }
      </React.Fragment>
    );
  }

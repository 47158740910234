import React from 'react';


const DisplayEvent = (props) => {
    let total = null;

    const eventClickHandler = () => {
        // console.log('inner event clicked', props.event.data);
        if (total !== null || (props.event.data.umore.survey && props.event.data.umore.survey.length > 0)) {
            props.event.eventClicked(props.event)
        }
    }


    return (
        <React.Fragment >
            <span className="display-event" onClick={props.event.eventClicked ? () => eventClickHandler() : null}>
                {Object.keys(props.event.data).map((key, index) => {
                    let eventTotal = null;
                    if (key === props.event.selected) {
                        if (props.event.data[key].total) {
                            total = props.event.data[key].total;
                        }


                        return props.event.data[key].total;
                    }

                    return eventTotal;
                })}
                <br />
                {Object.keys(props.event.data).map((key, index) => {
                    if (key === props.event.selected) {
                        if (props.event.data[key].extra) {
                            return props.event.data[key].extra
                        } else {
                            return null
                        }
                    }

                    return null;
                })}
            </span>
        </React.Fragment>
    )
}

export default DisplayEvent;
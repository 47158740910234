import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  patients: null,
  currentPatient: null,
  currentPatientMoods: null,
  currentPatientAdherence: null,
  moods: [],
  aggregatedMood: null,
  moodsUnderTarget: [],
  calculatedMoods: [],
  dailyMoods: [],
  allAdherences: [],
  patientsAdherences: [],
  adherencesUnderTarget: [],
  totalAdherence: null,
  savedMoods: null,
  savedAdherences: null,
  totalsSaved: false,
  patientsPercentages: [],
};

export const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    loadPatients: (state) => {
      state.patients = [
        {
          id: 1,
          email: "paziente_1@example.com",
          enabled: true,
          firstname: "Maria",
          lastname: "Rossi",
          date_of_birth: "1989-05-26T00:00:00+02:00",
          gender: "f",
          pathologies: [
            {
              id: 188,
              name: "Depressione"
            },
            {
              id: 189,
              name: "Disturbo D'ansia"
            }
          ],
        },
        {
          id: 2,
          email: "paziente_1@example.com",
          enabled: true,
          firstname: "Michele",
          lastname: "Bianchi",
          date_of_birth: "1989-05-26T00:00:00+02:00",
          gender: "m",
          pathologies: [
            {
              id: 188,
              name: "Depressione"
            },
            {
              id: 189,
              name: "Disturbo Bipolare"
            }
          ],
        },
        {
          id: 3,
          email: "paziente_1@example.com",
          enabled: true,
          firstname: "Giorgio",
          lastname: "Passoni",
          date_of_birth: "1989-05-26T00:00:00+02:00",
          gender: "m",
          pathologies: [
            {
              id: 188,
              name: "Depressione"
            },
            {
              id: 189,
              name: "Disturbo Bipolare"
            }
          ],
        },
      ];
    },
    setCurrentPatient: (state, action) => {
      state.currentPatient = action.payload;
    },
    saveMoods: (state, action) => {
      const currentMoodsArray = state.moods;
      let singleUserMood = 0;
      let singleMoodLength = 0;

      currentMoodsArray.push(action.payload);
      state.moods = currentMoodsArray;

      action.payload.moodsArray.forEach(element => {
        singleUserMood += parseFloat(element.rating);
        singleMoodLength++;
      });

      state.calculatedMoods.push({ patientID: action.payload.patientID, mood: (singleUserMood / singleMoodLength) });

    },
    resetMoods: (state) => {
      state.moods.length = 0;
    },
    calculateTotalMood: (state) => {

      let allMoodsData = 0;
      let allMoodsLength = 0;
      let moodsUnder = [];

      state.moods.forEach(element => {
        let singleUserMood = 0;
        let singleMoodLength = 0;

        element.moodsArray.forEach(element => {
          singleUserMood += parseFloat(element.rating);
          singleMoodLength++;
        });

        allMoodsData += (singleUserMood / singleMoodLength);
        allMoodsLength++;
        if (singleUserMood / singleMoodLength < 3) {
          moodsUnder.push(element.patientID);
        }
      });
      state.moodsUnderTarget = moodsUnder;

      state.aggregatedMood = (allMoodsData / allMoodsLength).toFixed(1);
    },
    calculateDailyMoods: (state) => {
      state.moods.forEach(element => {
        element.moodsArray.forEach(mood => {
          const moodDate = new Date(mood.date);
          const convertedDate = `${moodDate.getDate()}-${moodDate.getMonth()}-${moodDate.getFullYear()}`;
          const moodIndex = state.dailyMoods.findIndex(e => e.date === convertedDate);
          if (moodIndex !== -1) {
            const currentMoods = state.dailyMoods[moodIndex].ratings;

            currentMoods.push(mood.rating);

            state.dailyMoods[moodIndex].ratings = currentMoods;
            state.dailyMoods[moodIndex].medianMood = getArrayMedian(currentMoods);

          } else {
            state.dailyMoods.push({
              ratings: [mood.rating],
              date: convertedDate,
              averageValue: mood.rating,
            });
          }

        });
      });
    },
    setSinglePatientMoods: (state, action) => {
      const patientMoods = action.payload.moods.find(element => element.patientID === action.payload.id);
      const newMoodsArray = [];


      if (patientMoods) {
        patientMoods.moodsArray.forEach(mood => {
          const moodDate = new Date(mood.date);
          const convertedDate = `${moodDate.getDate()}-${moodDate.getMonth()}-${moodDate.getFullYear()}`;

          newMoodsArray.push({
            rating: mood.rating,
            date: convertedDate,
            averageValue: mood.rating
          });
        });
        state.currentPatientMoods = newMoodsArray;
      }
    },
    saveAdherences: (state, action) => {
      const newAdherencesArray = [];
      action.payload.forEach(element => {
        const elementDate = new Date(element.date);
        const convertedDate = `${elementDate.getDate()}-${elementDate.getMonth()}-${elementDate.getFullYear()}`;
        newAdherencesArray.push({
          rate: element.rate,
          date: convertedDate,
          averageValue: (element.rate * 100)
        });
      });

      state.allAdherences = newAdherencesArray;

    },
    saveTotalAdherence: (state, action) => {
      state.totalAdherence = (action.payload ?? 0).toFixed(2);
    },
    savePatientsAdherence: (state, action) => {
      const patientsArray = state.patientsAdherences;

      patientsArray.push({
        patientID: action.payload.id,
        adherenceArray: action.payload.array,
        rate: action.payload.rate
      });

      state.patientsAdherences = patientsArray;

      if (action.payload.rate < 0.9) {
        state.adherencesUnderTarget.push(action.payload.id);
      }
    },
    resetAdherenceTarget: (state) => {
      state.adherencesUnderTarget.length = 0;
    },
    setSinglePatientAdherence: (state, action) => {
      const adherences = action.payload.adherences.find(element => element.patientID === action.payload.id);
      const newAdherenceArray = [];


      if (adherences) {
        if (adherences.adherenceArray) {


          adherences.adherenceArray.data.forEach(ad => {
            const adherenceDate = new Date(ad.date);
            const convertedDate = `${adherenceDate.getDate()}-${adherenceDate.getMonth()}-${adherenceDate.getFullYear()}`;

            if (adherenceDate < new Date()) {
              newAdherenceArray.push({
                rate: ad.rate,
                date: convertedDate,
                averageValue: (ad.rate * 100)
              });
            }
          });
          state.currentPatientAdherence = newAdherenceArray;
        }
      }
    },
    savePatientsTotals: (state) => {
      state.patientsPercentages = [];
      if (!state.totalsSaved) {
        state.savedMoods = state.calculatedMoods;
        state.savedAdherences = state.patientsAdherences;
        state.totalsSaved = true;
      }
    },
    resetData: (state) => {
      state.moods = [];
      state.aggregatedMood = null;
      state.calculatedMoods = [];
      state.dailyMoods = [];
      state.allAdherences = [];
      state.patientsAdherences = [];
      state.totalAdherence = null;
    }

  }
});

// function getPercentage(singleData, total) {
//   return (singleData / total) * 100;
// }


/** TODO create hook and use everywhere? */
export function getArrayMedian(array) {
  let arrayTotal = 0;

  array.forEach(element => {
    arrayTotal += parseFloat(element.rating);
  });

  return (arrayTotal / array.length).toFixed(1);
}

export const { loadPatients, saveMoods, calculateTotalMood, calculateDailyMoods, setCurrentPatient, setSinglePatientMoods, saveAdherences, saveTotalAdherence, savePatientsAdherence, setSinglePatientAdherence, resetData, savePatientsTotals, resetAdherenceTarget } = patientsSlice.actions;


export default patientsSlice.reducer;

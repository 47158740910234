import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { loadEvents } from '../store/eventsStore';
import { sendMessage } from '../store/messagesStore';
import PatientInfo from './patient/patientInfo';
import SmallCalendar from 'react-calendar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isDev from '../hooks/isDev';
import getCallUrl from '../utils/callUrlUtil';

const AddAppointment = props => {
    const [patientData, setPatientdata] = useState(props.patientData ?? null);
    const [useError, setError] = useState(null);
    const [patientFilter, setPatientFilter] = useState('');
    const [filteredPatients, setFilteredPatients] = useState([]);
    const [showCalendar, setShowcalendar] = useState(false);
    const [daySelected, setDaySelect] = useState('');
    const [timeSelected, setTimeSelected] = useState('');
    const userToken = useSelector((state) => state.user.authToken);
    const patients = useSelector((state) => state.user.userPatients);
    const dispatch = useDispatch();
    let callUrl = getCallUrl();

    useEffect(() => {
      if(!props.visible && !props.avoidReset){
          resetState();
      }
    }, [props.visible])

    useEffect(() => {

        setPatientdata(props.patientData)
      
    }, [props.patientData])

    useEffect(() => { if (patientFilter !== '') patientFilterHandler();}, [patientFilter])


    function resetState(){
        setPatientFilter('');
        setDaySelect('');
        setTimeSelected('');
        setFilteredPatients([]);
        setPatientdata(null);
    }

    // useEffect(() => {console.log('fpatients', filteredPatients)}, filteredPatients)
    

    const selectPatientHandler = (id) => {
        const patientIndex = patients.findIndex(e => e.patient.id === id);

        if (patientIndex !== -1) {
            setPatientdata(patients[patientIndex].patient)
            setPatientFilter('');
        }

    }

    const filterHandler = (event) => {
        setPatientFilter(event.target.value.toLowerCase());
    }

    const patientFilterHandler = () => {
        setFilteredPatients(patients.filter(item => {
            const name = item.patient.firstname + item.patient.lastname;
            if (name.toLowerCase().includes(patientFilter)) {
                return item;
            }
        }))
        setPatientdata(null);

    }

    const dayClickHandler = (event) => {
        const date = event;
        setDaySelect(date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear());
        setShowcalendar(false);
    }


    const addAppointmentHandler = () => {

        setError(null)
        if (patientData === null) {
            setError('Devi selezionare almeno un paziente');
            return;
        }

        if (daySelected === '') {
            setError('Devi selezionare una data per procedere');
            return;
        }

        if (timeSelected === '') {
            setError('Devi selezionare un orario per procedere');
            return;
        }

        const splitDate = daySelected.split('/');
        const splitTime = timeSelected.split(':');
        const newAppointmentDate = new Date(splitDate[2], splitDate[1] - 1, splitDate[0], splitTime[0], splitTime[1], 0, 0);


        const appointmentObject = {
            "title": "Appuntamento",
            "date": newAppointmentDate,
            "type": "appointment",
            "content": "",
            "taggedUsersData": [
                patientData.id
            ]
        }

        axios.post(`${callUrl}/api/users/calendar-events/add`, appointmentObject, {
            headers: { 'X-AUTH-TOKEN': userToken },
        })
            .then(response => {
                if (response.status === "error") {
                    dispatch(sendMessage({
                        message: 'Impossibile inserire appuntamento',
                        positive: false,
                    }));
                } else {
                    eventLoadingHandler();
                    dispatch(sendMessage({
                        message: 'Appuntamento inserito',
                        positive: true,
                    }));
                    resetState();
                }
            })
            .catch(error => {
                dispatch(sendMessage({
                    message: 'Impossibile inserire appuntamento: ' + error,
                    positive: false,
                }));
            });


    }

    /** Calendar Events Loading, called when new appoitment added */
    const eventLoadingHandler = () => {

        axios.get(`${callUrl}/api/users/calendar-events/list/all`, {
            headers: { 'X-AUTH-TOKEN': userToken },
        })
            .then(response => {
                if (response.data.status === "success") {
                    dispatch(loadEvents(response.data.data));
                } else {
                    dispatch(sendMessage({
                        message: 'Impossibile recuperare dati eventi: ' + response.data.message,
                        positive: false,
                    }))
                }
            })
            .catch(error => dispatch(sendMessage({
                message: 'Impossibile recuperare dati eventi: ' + error,
                positive: false,
            })));
    }

    return (
        <React.Fragment>
            <div className={`row ${useError ? '' : 'd-none'}`}>
                <div className="col-12">
                    <p className="inline-error text-center">{useError ? useError : null}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <input className={`form-control search ${props.patientData ? 'd-none' : ''}`} type="text" placeholder="Cerca Paziente" value={patientFilter} onChange={filterHandler} />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <ul className={`patient-list ${filteredPatients.length > 0 && !patientData ? '' : 'd-none'}`} >
                        {
                            filteredPatients.map((item, index) =>
                                <div className="rounded-box patient-box search" onClick={() => selectPatientHandler(item.patient.id)}>
                                    <li className="patient-list-item">
                                        <div className="avatar"></div>
                                        <p className="patient-list-name">{item.patient.firstname} {item.patient.lastname}</p>
                                    </li>
                                </div>
                            )
                        }
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className={`patient-info-wrapper mt-2 ${patientData && patientFilter === '' ? '' : 'd-none'}`}>
                        <PatientInfo
                            object={patientData}
                        />
                    </div>
                </div>
            </div>
            <div className={`row ${patientData ? '' : 'd-none'}`}>
                <div className="col-6">
                    <div className="form-group">
                        <input className="form-control" readOnly placeholder="Seleziona data" type="text" value={daySelected} onClick={() => setShowcalendar(true)}></input>
                        <label className="custom-label">Seleziona data</label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <input className="form-control" placeholder="Seleziona Ora" type="time" value={timeSelected} onChange={(event) => setTimeSelected(event.target.value)}></input>
                        <label className="custom-label">Seleziona Ora</label>
                    </div>
                </div>
                <SmallCalendar
                    className={`absolute ${showCalendar ? '' : 'd-none'}`}
                    locale="it-IT"
                    onClickDay={(event, value) => dayClickHandler(event)}
                    minDate={new Date()}
                />
            </div>
            <div className={`row ${patientData ? '' : 'd-none'}`}>
                <div className="col-12">
                    <button className="button secondary mt-2 mx-auto" onClick={addAppointmentHandler}><FontAwesomeIcon className="mr-1" icon="calendar-plus" /> Aggiungi appuntamento</button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AddAppointment;

import React from 'react';
import { NavLink } from "react-router-dom";

import logo from '../assets/images/layout/logo-w.png';
import logoMini from '../assets/images/layout/logo-compact-w.png';
import logoGoogle from '../assets/images/icons/google-play.png';
import logoIos from '../assets/images/icons/apple.png';

import Home from '../assets/images/fa-icons/home.svg';
import HomeW from '../assets/images/fa-icons/home-w.svg';
import Address from '../assets/images/fa-icons/address-card.svg';
import AddressW from '../assets/images/fa-icons/address-card-w.svg';
import Calendar from '../assets/images/fa-icons/calendar.svg';
import CalendarW from '../assets/images/fa-icons/calendar-w.svg';
import Newspaper from '../assets/images/fa-icons/newspaper.svg';
import NewspaperW from '../assets/images/fa-icons/newspaper-w.svg';
import ChartLine from '../assets/images/fa-icons/chart-line.svg';
import ChartLineW from '../assets/images/fa-icons/chart-line-w.svg';


const Menu = (props) => {

    return (
        <div className={`main-menu ${props.collapsed ? 'collapsed' : ''}`}>
            <img className="logo" src={logo} alt="logo giada" />
            <img className="mini-logo" src={logoMini} alt="logo giada" />
            <div className="wrapper-voice-menu">
                <NavLink className="item-menu" activeClassName="active" to="/dashboard">
                    <img className="icon-img" src={Home} alt="icona dashboard" />
                    <img className="icon-img icon-w" src={HomeW} alt="icon dashboard" />
                    <span>Dashboard</span>
                </NavLink>
                <NavLink className="item-menu" activeClassName="active" to="/pazienti/">
                    <img className="icon-img" src={Address} aòt="patients icon" />
                    <img className="icon-img icon-w" src={AddressW} /> <span>Pazienti</span>
                </NavLink>
                <NavLink className="item-menu" activeClassName="active" to="/calendario">
                    <img className="icon-img" src={Calendar} />
                    <img className="icon-img icon-w" src={CalendarW} /> <span>Calendario</span>
                </NavLink>
                <NavLink className="item-menu" activeClassName="active" to="/news">
                    <img className="icon-img" src={Newspaper} />
                    <img className="icon-img icon-w" src={NewspaperW} /><span>News</span>
                </NavLink>
                <NavLink className="item-menu" activeClassName="active" to="/analisi">
                    <img className="icon-img" src={ChartLine} />
                    <img className="icon-img icon-w" src={ChartLineW} /> <span>Analisi</span>
                </NavLink>
                <div className="wrapper-appstore">
                    <h5>Scarica l'app</h5>
                    <a target="_blank" className="link-store" href="https://play.google.com/store/apps/details?id=com.edra.giada&gl=IT">
                        <img className="logo-store" src={logoGoogle} alt="logo app store" />
                    </a>
                    <a target="_blank" className="link-store" href="https://apps.apple.com/it/app/giada-accanto-a-te/id1483030584">
                        <img className="logo-store" src={logoIos} alt="logo apple store" />
                    </a>
                </div>
                <div className="collapsed-appstore">
                    <div className="item-menu">
                        <a className="stretched-link" href="https://play.google.com/store/apps/details?id=com.edra.giada" target={'_blank'}></a>
                        <img className="logo-store" src={logoGoogle} alt="logo app store" />
                    </div>
                    <div className="item-menu">
                        <a className="stretched-link" href="https://apps.apple.com/it/app/giada-accanto-a-te/id1483030584" target={'_blank'}> </a>
                        <img className="logo-store" src={logoIos} alt="logo app store" />
                    </div>
                </div>
            </div>

        </div>

    );
};

export default Menu;

import axios from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setContext } from '../store/contextStore';
import { sendMessage } from '../store/messagesStore';
import { acceptUserPatient } from '../store/userStore';
import getCallUrl from '../utils/callUrlUtil';
import getDataDifference from '../utils/getDataDifference';
import ContextMenu from './contextMenu/contextMenu';
import { acceptTaggedEventAtom, removeTaggedEventAtom, taggedEventsAtom } from '../jotai/calendarEvents/taggedEventsAtom';




export default function NotificationsWidget() {
    const [localAppointments, setLocalAppointments] = useState<any[]>([]);
    const [localPatients, setLocalPatients] = useState<any[]>([]);
    const [localLoaded, setLocalLoaded] = useState(false);
    //@ts-ignore
    const patientsPending = useSelector(state => state.user.patientsPending);
    const appointmentsPending = useAtomValue(taggedEventsAtom);
    //@ts-ignore
    const userToken = useSelector((state) => state.user.authToken);
    const callUrl = getCallUrl();
    const dispatch = useDispatch();
    const removeTaggedEvent = useSetAtom(removeTaggedEventAtom);
    const acceptTaggedEvent = useSetAtom(acceptTaggedEventAtom);

    useEffect(() => {
        loadFromLocal();
    }, []);

    useEffect(() => {

        if (localLoaded) {
            saveNotificationData();
        }

        console.log('appointmentsPending', appointmentsPending);


    }, [patientsPending, appointmentsPending]);

    function loadFromLocal() {
        const savedAppointments = localStorage.getItem('appointmentsPending');
        const savedPatients = localStorage.getItem('pendingPatients');

        setLocalPatients(savedPatients ? JSON.parse(savedPatients) : []);
        setLocalAppointments(savedAppointments ? JSON.parse(savedAppointments) : []);
        setLocalLoaded(true);
    }

    function saveNotificationData() {

        const appointments: any[] = localAppointments;
        const patients: any[] = localPatients;

        // check if ids already saveAdherences, if not add to array
        patientsPending.forEach((element: any) => {
            if (patients.find((el: any) => el.id === element.id) === undefined) {
                patients.push({ id: element.id, date: new Date() });
            }

        });

        appointmentsPending.forEach((element: any) => {
            if (appointments.find((el: any) => el.id === element.id) === undefined) {
                appointments.push({ id: element.id, date: new Date() });
            }
        });

        localStorage.setItem('pendingPatients', JSON.stringify(patients));
        localStorage.setItem('appointmentsPending', JSON.stringify(appointments));
    }


    const contextHandler = (e: any) => {

        let xPos = e.target.getBoundingClientRect().left + 'px';
        let yPos = e.target.getBoundingClientRect().top + 'px';

        const widthDifference = e.target.getBoundingClientRect().left + 800 - window.innerWidth;
        const heightDifference = e.target.getBoundingClientRect().top + 400 - window.innerHeight;

        if (widthDifference > 0) {
            xPos = e.target.getBoundingClientRect().left - (widthDifference + 15) + 'px';
        }
        if (heightDifference > 0) {
            yPos = e.target.getBoundingClientRect().top - (heightDifference + 5) + 'px';
        }

        dispatch(setContext({
            xPos: xPos,
            yPos: yPos,
            contextName: 'notifications',
        }));

    };

    function setAppointmentHandler(id: string, action: boolean) {
        // console.log(`${callUrl}/api/users/calendar-events/medic/${id}/confirm/${action ? 'true' : 'false'}`);

        axios.get(`${callUrl}/api/users/calendar-events/medic/${id}/confirm/${action ? 'true' : 'false'}`, {
            headers: { 'X-AUTH-TOKEN': userToken },
        })
            .then(response => {
                // console.log('eventResponse', response);
                if (response.data.status === 'success') {

                    dispatch(sendMessage({
                        message: `Appuntamento ${action ? 'confermato' : 'rifiutato'} con successo!`,
                        positive: true,
                    }));

                    if (action) {
                        acceptTaggedEvent(id);
                        removeTaggedEvent(id);
                    } else {
                        removeTaggedEvent(id);
                    }

                } else {
                    dispatch(sendMessage({
                        message: `Impossibile ${action ? 'confermare' : 'rifiutare'} appuntamento: ` + response.data.message,
                        positive: false,
                    }));
                }
            })
            .catch(error => dispatch(sendMessage({
                message: `Impossibile ${action ? 'confermare' : 'rifiutare'} appuntamento errore: ` + error,
                positive: false,
            })));
    }


    function setPatientHandler(id: string, action: string) {

        axios.post(`${callUrl}/api/medics/pending-patients/${id}/${action}`, {}, {
            headers: { 'X-AUTH-TOKEN': userToken },
        })
            .then(response => {
                if (response.data.status !== 'error') {
                    dispatch(acceptUserPatient({ id: id, accept: action === 'accept' }));
                    dispatch(sendMessage({
                        message: action === 'accept' ? 'Utente accettato' : 'Utente rifiutato',
                        positive: true,
                    }));
                    localStorage.setItem('pendingPatients', JSON.stringify(localPatients.filter(el => el.id !== id)));

                } else {
                    dispatch(sendMessage({
                        message: 'operazione non riuscita',
                        positive: false,
                    }));
                }
            })
            .catch(error => dispatch(sendMessage({
                message: 'Operazione non riuscita ' + error,
                positive: false,
            }))
            );
    }

    return (
        <React.Fragment>
            <div className="wrapper-notify" onClick={contextHandler}>
                <div className={`notify-circle ${patientsPending.length > 0 || appointmentsPending.length > 0 ? 'active' : ''}`}></div>
            </div>
            <ContextMenu
                type="big"
                contextName="notifications"
            >
                <div className="notification-wrapper">
                    <p className="notification-title text-start">Notifiche</p>
                    {patientsPending.map((element: any) => (
                        <div key={element.id ?? Math.random()} className="notification-row">
                            <div className="avatar"></div>
                            <div className="notification-body">
                                <p className="nb-text">Il tuo paziente {element.patient.firstname} {element.patient.lastname} ti ha inviato una richiesta di contatto sull'app Giada</p>
                                <p className="nb-info text-muted">{getDataDifference(localPatients.find((el: any) => el.id === element.id)?.date ?? new Date())}</p>
                            </div>
                            <div className="notification-actions">
                                <button onClick={() => setPatientHandler(element.id, 'remove')} className="button deny outline">
                                    Rifiuta
                                </button>
                                <button onClick={() => setPatientHandler(element.id, 'accept')} className="button accept">
                                    Accetta
                                </button>
                            </div>
                        </div>
                    ))}
                    {appointmentsPending.map((element: any) => (
                        <div className="notification-row">
                            <div className="avatar"></div>
                            <div className="notification-body">
                                <p className="nb-text">Il tuo paziente {element.name} {element.lastName} ha richiesto un appuntamento con te sull'app Giada<br />
                                    per il giorno {element.date.toLocaleString().split(',')[0]} alle ore {element.date.toLocaleString().split(',')[1].substr(0, 6)}</p>
                                <p className="nb-info text-muted">{getDataDifference(localAppointments.find((el: any) => el.id === element.eventId)?.date ?? new Date())}</p>
                            </div>
                            <div className="notification-actions">
                                <button onClick={() => setAppointmentHandler(element.eventId, false)} className="button deny outline">
                                    Rifiuta
                                </button>
                                <button onClick={() => setAppointmentHandler(element.eventId, true)} className="button accept">
                                    Accetta
                                </button>
                            </div>
                        </div>
                    ))}
                    {appointmentsPending.length <= 0 && patientsPending.length <= 0 ?
                        <div className="notification-row">
                            nessuna nuova notifica
                        </div>
                        : null}
                </div>
            </ContextMenu>
        </React.Fragment>
    );
}
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { sendMessage } from '../store/messagesStore';
import { addNewGoal } from '../store/goalsStore';
import isDev from '../hooks/isDev';
import getCallUrl from '../utils/callUrlUtil';



const AddPatientGoal = (props) => {
    const [title, setTitle] = useState('');
    const [typology, setTypology] = useState('Spontaneo');
    const [description, setDescription] = useState('');
    const userToken = useSelector((state) => state.user.authToken);
    const dispatch = useDispatch();
    let callUrl = getCallUrl();


    const cancelHandler = () => {
        setTitle('');
        setTypology('Spontaneo');
        setDescription('');

        props.closeModal();
    }

    const addGoalHandler = () => {
        const goalValues = ["Spontaneo",
            "Attività",
            "Assistenza",
            "Aderenza Terapeutica",
            "Umore",
            "Utilizzo dell'app"]
        const realGoalValues = ["spontaneous", "activity", "assistance", "therapeutic_adherence", "mood", "app_usage"];
        const typologyIndex = goalValues.findIndex(e => e === typology);
        let typologyToAdd = 'spontaneous';

        if (typologyIndex !== -1) {
            typologyToAdd = realGoalValues[typologyIndex];
        }
        const newGoalObject = {
            "title": title,
            "type": typologyToAdd,
            "description": description
        }


        axios.post(`${callUrl}/api/goals/add-to-patient/${props.userID}`, {
            "title": title,
            "type": typologyToAdd,
            "description": description
        }, {
            headers: { 'X-AUTH-TOKEN': userToken },
        })
            .then(response => {
                if (response.data.status === "error") {
                    dispatch(sendMessage({
                        message: 'Impossibile inserire tragaurdo',
                        positive: false,
                    }));

                } else {
                    dispatch(sendMessage({
                        message: 'Traguardo inserito',
                        positive: true,
                    }));

                    dispatch(addNewGoal({
                        userID: props.userID,
                        title: title,
                        type: typology,
                        description: description,
                    }))


                    cancelHandler();
                }
            })
            .catch(error => {
                dispatch(sendMessage({
                    message: 'Impossibile inserire traguardo: ' + error,
                    positive: false,
                }));
            });
    }

    return (
        <div className="goal-add-wrapper">
            <div className="row">
                <div className="col-6">
                    <div className="form-group">
                        <label>Titolo</label>
                        <input type="text" value={title} onChange={(e) => setTitle(e.target.value)}></input>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label>Tipologia</label>
                        <select value={typology} onChange={(e) => setTypology(e.target.value)}>
                            <option>Spontaneo</option>
                            <option>Attività</option>
                            <option>Assistenza</option>
                            <option>Aderenza Terapeutica</option>
                            <option>Umore</option>
                            <option>Utilizzo dell'app</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="form-group">
                        <label>Descrizione</label>
                        <textarea rows="8" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="form-group">
                        <div className={'modal-buttons'}>
                            <button onClick={cancelHandler} className="button big deny">
                                Annulla
                            </button>
                            <button onClick={addGoalHandler} className="button big accept">
                                Aggiungi
                            </button>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}


export default AddPatientGoal;
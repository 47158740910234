import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

const dataUnitsArray = [
    ' media minuti',
    '%',
    '/5',
    ' raggiunti',
    ' compilati',
];

const CustomTooltip = ({ slice, chartsData, passedID, specialID }) => {

    return (
        slice.points.map(point => (
            <div
                className="custom-chart-tooltip"
                key={uuid()}

            >
                {chartsData.map((chart, index) => {
                    const datatoShow = chart.data[point.data.x - 1]?.y;
                    return (passedID[chart.id] || chart.id === specialID ?
                        <p key={uuid()}>
                            {datatoShow !== null ?
                                <React.Fragment>
                                    <span>{chart.name}: {Math.round(datatoShow)}{dataUnitsArray[chart.id - 1]}</span>
                                </React.Fragment>
                                : null}
                        </p>
                        : null);

                })}
            </div>
        ))
    );
};

export default CustomTooltip;
import React from 'react'
import { useSelector } from 'react-redux';
import PatientRow from '../../components/patient/patientRow';

export default function ActivityFiltered({ patients, patientClick }) {
  const decreasingActivities = useSelector((state) => state.activities.decreasingActivities);

    return (
      patients.filter(element => decreasingActivities.includes(element.patient.id)).length> 0 ? patients.filter(element => decreasingActivities.includes(element.patient.id)).map(item => {
            /* if user is accepted*/
            if (item.status === 'accepted') {
                return (
                    <div key={item.patient.id} className="col-12 col-lg-6 col-xl-12">
                        <PatientRow
                            patientClick={() => patientClick(item.patient.id)}
                            patient={item.patient}
                            appointment={null}
                        />
                    </div>
                )
            }
        })
            : < h3 > Nessun paziente presente</h3 >
    )
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    averageUsage: 0,
    dayUsage: [],
    timeUsage: [],
    dayUsageChartData: [],
    userDailyUsage: [],
    userSessions: [],
    userAverageDuration: [],
    singleDayUsage: 0,
    singleTimeUsage: 0,
    unused: [],
};

export const appUsageSlice = createSlice({
    name: 'usage',
    initialState,
    reducers: {
        setUnused: (state, action) => {
            if (action.payload.usage.duration_avarage === 0) {
                state.unused.push(action.payload.id);
            }
        },
        resetUnused: (state, action) => {
            state.unused.length = 0;
        },
        setUsage: (state, action) => {
            state.averageUsage = state.averageUsage + Math.round(parseFloat(action.payload.usage.duration_avarage) / 60);


            const newDayUsage = state.dayUsage;
            const newDayChartData = state.dayUsageChartData;
            const userDailyUsage = state.userDailyUsage;
            const sessions = state.userSessions;

            action.payload.usage.usage_by_day.forEach(element => {
                const elementDate = new Date(element.date);
                const dateIndex = newDayUsage.findIndex(e => e.date === elementDate);
                const usage = parseInt(element.usage);

                if (dateIndex !== -1) {
                    newDayUsage[dateIndex].usage = newDayUsage[dateIndex].usage + usage;
                    newDayChartData[dateIndex].averageValue = newDayChartData[dateIndex].averageValue + usage;
                    userDailyUsage[dateIndex].usage = userDailyUsage[dateIndex].usage + usage;
                } else {
                    userDailyUsage.push({ id: action.payload.id, usage: Math.round(usage / 60), date: `${elementDate.getDate()}-${elementDate.getMonth() + 1}-${elementDate.getFullYear()}` });

                    newDayUsage.push({
                        date: elementDate.toString(),
                        usage: Math.round(usage / 60)
                    });

                    newDayChartData.push({
                        date: `${elementDate.getDate()}-${elementDate.getMonth()}-${elementDate.getFullYear()}`,
                        averageValue: Math.round(usage / 60)
                    });
                }
            });

            sessions.push({ id: action.payload.id, session: action.payload.usage.sessions_per_day });

            state.dayUsage = newDayUsage;
            state.dayUsageChartData = newDayChartData;
            state.userSessions = sessions;

            const newTimeUsage = state.timeUsage;

            action.payload.usage.usage_by_hour.forEach(element => {
                const elementHour = element.hourOfDay;
                const timeIndex = newTimeUsage.findIndex(e => e.hour === elementHour);
                const usage = parseInt(element.usage);

                if (timeIndex !== -1) {
                    newTimeUsage[timeIndex].usage = newTimeUsage[timeIndex].usage + usage;
                } else {
                    newTimeUsage.push({
                        hour: elementHour,
                        usage: usage
                    });
                }
            });

            state.timeUsage = newTimeUsage;

            const userAverageDuration = state.userAverageDuration;

            userAverageDuration.push({ id: action.payload.id, sessions: action.payload.usage.duration_avarage });

            state.userDailyUsage = userDailyUsage;
            state.userAverageDuration = userAverageDuration;


        },
        setSingleUsage: (state, action) => {
        },
        resetUsage: (state) => {
            state.averageUsage = 0;
            state.dayUsageChartData = [];
        }
    }
});

export const { setUsage, setSingleUsage, resetUsage, setUnused, resetUnused } = appUsageSlice.actions;

export default appUsageSlice.reducer;
import { atom } from "jotai";
import { calendarPatientIdAtom } from "./calendarWeeklyEventsAtom";

type goalElememtType = {
  completed: boolean;
  completed_at: string;
  created_at: string;
  date: string;
  description: string;
  id: string;
  title: string;
  type: string;
};

type goalType = {
  patientID: string,
  goals: goalElememtType[];
};

export const uniqueGoalsAtom = atom<goalType[]>([]);

export const setUniqueGoalsAtom = atom(
  null,
  (get, set, payload: goalType) => {
    const goals = get(uniqueGoalsAtom);
    // get the goals for the patient
    const patientIndex = goals.findIndex((goal) => goal.patientID === payload.patientID);
    const patientGoals = goals.find((goal) => goal.patientID === payload.patientID);
    if (patientGoals) {
      const newGoals = [...patientGoals.goals];

      payload.goals.forEach((goal) => {
        if (!newGoals.some((g) => g.id === goal.id)) {
          newGoals.push(goal);
        }
      }
      );


      goals[patientIndex].goals = newGoals;

      set(uniqueGoalsAtom, goals);


    }
  }
);

export const getWeeklyGoalsAtom = atom(
  (get) => {
    const goals = get(uniqueGoalsAtom);
    const patientId = get(calendarPatientIdAtom);

    const patientGoals = goals.find((goal) => goal.patientID === patientId);

    if (patientGoals) {
      return goals;
    }
    return goals;

  }
);

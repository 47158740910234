import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import list from '../assets/images/icons/list.png';
import surveyIcon from '../assets/images/icons/paper.png';
import shoe from '../assets/images/icons/shoes.png';
import cup from '../assets/images/icons/cup.png';
import danger from '../assets/images/icons/danger-transparent.png';
import getCallUrl from '../utils/callUrlUtil';

const EventModalBuilder = props => {
    const userToken = useSelector((state) => state.user.authToken);
    const [moodsValues, setMoodsValues] = useState([]);
    const [adherenceValues, setAdherenceValues] = useState([]);
    const [taken, setTaken] = useState(0);
    const [missed, setMissed] = useState(0);
    const [adherenceRateValue, setAdherenceRateValue] = useState([]);
    let callUrl = getCallUrl();

    useEffect(() => {
        if (props.selected === 'umore' && props.event) {
            setMoodsValues([]);
            getMoodContent();
        } else {
            setMoodsValues([]);

        }

        if (props.selected === 'terapia' && props.event) {
            setAdherenceValues([]);
            getAdherenceContent();
        } else {
            setAdherenceValues([]);
        }

    }, [props]);



    const getAdherenceContent = () => {
        if (props.event) {
            const date = `${props.event.start.getFullYear()}-${props.event.start.getMonth() + 1}-${props.event.start.getDate()}`;
            // console.log('date', date)

            axios.get(`${callUrl}/api/patient-drugs/patients/${props.patient.id}/therapeutic-adherences-detail/${date}`, {
                headers: { 'X-AUTH-TOKEN': userToken },
            })
                .then(response => {
                    const drugsResult = response.data.data.drugs;
                    let taken = 0;
                    let missed = 0;
                    console.log('response drugsResult', response.data.data);
                    setAdherenceValues(drugsResult);
                    setAdherenceRateValue(response.data.data.rate);
                    if (drugsResult && drugsResult.length > 0) {
                        drugsResult.forEach((drug) => {
                            if (props.selectedDrugId === '' || props.selectedDrugId === drug.drug.id) {
                                if (drug.taken > 0) {
                                    taken += drug.taken;
                                } else {
                                    missed += drug.dose;
                                }
                            }
                        });


                        setTaken(taken);
                        setMissed(missed);
                    }
                })
                .catch(error => console.log(error));

        }

    };


    function getMoodContent() {
        if (props.event) {
            const date = `${props.event.start.getFullYear()}-${props.event.start.getMonth() + 1}-${props.event.start.getDate()}`;

            return axios.get(`${callUrl}/api/moods/patients/${props.patient.id}/list/${date}`, {
                headers: { 'X-AUTH-TOKEN': userToken },
            })
                .then(response => {
                    if (response.data.status === 'success') {
                        setMoodsValues([response.data.data.moods[0]]);

                    } else {
                        return null;
                    }
                })
                .catch(error => console.log(error));
        }

    }

    const getContent = () => {
        if (props.event) {
            switch (props.selected) {
                case 'attivita': {
                    let totalSteps = 0;
                    let totalKm = 0;
                    const activities = props.event.data[props.selected].activities ? props.event.data[props.selected].activities : null;

                    activities.forEach(activity => {
                        totalSteps += activity.steps;
                        totalKm += activity.distance;
                    });
                    return (
                        <React.Fragment>
                            <ul>
                                {activities ? activities.map(element => {
                                    return (
                                        <li className="icon-info mb-3">
                                            <img src={shoe} className="icon" alt="activity-icon" />

                                            <div>
                                                <p>
                                                    Camminata <span className="gray-info">/ {element.time}</span>
                                                </p>
                                                <p>
                                                    <span className="special good">{element.steps}</span> Passi / <span className="special good">{element.distance}</span> km
                                                </p>
                                            </div>
                                        </li>
                                    );
                                }) : null}
                            </ul>


                            <div className="event-footer">
                                <span className="special good">{totalSteps}</span> Passi Totali / <span className="special good">{totalKm.toFixed(2)}</span> Km Totali
                            </div>
                        </React.Fragment>

                    );
                }
                case 'traguardi': {
                    return (
                        <React.Fragment>
                            <ul>
                                {
                                    props.event.data[props.selected].goalsInfo ?
                                        props.event.data[props.selected].goalsInfo.map(element => (
                                            <li className="icon-info">
                                                <img src={cup} className="icon" alt="activity-icon" />
                                                <div>
                                                    <p>
                                                        {element.title}
                                                    </p>
                                                    <p className="gray-info">
                                                        {element.description}
                                                    </p>
                                                </div>
                                            </li>
                                        )) : null
                                }
                            </ul>

                        </React.Fragment>

                    );
                }
                case 'info': {
                    return (
                        <React.Fragment>
                            <ul>
                                {props.event.data[props.selected].clinicalInfo ? props.event.data[props.selected].clinicalInfo.map(element => (
                                    <li className="icon-info mb-3">
                                        <img src={danger} className="icon" alt="activity-icon" />
                                        <div>
                                            <p>
                                                {element.title}
                                            </p>
                                            <p className="gray-info">
                                                {element.description}
                                            </p>
                                        </div>
                                    </li>
                                )) : null}

                            </ul>

                        </React.Fragment>

                    );
                }
                case 'utilizzo': {
                    return (
                        <ul>
                            <li>
                                <p>Apertura App</p>
                                <p>Durata {props.event.data[props.selected].total} minuti</p>
                            </li>
                        </ul>
                    );
                }
                default:

                    if (props.event.data[props.selected].extra) {
                        const answers = [
                            "Mai",
                            "Alcuni giorni",
                            "Più di metà dei giorni",
                            "Quasi tutti i giorni",];
                        return (
                            <React.Fragment>
                                <div style={{
                                    marginTop: '3rem',
                                    paddingTop: '2rem',
                                    borderColor: '#31ac7e',
                                    borderTopStyle: 'solid',
                                    borderTopWidth: '0.1rem',
                                    borderBottomStyle: 'solid',
                                    borderBottomWidth: '0.1rem',
                                }}>
                                    <p className='mood-modal-title'>PHQ9</p>

                                    <div className="icon-info">
                                        <ul className="info" style={{ maxHeight: 'calc(100vh - 40rem)' }}>
                                            {props.event.data[props.selected].survey.map((element, index) => (
                                                <li className='survey-block'>
                                                    <p className='survey-block-number'>{index + 1}</p>
                                                    <p className='survey-block-question'>{index !== 0 ? element.question : 'hai provato poco interesse o piacere nel fare le cose ?'}</p>
                                                    <p className='survey-block-answer'>{answers[element.answer]}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                {/* <div className="special danger" style={{ marginTop: '2rem' }}>{props.event.data[props.selected].surveyTotal} Totale</div> */}
                            </React.Fragment>
                        );
                    } else {
                    }
            }
        }
    };

    function getAdherenceRateValue(values) {
        const taken = values.filter(time => props.selectedDrugId !== '' ? time.drug.id === props.selectedDrugId : true).filter(time => time.taken).length;
        const notTaken = values.filter(time => props.selectedDrugId !== '' ? time.drug.id === props.selectedDrugId : true).filter(time => !time.taken).length;

        return taken / (taken + notTaken);

    }


    return (
        <React.Fragment>
            {moodsValues.map((mood, index) => (
                <React.Fragment>
                    <p className='mood-modal-title'>Umore</p>
                    <div key={index} className="mood-modal-item">
                        <p className='mood-value'>{mood && mood.rating ? mood.rating : 'ND'}/5</p>
                        {/* <p className='mood-note text-muted'>{mood.note ?? 'Nessuna nota inserita'}</p> */}
                    </div>
                </React.Fragment>
            ))}
            {props.selected !== 'umore' || 'terapia' ? getContent() : null}

            {adherenceValues?.length > 0 ?
                <React.Fragment>
                    <div className="icon-info">
                        <img src={danger} className="icon" alt="list-icon" />
                        <div className="info">
                            <p style={{ fontWeight: 'bold' }}>{missed === 1 ? 'Mancata' : 'Mancate'} <span className="special good">{missed}</span></p>
                            <ul>
                                {adherenceValues.filter(time => props.selectedDrugId !== '' ? time.drug.id === props.selectedDrugId : true).filter(time => !time.taken).map(taken => (
                                    <li>
                                        - {taken.drug.name} <span className="gray-info">/dosi: {taken.dose} /ore: {taken.time}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="icon-info">
                        <img src={list} className="icon" alt="list-icon" />
                        <div className="info">
                            <p style={{ fontWeight: 'bold' }}>{taken === 1 ? 'Presa' : 'Prese'} <span className="special good">{taken}</span></p>
                            <ul>
                                {adherenceValues.filter(time => props.selectedDrugId !== '' ? time.drug.id === props.selectedDrugId : true).filter(time => time.taken).map(taken => (
                                    <li>
                                        - {taken.drug.name} <span className="gray-info">/dosi: {taken.dose}  /ore: {taken.time}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="calendar-footer">
                        {props.event.data.terapia.total &&
                            <span className={`special ${Math.round(getAdherenceRateValue(adherenceValues) * 100) < 70 ? Math.round(getAdherenceRateValue(adherenceValues) * 100) < 55 ? 'danger' : 'warning' : 'good'}`}>{props.event.data.terapia.total} aderenza</span>
                        }
                    </div>
                </React.Fragment>
                : props.selected === 'aderenza' ? <p> Nessuna medicina presa nel giorno selezionato </p> : null}
        </React.Fragment>
    );
};

export default EventModalBuilder;

function getTakenRate(times) {
    let taken = 0;
    let total = 0;
    times.forEach(element => {
        if (element.taken) {
            taken++;
        }
        total++;
    }
    );
    // console.log('times', total)
    return taken / total;
}
import axios from "axios";
import { atom } from "jotai";
import getCallUrl from "../utils/callUrlUtil";
import { calendarStartDateAtom, calendarEndDateAtom, calendarPatientIdAtom } from "./calendarWeeklyEventsAtom";
import { userTokenAtom } from "./userInfoAtom";


export const getCalendarWeeklyMoods = atom(
  async (get) => {
    const startDate = get(calendarStartDateAtom);
    const endDate = get(calendarEndDateAtom);
    const callUrl = getCallUrl();
    const userToken = get(userTokenAtom);
    const patientId = get(calendarPatientIdAtom);




    const response = await axios.get(`${callUrl}/api/moods/patients/${patientId}/list/${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}/${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`, {
      headers: { 'X-AUTH-TOKEN': userToken },
    })
      .then(response => {

        return response.data;
      })
      .catch(error => {
        console.log(error);
      });

    if (response) {
      const responseArray = response.data.moods;
      const newResponseArray = responseArray.map((element: any) => {
        const newDate = new Date(element.date);
        return {
          rating: element.rating,
          averageValue: element.rating,
          date: `${newDate.getDate()}-${newDate.getMonth()}-${newDate.getFullYear()}`
        };
      });


      return newResponseArray;
    } else {
      return [];
    }
  }
);
import { ResponsiveLine } from '@nivo/line';
import React, { useEffect, useState } from 'react';
import CustomTooltip from './CustomTooltip';


const LineChart = ({ data, setID, passedID, dataMin, dataMax, chartsData, special, specialID }) => {
  const chartData = [];
  const [localData, setLocalData] = useState([]);
  const maxData = getMaxData(data);
  const minData = Math.min(...data[0].data.map(elem => elem.y));


  useEffect(() => {
    setLocalData(data);
  }, [data]);

  return (
    <React.Fragment>

      <div className={`absolute-chart ${passedID[setID] || specialID === setID ? 'chart-active' : ''} ${special ? 'special' : ''}`}>
        {

          !passedID[setID] && !special && specialID !== setID ?
            null
            : <ResponsiveLine
              data={localData ?? chartData}
              margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
              xScale={{ type: 'point' }}
              yScale={{ type: 'linear', min: minData, max: dataMax ?? maxData + 1, stacked: false, reverse: false }}
              yFormat=" >-.2f"
              curve="catmullRom"
              axisTop={null}
              lineWidth={4}
              axisBottom={null}
              axisLeft={null}
              axisRight={null}
              enableGridX={false}
              enableGridY={false}
              pointSize={10}
              pointColor={special ? 'transparent' : { theme: 'background' }}
              pointBorderWidth={2}
              pointBorderColor={special ? 'transparent' : { from: 'serieColor' }}
              enableSlices="x"
              pointLabelYOffset={-202}
              useMesh={true}
              colors={special ? 'transparent' : { datum: 'color' }}
              sliceTooltip={special ? ({ slice }) => {
                return (
                  <CustomTooltip slice={slice} chartsData={chartsData} passedID={passedID} specialID={specialID} />
                );

              } : null}
            />}
      </div>
    </React.Fragment>
  );
};



export default LineChart;


function getMaxData(data) {
  const maxData = Math.max(...data[0].data.map(elem => elem.y));

  return maxData > 0 ? maxData : 1;

}
import React from 'react'
import { useSelector } from 'react-redux'
import Loader from './Loader'

export default function AbsoluteLoader() {
  // @ts-ignore
  const isLoading = useSelector(state => state.loading.loading)
  return (
    isLoading &&
    <React.Fragment>
      <div className='absolute-loader-bg' style={{position:'fixed', top: 0, left: 0, height: '100vh', width:'100vw', background: 'rgba(0,0,0,0.5)', zIndex:90}}></div>
      <div className="aboslute-loader">
       <h1>Loading Data...</h1>
        <Loader />
      </div>
    </React.Fragment>
  )
}

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { setAuthToken, userLogin } from '../../store/userStore';
import getCallUrl from '../../utils/callUrlUtil';
import { loginData } from '../exampleData/exampleData';
import isDev from '../../hooks/isDev';
import { useSetAtom } from 'jotai';
import { userTokenAtom } from '../../jotai/userInfoAtom';


function Login(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(true);
    const userToken = useSelector((state) => state.user.authToken);
    const userError = useSelector((state) => state.user.logError);
    const dispatch = useDispatch();
    const callUrl = getCallUrl();
    const setTokenAtom = useSetAtom(userTokenAtom);

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (userToken !== null && userError === null) {
            props.loggedAction();
            sessionStorage.setItem('authDate', new Date('now'));
        } else if (token) {
            tokenLoginHandler();
        }

    }, [userToken, userError]);

    const tokenLoginHandler = () => {
        const localToken = localStorage.getItem('token');
        axios.get(`${callUrl}/api/users/profile`, {
            headers: { 'X-AUTH-TOKEN': localToken },
        })
            .then(response => {
                if (isDev()) {
                    console.log('login response', response.data.data.user);
                    console.log('login patients', response.data.data.user.patient_medics);
                }
                if (response.status === 200) {
                    // console.log(response.data)
                    dispatch(setAuthToken(localToken));
                    setTokenAtom(localToken);
                    sessionStorage.setItem('token', localToken);
                    // console.log('response.data', response.data.data.user.patient_medics);

                    dispatch(userLogin({
                        data: response.data,
                        logError: null,
                        skipToken: true
                    }));
                } else {
                    localStorage.removeItem('token');
                    // dispatch(userLogin({ data: null, logError: "Token scaduto, effettua il login pr continuare" }));
                }
            })
            .catch(error => {
                console.log(error);
            });
    };


    const loginHandler = (e) => {
        e.preventDefault();

        if (callUrl === 'fakeData') {
            dispatch(userLogin({
                data: loginData,
                logError: null,
            }));
        } else {
            axios.post(`${callUrl}/api/users/login-medic`, { "username": username, "password": password })
                .then(response => {
                    if (isDev()) {
                        console.log('login response', response.data.data.user);
                        console.log('login patients', response.data.data.user.patient_medics);

                    }
                    if (response.status === 200) {
                        // console.log('response.data', response.data.data.user);
                        if (rememberMe) {
                            localStorage.setItem("token", response.data.data.token);
                        }
                        sessionStorage.setItem('token', response.data.data.token);
                        dispatch(userLogin({
                            data: response.data,
                            logError: null,
                        }));
                        // dispatch(setUserToken(response.data.data.token));
                    } else {
                        dispatch(userLogin({ data: null, logError: "Combinazione username/password non trovata" }));
                    }
                })
                .catch(error => {
                    console.log(error);
                    dispatch(userLogin({ data: null, logError: "Combinazione username/password non trovata" }));

                });
        }


    };
    const fakeLoginHandler = (e) => {

        dispatch(userLogin({ data: 'fake', logError: null }));

    };

    const usernameHandler = (event) => {
        setUsername(event.target.value);
    };
    const passwordHandler = (event) => {
        setPassword(event.target.value);
    };


    return (
        <div className="login-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 mx-auto">
                        <div className="rounded-box">
                            <div className="login-logo"></div>
                            <p className="subtitle">Utilizza il tuo account MediKey per accedere a Giada.</p>
                            <p className="inline-error">{userError ? userError : null}</p>
                            <form className="login-form">

                                <div className="row">
                                    <div className="col-8 mx-auto">
                                        <div className="form-group">
                                            <input className={`form-control ${userError ? 'error' : ''}`} type="text" placeholder="Utente MediKey" value={username} onChange={usernameHandler}></input>
                                            <label className="custom-label">Utente MediKey</label>
                                        </div>
                                    </div>
                                    <div className="col-8 mx-auto">
                                        <div className="form-group">
                                            <input className={`form-control ${userError ? 'error' : ''}`} type="password" placeholder="Password" value={password} onChange={passwordHandler}></input>
                                            <label className="custom-label">Password</label>
                                        </div>
                                    </div>
                                    <div className="col-8 mx-auto d-flex justify-content-between align-items-center">
                                        <div className="form-group mb-3">
                                            <div className="custom-control custom-checkbox" onClick={() => setRememberMe(!rememberMe)}>
                                                <input className="custom-control-input" style={{ cursor: 'pointer' }} checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} type="checkbox" />
                                                <label className="custom-control-label">
                                                    Ricordami
                                                </label>
                                            </div>
                                        </div>
                                        {/* <a className="mb-3" href="#">Password Dimenticata</a> */}
                                    </div>
                                    <div className="col-12 mx-auto">
                                        <button className="button bigger" onClick={(event) => { loginHandler(event); }}>Login</button>
                                        {/* <button className="button bigger outlined">Registrati</button> */}
                                    </div>
                                </div>
                            </form>
                            {/* <p className="closing">Probemi ad accedere? <span onClick={fakeLoginHandler}>Clicca qui</span></p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
import DailyUse from '../assets/images/icons/phone.png';
import Step from '../assets/images/icons/shoes.png';
import Goal from '../assets/images/icons/cup.png';
import Survey from '../assets/images/icons/paper.png';
import Danger from '../assets/images/icons/danger.png';

/* umore
terapia
attivita
traguardi
utilizzo
info */


export default function CalendarTransform(data, startDate, selected, patientID, eventClicked, selectedActivity) {
    if (data) {
        const datedCalendarEvents = [];
        const date = new Date(startDate) ?? new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const datesArray = getDaysArray(firstDay, lastDay);

        // console.log('calendar startDay', firstDay);

        datesArray.forEach((element, index) => {
            const elementDate = `${element.getDate()}-${element.getMonth()}-${element.getFullYear()}`;
            const surveyData = getSurveyEvents(data.surveys, elementDate);

            datedCalendarEvents.push({
                id: index,
                title: 'calendar - 1',
                start: new Date(element.getFullYear(), element.getMonth(), element.getDate(), 0, 0, 0),
                end: new Date(element.getFullYear(), element.getMonth(), element.getDate(), 0, 20, 0),
                data: {
                    umore: {
                        total: data.moods && selectedActivity === 0 ? getMoodEvents(data.moods, elementDate) : '',
                        extra: data.surveys && selectedActivity === 1 ? surveyData !== '' ? <span class="ph-calendar">PHQ9-{surveyData}</span> : '' : '',
                        survey: data.surveys ? getSurveyAnswers(data.surveys, elementDate) : '',
                        surveyTotal: data.surveys ? getSurveyTotal(data.surveys) : 0,
                        icon: Survey,
                    },
                    terapia: {
                        total: data.adherence ? getAdherenceEvents(data.adherence, elementDate) : '',
                        icon: Survey,
                    },
                    attivita: {
                        total: data.activities && selectedActivity === 0 ? getActivities(data.activities, elementDate, patientID, false) : '',
                        extra: data.activities && selectedActivity === 1 ? getActivities(data.activities, elementDate, patientID, true) : '',
                        activities: data.activities ? getActivitiesArray(data.activities, elementDate, patientID) : null,
                        icon: Step,
                    },
                    traguardi: {
                        total: data.goals ? getGoals(data.goals, elementDate) : '',
                        goalsInfo: data.goals ? getGoalsInfo(data.goals, elementDate) : null,
                        icon: Goal,
                    },
                    utilizzo: {
                        total: data.appUse ? getUsageInfo(data.appUse, elementDate, patientID) : '',
                        icon: DailyUse,
                    },
                    info: {
                        total: data.clinicalEvents ? getClinical(data.clinicalEvents, elementDate, patientID, false) : '',
                        clinicalInfo: data.clinicalEvents ? getClinical(data.clinicalEvents, elementDate, patientID, true) : '',
                        icon: Danger,
                    }
                },
                selected: selected,
                patientID: patientID,
                eventClicked: eventClicked ? (e) => eventClicked(e) : null,

            });
        });


        console.log('datedCalendarEvents', datedCalendarEvents);
        return datedCalendarEvents;
    } else {
        return null;
    }

};

const getDaysArray = (start, end) => {
    for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
    }
    return arr;
};

const getUsageInfo = (data, day, patientID) => {
    let value = 0;
    // console.log('getUsageInfo', data);
    data.forEach(element => {
        if (element.id === patientID) {
            const dayDate = day.split('-');
            dayDate[1] = parseInt(dayDate[1]) + 1;
            const convertedDayDate = dayDate.join('-');
            if (convertedDayDate === element.date) {
                value += element.usage;
            }
        }

    });

    return value === 0 ? '' : value;
};
const getGoalsInfo = (data, day) => {
    const info = [];

    data.forEach(element => {
        const date = new Date(element.completed_at);
        const convertedDate = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
        if (day === convertedDate) {
            info.push({
                title: element.title,
                description: element.description,
            });
        }

    });

    return info;
};
const getClinical = (data, day, patientID, info) => {
    let value = 0;
    const infoArray = [];

    const patientIndex = data.findIndex(e => e.id === patientID);

    if (patientIndex !== -1) {
        data[patientIndex].events.forEach(element => {
            const eventDate = new Date(element.date);
            const convertedDate = `${eventDate.getDate()}-${eventDate.getMonth()}-${eventDate.getFullYear()}`;

            if (day === convertedDate) {
                value++;
                infoArray.push({
                    title: element.title,
                    description: element.description,
                });
            }

        });
    }

    if (info) {
        return infoArray;
    } else {
        return value === 0 ? '' : value > 1 ? value + ' Eventi' : value + ' Evento';
    }
};

const getActivitiesArray = (data, day, patientID) => {
    const activities = [];
    const patientIndex = data.findIndex(e => e.id === patientID);

    if (patientIndex !== -1) {
        data[patientIndex].activities.forEach(element => {
            const eventDate = new Date(element.date);
            const convertedDate = `${eventDate.getDate()}-${eventDate.getMonth()}-${eventDate.getFullYear()}`;

            if (day === convertedDate) {
                element.activity.forEach(activity => {
                    activities.push(activity);
                });
            }
        });
    }

    return activities;
};

const getActivities = (data, day, patientID, km) => {
    let value = 0;
    if (!data || data.length <= 0) return '';
    const patientIndex = data.findIndex(e => e.id === patientID);

    if (patientIndex !== -1) {
        data[patientIndex].activities.forEach(element => {
            const eventDate = new Date(element.date);
            const convertedDate = `${eventDate.getDate()}-${eventDate.getMonth()}-${eventDate.getFullYear()}`;

            if (day === convertedDate) {
                value += element.total_steps;
            }
        });
    }

    if (km) {
        return value === 0 ? '' : (value * 0.0008).toFixed(2) + ' Km';
    } else {
        return value === 0 ? '' : value + ' Passi';
    }

};
const getGoals = (data, day) => {
    let value = 0;

    data.forEach(element => {
        const date = new Date(element.completed_at);
        const convertedDate = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
        if (day === convertedDate) {
            value++;
        }

    });

    return value === 0 ? '' : value > 1 ? value + ' Traguardi' : value + ' Tragaurdo';
};
const getSurveyAnswers = (data, day) => {
    let value = null;
    data.forEach(element => {

        if (day === element.date) {
            value = element.answers;
        }

    });

    return value;
};
const getSurveyTotal = (data) => {
    let value = 0;
    if (data[0]) {
        data.forEach(element => {
            element.answers.forEach(answer => {
                value += answer.answer;
            });
        });
    }

    return value;
};
const getSurveyEvents = (data, day) => {
    let value = '';
    data.forEach(element => {

        if (day === element.date) {
            value = 'Questionario';
        }

    });

    return value;
};
const getMoodEvents = (data, day) => {
    let value = '';
    data.forEach(element => {

        if (day === element.date) {
            value = element.rating;

        }

        if (data.surveys) {
            data.surveys.forEach(element => {
                if (element.date === day) {
                    value += ' Questionario';
                }
            });
        }
    });

    return value;
};

const getAdherenceEvents = (data, day) => {
    if (Array.isArray(data)) {
        let value = '';
        data.forEach(element => {


            if (day === element.date && element.rate !== null) {

                value = Math.round(element.rate * 100);

            }
        });

        return value !== '' ? value + "%" : '';
    } else if (Array.isArray(data.data)) {

        let value = '';
        data.data.forEach(element => {
            if (element.rate !== null) {

                const newDate = new Date(element.date);
                const dayString = `${newDate.getDate()}-${newDate.getMonth()}-${newDate.getFullYear()}`;
                if (day === dayString) {
                    value = Math.round(element.rate * 100);

                }
            }
        });

        return value !== '' ? value + "%" : '';
    } else if (Array.isArray(data.adherence[0]?.adherenceArray)) {

        let value = '';
        data.adherence[0].adherenceArray.forEach(element => {
            const newDate = new Date(element.date);
            const dayString = `${newDate.getDate()}-${newDate.getMonth()}-${newDate.getFullYear()}`;
            if (day === dayString) {
                value = Math.round(element.rate * 100);

            }
        });
    } else {
        return data.rate ? (data.rate * 100) + '%' : '';
    }
};


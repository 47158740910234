import { useAtomValue } from 'jotai';
import React from 'react';
import { useDispatch } from 'react-redux';
import { acceptedEventsAtom } from '../../../jotai/calendarEvents/taggedEventsAtom';
import { setContext } from '../../../store/contextStore';
import { getEventByDate, getTaggedEvent } from '../../../store/eventsStore';

const DayEvent = (event) => {
    const dispatch = useDispatch();
    const acceptedEvents = useAtomValue(acceptedEventsAtom);

    const appointmentLabel = event.event.eventsNumber > 1 ? '- Appuntamenti' : '- Appuntamento';

    const eventClickedHandler = (e) => {
        let xPos = e.target.getBoundingClientRect().left + 'px';
        let yPos = e.target.getBoundingClientRect().top + 'px';

        const widthDifference = e.target.getBoundingClientRect().left + 550 - window.innerWidth;
        const heightDifference = e.target.getBoundingClientRect().top + 400 - window.innerHeight;

        if (widthDifference > 0) {
            xPos = e.target.getBoundingClientRect().left - (widthDifference + 15) + 'px';
        }
        if (heightDifference > 0) {
            yPos = e.target.getBoundingClientRect().top - (heightDifference + 5) + 'px';
        }

        console.log('event', event.event);

        if (event.event.eventId) {
            dispatch(getTaggedEvent(event.event.eventId));
        } else {
            dispatch(getEventByDate(event.event.start));
        }


        dispatch(setContext({
            xPos: xPos,
            yPos: yPos,
            contextName: 'calendar',
        }));

    };

    return (
        <React.Fragment>
            <div className="rbc-appointment-handler" onClick={(e) => eventClickedHandler(e)}>
            </div>
            <span className="appointments">{event.event.eventsNumber} {appointmentLabel}</span>
        </React.Fragment>
    );
};

export default DayEvent;
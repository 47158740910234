import React, { useEffect, useState } from 'react';
import axios from 'axios';
import getCallUrl from '../utils/callUrlUtil';
import { useSelector } from 'react-redux';
import { useAtom } from 'jotai';
import { adherenceSpecificationAtom } from '../jotai/adherenceSpecificationAtom';


export default function SpecifiedAdherenceRateComponent(props) {
  const adheranceSpecification = useAtom(adherenceSpecificationAtom);
  const [rate, setRate] = useState(0);
  const startDate = useSelector((state) => state.user.startDate);
  let callUrl = getCallUrl();

  useEffect(() => {

    let date = new Date(startDate);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    // if adherenceSpecification equals to week, date is the first day of the week lastDay the last day of the week
    // if adherenceSpecification equals to month, lastDay is the last day of the month
    // else date is today and lastDay is the last day is today
    if (adheranceSpecification[0] === 'week') {
      date = new Date(date.setDate(date.getDate() - date.getDay()));
      lastDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 6);
    } else if (adheranceSpecification[0] === 'month') {
      date = new Date(date.getFullYear(), date.getMonth(), 1);
      lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    } else {
      date = new Date();
      lastDay = new Date();
    }

    axios.get(`${callUrl}/api/patient-drugs/therapeutic-adherences-stats/${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}/${date.getFullYear()}-${date.getMonth() + 1}-${lastDay.getDate()}/false?patient=${props.patientID}&patientDrug=${props.drugID}`, {
      headers: { 'X-AUTH-TOKEN': props.userToken },
    })
      .then(response => {
        // console.log('inner adherence response', response.data);
        // console.log('inner adherence call', `${callUrl}/api/patient-drugs/therapeutic-adherences-stats/${date.getFullYear()}-${date.getMonth() + 1}-01/${date.getFullYear()}-${date.getMonth() + 1}-${lastDay.getDate()}/false?patient=${props.patientID}&patientDrug=${props.drugID}`);
        if (response.data.data.rate * 100 !== isNaN()) {
          setRate(response.data.data.rate * 100);
        }
      })
      .catch(error => console.log(error));
  }, [props, startDate, adheranceSpecification]);


  return (
    <span>{Math.round(rate)}%</span>
  );
}

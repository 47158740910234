import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as newsStore from '../../store/newsStore';
import Pagination from '../../components/Pagination';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewsCard from '../../components/newsCard';
import NewsImg from '../../assets/images/layout/news-header.png';

const News = (props) => {
    const [toggleOrder, setToggleOrder] = useState(false);
    const [newsFilter, setNewsFilter] = useState('');
    const newsArray = useSelector((state) => state.news.newsArray);
    const filteredArray = useSelector((state) => state.news.filteredArray);
    const dispatch = useDispatch();


    useEffect(() => {
        document.title = props.title || "Giada";

        window.scrollTo(0, 0);

    }, [props.title]);

    const toggleOrderHandler = () => {
        setToggleOrder(!toggleOrder);
        dispatch(newsStore.toggleOrder());
    }

    const filterHandler = (event) => {
        const inputText = event.target.value.toLowerCase();
        setNewsFilter(inputText);
        dispatch(newsStore.filterNews(inputText));
    }



    return (

        <React.Fragment>
            <div className="page-header">
                <div className="breadcrumbs" onClick={() => setNewsFilter('')}>
                    News {newsFilter.length > 0 ?
                        <React.Fragment>
                            <span className="breadcrumb-separator">{'>'}</span> <span>{newsFilter}</span>
                        </React.Fragment>
                        : null}
                </div>
                <div className="page-actions right">
                    <div className="label">Ordina per:</div>
                    <div className={`order-button down ${!toggleOrder ? 'active' : ''}`} onClick={toggleOrder ? toggleOrderHandler : null}></div>
                    <div className={`order-button up ${toggleOrder ? 'active' : ''}`} onClick={!toggleOrder ? toggleOrderHandler : null}></div>
                    <input type="text" className="form-control search" placeholder="Cerca News o Argomento" value={newsFilter} onChange={filterHandler} />
                </div>
            </div>
            <div className="row">
                {newsArray ?
                    <Pagination
                        data={newsFilter.length > 0 ? 
                            filteredArray.map((element, idx) =>
                            <div key={idx} className="col-lg-6 col-xl-4">
                                <NewsCard
                                    img={element.featured_image ? element.featured_image.reference.url : NewsImg}
                                    title={element.title}
                                    tags={element.tags}
                                    date={element.date}
                                    excerpt={element.abstract}
                                    content={element.content}
                                    alt={element.featured_image ? element.featured_image.reference.alt : ''}
                                />
                            </div>)
                            :
                            newsArray.map((element, idx) =>
                                <div key={idx} className="col-lg-6 col-xl-4">
                                    <NewsCard
                                        img={element.featured_image ? element.featured_image.reference.url : NewsImg}
                                        title={element.title}
                                        tags={element.tags}
                                        date={element.date}
                                        excerpt={element.abstract}
                                        content={element.content}
                                        alt={element.featured_image ? element.featured_image.reference.alt : ''}
                                    />
                                </div>)}
                        title="Posts"
                        pageLimit={5}
                        dataLimit={9}
                    />
                    /* newsArray.map( element => {
                         return(
                             <div key={element.id} className="col-lg-6 col-xl-4">
                             <NewsCard
                                 img={element.featured_image?element.featured_image:NewsImg}
                                 title={element.title}
                                 tags={element.tags}
                                 date={element.date}
                                 excerpt={element.abstract}
                                 content={element.content}
                             />
                             
                             </div>
                         ) 
                     }) */
                    : <p>Non ci sono news</p>}
            </div>

        </React.Fragment>
    )
}

export default News;